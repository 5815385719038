import InvestmentActionTypes from "./types";
import AccountActionTypes from "../account/types";

const INITIAL_STATE = {
    data: [],
    stepData: {
        step: "investmentSum",
        withContinueStep: false,
        clearData: true,
        investSum: {},
        investmentData: {
            step: "investmentUserDataStep",
            userData: { step: "personalData" },
            overviewData: { step: "investmentOverviewData" },
        },
    },
    filterBy: {},
    totalInvestmentAmount: 0,
    isInvestmentCreated: false,
    isInvestmentLoading: false,
    isUserInvestmentsLoading: false,
    isInitInvestmentLoading: false,
    isInitInvestmentSuccess: false,
    successData: {},
    sortBy: "",
};

const investmentReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case InvestmentActionTypes.SET_INVESTMENT_STEP_DATA:
            const { stepData } = state;
            return {
                ...state,
                stepData: {
                    ...stepData,
                    clearData: false,
                    step: payload.step || stepData.step,
                    withContinueStep: payload.withContinueStep || stepData.withContinueStep,
                    investSum: {
                        ...stepData.investSum,
                        ...payload.investSum,
                    },
                    invest1to8MData: {
                        ...stepData.invest1to8MData,
                        ...payload.invest1to8MData,
                    },
                    investmentData: {
                        ...stepData.investmentData,
                        ...payload.investmentData,
                        userData: {
                            ...stepData.investmentData.userData,
                            ...payload.userData,
                        },
                        overviewData: {
                            ...stepData.investmentData.overviewData,
                            ...payload.overviewData,
                        },
                    },
                },
            };
        case InvestmentActionTypes.CLEAR_INVESTMENT_STEP_DATA:
            return {
                ...state,
                isInvestmentCreated: false,
                isInvestmentLoading: false,
                isUserInvestmentsLoading: false,
                isInitInvestmentLoading: false,
                isInitInvestmentSuccess: false,
                stepData: {
                    ...INITIAL_STATE.stepData,
                    clearData: true,
                    investmentData: {
                        ...INITIAL_STATE.stepData.investmentData,
                        userData: {
                            ...INITIAL_STATE.stepData.investmentData.userData,
                            identificationData: state.stepData.investmentData.userData.identificationData,
                            personalData: state.stepData.investmentData.userData.personalData,
                        },
                    },
                },
            };
        case InvestmentActionTypes.RESET_INVESTMENT_STEP_DATA:
            return {
                ...state,
                isInvestmentCreated: false,
                isInvestmentLoading: false,
                isUserInvestmentsLoading: false,
                isInitInvestmentLoading: false,
                isInitInvestmentSuccess: false,
                stepData: {
                    ...INITIAL_STATE.stepData,
                    clearData: false,
                    investmentData: {
                        ...INITIAL_STATE.stepData.investmentData,
                        userData: {
                            ...INITIAL_STATE.stepData.investmentData.userData,
                            identificationData: state.stepData.investmentData.userData.identificationData,
                            personalData: state.stepData.investmentData.userData.personalData,
                        },
                    },
                },
                data: [],
            };
        case InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_START:
            return {
                ...state,
                isLoading: true,
                isInitInvestmentLoading: true,
                isInitInvestmentSuccess: false,
            };

        case InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isInitInvestmentLoading: false,
                isInitInvestmentSuccess: true,
                stepData: {
                    ...state.stepData,
                    investmentData: {
                        ...state.stepData.investmentData,
                        ...payload,
                    },
                },
            };

        case InvestmentActionTypes.SET_INITIAL_INVESTMENT_DATA_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
                isInitInvestmentLoading: false,
                isInitInvestmentSuccess: false,
            };

        case InvestmentActionTypes.SET_FILTERS:
            return {
                ...state,
                filterBy: payload.filterBy,
            };

        case InvestmentActionTypes.SET_SORT_FILTERS:
            return {
                ...state,
                sortBy: payload.sortBy,
            };

        case InvestmentActionTypes.FETCH_USER_INVESTMENTS_START:
            return {
                ...state,
                isUserInvestmentsLoading: true,
            };

        case InvestmentActionTypes.FETCH_USER_INVESTMENTS_SUCCESS:
            return {
                ...state,
                data: payload,
                isUserInvestmentsLoading: false,
            };
        case InvestmentActionTypes.FETCH_USER_INVESTMENTS_FAILURE:
            return {
                ...state,
                error: payload,
                isUserInvestmentsLoading: false,
            };
        case InvestmentActionTypes.CREATE_INVESTMENT_DATA_START:
            return {
                ...state,
                error: null,
                isInvestmentCreated: false,
                isInvestmentLoading: true,
            };
        case InvestmentActionTypes.CREATE_INVESTMENT_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                isInvestmentCreated: true,
                isInvestmentLoading: false,
                successData: payload,
            };
        case InvestmentActionTypes.CREATE_INVESTMENT_DATA_FAILURE:
            return {
                ...state,
                error: payload,
                isInvestmentCreated: false,
                isInvestmentLoading: false,
            };
        case InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_START:
            return {
                ...state,
                amountError: "",
                isInvestmentAmountLoading: true,
            };
        case InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_SUCCESS:
            return {
                ...state,
                amountError: "",
                totalInvestmentAmount: payload,
                isInvestmentAmountLoading: false,
            };
        case InvestmentActionTypes.FETCH_INVESTMENT_AMOUNT_FAILURE:
            return {
                ...state,
                amountError: payload,
                isInvestmentAmountLoading: false,
            };
        case AccountActionTypes.LOGOUT:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default investmentReducer;
