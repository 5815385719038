import React, { useEffect } from "react";
import { useFormattedMessage } from "hooks";
import NewLoader from "components/NewLoader";

const messages = {
    heading: "mobile_number_change",
    content1: "change_mobile_number_in_account",
    content2: "please_check_email_inbox",
    content3: "not_recieved_email_check_spam_folder",
    buttonText: "request_e_mail",
    emailSentbuttonText: "email_sent",
};

const MobileInfo = ({ className = "", counter, isTwoFactorMailLoading, isTwoFactorMailSent, onButtonClick }) => {
    useEffect(() => {
        jQuery(".side-modal").animate({ scrollTop: 0 });
    }, []);

    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`mobile-info-container ${className}`}>
            <h3 className="mt-0">{formatMessage(messages.heading)}</h3>
            <p>{formatMessage(messages.content1)}</p>
            <p>{formatMessage(messages.content2)}</p>
            <p>{formatMessage(messages.content3)}</p>
            <button
                disabled={isTwoFactorMailLoading || counter !== 0}
                className={`mt-10 mx-auto ${isTwoFactorMailLoading || counter !== 0 ? "disable-grey" : ""}`}
                onClick={onButtonClick}
            >
                {isTwoFactorMailLoading ? (
                    <>
                        <NewLoader /> {formatMessage(messages.buttonText)}
                    </>
                ) : isTwoFactorMailSent ? (
                    formatMessage(messages.emailSentbuttonText)
                ) : counter !== 0 ? (
                    `${formatMessage(messages.buttonText)} in ${counter} sec`
                ) : (
                    formatMessage(messages.buttonText)
                )}
            </button>
        </div>
    );
};

export default MobileInfo;
