/* eslint-disable no-alert */
import React from "react";
import { useFormattedMessage } from "hooks";
import Close from "components/Close";
import { tenantPrimaryColors } from "constants";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectTenant } from "store/app/selectors";
const messages = {
    heading: "no_camera_access",
    content1: "no_camera_access_content1",
    settings: "settings",
    menu: "menu",
    notnow: "not_now",
    activate: "activate_mobile_push_notification",
    dontask: "dont_ask_again",
    next: "continue_button",
    back: "back_button_text",
};

const NoCameraAccess = ({ onClose = () => {}, currentTenant }) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <div>
            <div className={"close-container d-flex align-center justify-between no-camera-access-header"}>
                <div className="d-flex" />
                <div className="d-flex align-center">
                    <Close className="close-button fw-600" onCloseButtonClick={onClose} />
                </div>
            </div>
            <span className="d-flex justify-center mt-10">
                <svg width="81" height="64" viewBox="0 0 81 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M31.5 45C40.6127 45 48 37.6127 48 28.5C48 19.3873 40.6127 12 31.5 12C22.3873 12 15 19.3873 15 28.5C15 37.6127 22.3873 45 31.5 45Z"
                        fill={tenantPrimaryColors[currentTenant]?.primary}
                    />
                    <path
                        d="M31.5 43.5C39.7843 43.5 46.5 36.7843 46.5 28.5C46.5 20.2157 39.7843 13.5 31.5 13.5C23.2157 13.5 16.5 20.2157 16.5 28.5C16.5 36.7843 23.2157 43.5 31.5 43.5Z"
                        stroke="#171926"
                        stroke-width="3"
                    />
                    <path
                        d="M31.5 38C36.7467 38 41 33.7467 41 28.5C41 23.2533 36.7467 19 31.5 19C26.2533 19 22 23.2533 22 28.5C22 33.7467 26.2533 38 31.5 38Z"
                        fill="white"
                    />
                    <path
                        d="M31.5 36.5C35.9183 36.5 39.5 32.9183 39.5 28.5C39.5 24.0817 35.9183 20.5 31.5 20.5C27.0817 20.5 23.5 24.0817 23.5 28.5C23.5 32.9183 27.0817 36.5 31.5 36.5Z"
                        stroke="#171926"
                        stroke-width="3"
                    />
                    <path
                        d="M55 8.5H8C4.41015 8.5 1.5 11.4101 1.5 15V45C1.5 48.5899 4.41015 51.5 8 51.5H55C58.5899 51.5 61.5 48.5899 61.5 45V15C61.5 11.4101 58.5899 8.5 55 8.5Z"
                        stroke="#171926"
                        stroke-width="3"
                    />
                    <path
                        d="M9 1.5H12C12.663 1.5 13.2989 1.76339 13.7678 2.23223C14.2366 2.70107 14.5 3.33696 14.5 4V8.5H6.5V4C6.5 3.33696 6.76339 2.70107 7.23223 2.23223C7.70107 1.76339 8.33696 1.5 9 1.5Z"
                        stroke="#171926"
                        stroke-width="3"
                    />
                    <path
                        d="M76.3263 50.8487H57.2383V41.2647C57.2383 38.7335 58.2438 36.3059 60.0337 34.5161C61.8235 32.7262 64.2511 31.7207 66.7823 31.7207C69.3135 31.7207 71.7411 32.7262 73.5309 34.5161C75.3208 36.3059 76.3263 38.7335 76.3263 41.2647V50.8487ZM66.8493 36.2727C65.5286 36.2769 64.2634 36.8049 63.3315 37.7408C62.3996 38.6766 61.877 39.944 61.8783 41.2647V46.2567H71.8203V41.2647C71.8216 39.944 71.299 38.6766 70.3671 37.7408C69.4351 36.8049 68.17 36.2769 66.8493 36.2727Z"
                        fill="white"
                        stroke="#171926"
                        stroke-width="2.5"
                        stroke-linejoin="round"
                    />
                    <path d="M81 44H52V64H81V44Z" fill="white" />
                    <path d="M79.75 45.25H53.25V62.75H79.75V45.25Z" stroke="#171926" stroke-width="2.5" stroke-linejoin="round" />
                    <path
                        d="M66.5 56C67.8807 56 69 54.8807 69 53.5C69 52.1193 67.8807 51 66.5 51C65.1193 51 64 52.1193 64 53.5C64 54.8807 65.1193 56 66.5 56Z"
                        fill="white"
                        stroke="#171926"
                        stroke-width="2.5"
                        stroke-linejoin="round"
                    />
                    <path d="M66.5 55.8457V59.0507" stroke="#171926" stroke-width="2.5" stroke-linejoin="round" />
                </svg>
            </span>
            <h3 className="text-center mb-4 mt-10">{formatMessage(messages.heading)}</h3>
            <p className="mb-10 text-center text-14 fw-500">
                {formatMessage(messages.content1)}
                <span
                    className="underline cursor-pointer"
                    onClick={() => {
                        window?.ReactNativeWebView?.postMessage("toAppSetting");
                    }}
                >
                    {formatMessage(messages.settings)}
                </span>

                {formatMessage(messages.menu)}
            </p>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentTenant: selectTenant,
});

export default connect(mapStateToProps)(NoCameraAccess);
