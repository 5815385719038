/* eslint-disable react-hooks/rules-of-hooks */

import { tenantPrimaryColors } from "constants";
import { PROJECT_STATUS, INVESTMENT_STATUS, TILE_STATUS, CLUBDEALS_PROJECT_STATUS } from "constants/status";

import { useFormattedMessage, useUtils } from "hooks";
import { adminInvestmentMessages, investmentLookupMessages, clubDealMessages } from "i18n/messages";

export default function getClubDealStatusLookUp(status) {
    const { formatMessage } = useFormattedMessage();
    const text = formatMessage(clubDealMessages[status] || clubDealMessages["default"]);
    const statuses = {
        [CLUBDEALS_PROJECT_STATUS.ADVERTISING]: {
            className: "bg-black",
            text,
        },
        [CLUBDEALS_PROJECT_STATUS.FUNDING_CLOSED]: {
            className: "bg-primary-light",
            text,
        },
        default: {
            className: "bg-primary-light",
            text,
        },
    };
    return statuses[status];
}

export function getAdminInvestmentStatusText(status) {
    const { formatMessage } = useFormattedMessage();
    return formatMessage(adminInvestmentMessages[status] || adminInvestmentMessages["default"]);
}

export function getInvestmentStatusText(status) {
    const { formatMessage } = useFormattedMessage();
    return formatMessage(investmentLookupMessages[status] || investmentLookupMessages["default"]);
}

export function getProjectInvestmentStatusLookUp(status) {
    const currentTenantTechnicalName = useUtils()?.tenant?.TechnicalName;
    const { formatMessage } = useFormattedMessage();
    const text = formatMessage(investmentLookupMessages[status] || investmentLookupMessages["default"]);

    const statuses = {
        [PROJECT_STATUS.COMING_SOON]: {
            className: "bg-black",
            indicatorColor: "#9495A0",
            text,
        },
        [PROJECT_STATUS.ADVERTISING]: {
            className: "bg-black",
            indicatorColor: "#9495A0",
            text,
        },
        [PROJECT_STATUS.VIP_FUNDING]: {
            className: "bg-black",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
        },
        [PROJECT_STATUS.FUNDING_ACTIVE]: {
            className: "bg-black",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
            isProgressBar: true,
        },
        [PROJECT_STATUS.FUNDING_CLOSED]: {
            className: "bg-primary-light",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
            isProgressBar: true,
        },
        [PROJECT_STATUS.FINANCING_COMPLETED]: {
            className: "bg-primary-light",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
            isProgressBar: true,
        },
        [PROJECT_STATUS.MARKETPLACE]: {
            className: "bg-lighter-grey",
            indicatorColor: "#F58823",
            text,
            buySellTag: true,
        },
        [PROJECT_STATUS.MARKETPLACE_PAUSED]: {
            className: "bg-lighter-grey",
            indicatorColor: "#F58823",
            text,
            pausedTag: true,
        },
        [PROJECT_STATUS.MARKETPLACE_REVIEW]: {
            className: "bg-lighter-grey",
            indicatorColor: "#F58823",
            text,
        },
        [PROJECT_STATUS.CLUB_DEAL]: {
            className: "bg-black",
            indicatorColor: "#F58823",
            text,
        },
        [PROJECT_STATUS.SILENT_SALE]: {
            className: "",
            indicatorColor: "#F58823",
            text,
        },
        [PROJECT_STATUS.SUCCESSFULLY_PAID_BACK]: {
            className: "bg-light-green",
            indicatorColor: "#4CAF50",
            text,
        },
        [INVESTMENT_STATUS.RESERVED]: {
            className: "bg-light-red",
            indicatorColor: "#9495A0",
            text,
        },
        [INVESTMENT_STATUS.INITIALIZED]: {
            className: "bg-light-red",
            indicatorColor: "#9495A0",
            text,
        },
        [INVESTMENT_STATUS.CREATED_PENDING_KYC]: {
            className: "bg-light-red",
            indicatorColor: "#9495A0",
            text,
        },
        [TILE_STATUS.INITIATED]: {
            className: "bg-light-red",
            text,
        },
        [TILE_STATUS.PENDING]: {
            className: "bg-light-red",
            text,
        },
        [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: {
            className: "bg-light-red",
            indicatorColor: "#9495A0",
            text,
        },
        [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: {
            className: "bg-light-red",
            indicatorColor: "#9495A0",
            text,
        },
        [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: {
            className: "bg-light-red",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
        },
        [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: {
            className: "bg-primary-light",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
        },
        [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE]: {
            className: "bg-primary-light",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
        },
        [INVESTMENT_STATUS.ACTIVE]: {
            className: "bg-light-green",
            indicatorColor: tenantPrimaryColors?.[currentTenantTechnicalName]?.primary,
            text,
        },
        [INVESTMENT_STATUS.CANCELED]: {
            className: "bg-primary-light",
            indicatorColor: "#9495A0",
            text,
        },
        [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: {
            className: "bg-primary-light",
            indicatorColor: "#4CAF50",
            text,
        },
        [INVESTMENT_STATUS.SECONDARY_MARKET_BUY]: {
            className: "bg-primary-light",
            indicatorColor: "#F58823",
            text,
        },
        [INVESTMENT_STATUS.SECONDARY_MARKET_SALE]: {
            className: "bg-primary-light",
            indicatorColor: "#F58823",
            text,
        },
        "Request Information": {
            className: "bg-black",
            text,
        },
        default: {
            className: "bg-primary-light",
            indicatorColor: "#9495A0",
            text,
        },
    };
    return statuses[status] || statuses["default"];
}
