import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setUserMobileData } from "store/user/account/actions";
import { selectUserDetails, selectUserMobileDetails } from "store/user/account/selectors";
import { selectIsCurrentTenantFinexity, selectLocale } from "store/app/selectors";

import TwoCellRow from "components/TwoCellRow";
import MobileModal from "components/MobileModal";

import {
    email,
    firstName,
    lastName,
    gender,
    mobilePhone,
    titel,
    street,
    streetNumber,
    zipcode,
    city,
    country,
    streetAddition,
    dataActions,
    formSectionHeadings,
    owner,
    taxIdentificationNumber,
    religion,
    federalState,
} from "i18n/messages/formElements";
import {
    PROVIDER_TYPE,
    // selectedCountry,
} from "constants/index";
// import { FederalStatesGermanyOptions } from "constants";
import { KYC_STATUS_LOCAL, MOBILE_STATUS } from "constants/status";

import UserSvg from "assets/images/svg/user.svg";
import Phone from "assets/images/svg/phone.svg";
import KYCIncomplete from "assets/images/svg/kyc-incomplete.svg";
import KYCComplete from "assets/images/svg/kyc-completed.svg";
import EmailTo from "assets/images/svg/emailTo.svg";
// import CirclePercentage from "assets/images/svg/circle-percentage.svg";

import { useFormattedMessage, useUtils } from "hooks";
// import { allCountries } from "mocks";
import { selectAdminKYCLoading, selectAdminKYCProvider, selectCurrenKYCStatusAdmin } from "store/user/kyc/selectors";

const messages = {
    ...email,
    ...firstName,
    ...lastName,
    ...gender,
    ...mobilePhone,
    ...titel,
    ...street,
    ...streetNumber,
    ...zipcode,
    titel,
    ...city,
    ...country,
    ...streetAddition,
    ...dataActions,
    ...formSectionHeadings,
    ...owner,
    ...taxIdentificationNumber,
    ...religion,
    ...federalState,
    TaxHeading: "meta_title_tax_page",
    verifyNumber: "verify",
    addNumber: "form_add",
    notSpecified: "not_specified",
    waitingForUserKYC: "waiting_for_user_kyc",
    waitingForUserReKYC: "waiting_for_user_rekyc_complete",
    provideDocuments: "please_provide_user_documents",
    ensureDocumentData: "ensure_documtent_data",
    confirmCopy: "confrimed_copy",
    uploadIdentificationDocument: "upload_copy_of_both_side",
    kycManualButton: "kyc_user_manually",
    pendingPersonalDataHeading: "pending_personal_data",
    pendingPersonalDataContent: "not_yet_entered_data",
    manual: "manual",
    submitButtonText: "tipster_form_button_text",
    notSpecified: "not_specified",
};

const ProfileView = ({
    userData,
    setStep,
    // taxData,
    onCloseButtonClick,
    backSlideEffect = "",
    setBackSlideEffect,
    userPhoneData,
    isEditNotAllowed,
    sectionsToShow = {
        // canShowKYC: true,
        canShowTax: true,
        canShowPhone: true,
        canShowAccount: true,
        canShowPersonal: true,
    },
    userDetails,
    setUserMobileData,
    userMobileData,
    hideMobileModal,
    enableEmailModalOpen = false,
    setEmailModalOpen = () => {},
    onClickManualKYC,
    isShowManualKYC,
    statusKYCSelectedUser,
    kycProviderSelectedUser,
    selectedUserLoading,
}) => {
    const { formatMessage } = useFormattedMessage();

    const [mobilePhoneModalFlag, setMobilePhoneModalFlag] = useState(false);
    // const [federalState, setFederalState] = useState("");
    // const [country, setCountry] = useState("");

    const statusTwoFactor = userDetails.StatusTwoFactor;
    const userEmail = userDetails.Email;

    const isApp = useUtils().isPlatformSourceFromApp;

    useEffect(() => {
        if (!isApp) {
            const hash = window.location.hash && window.location.hash.substring(1);
            const mobileStep = statusTwoFactor === "" || statusTwoFactor === MOBILE_STATUS.WAITING_FOR_TAN_CONFIRMATION ? 2 : 1;

            setUserMobileData({ ...userMobileData, step: mobileStep, existData: false });

            if (hash === MOBILE_STATUS.HASH_VARIABLE && mobileStep === 2) {
                setMobilePhoneModalFlag(true);
            }
        }
    }, [userEmail]);

    // useEffect(() => {
    //     let federal = taxData?.FederalState;
    //     federal = federal ? FederalStatesGermanyOptions.find((item) => item.value == federal) : null;
    //     setFederalState(federal);

    //     let country =
    //         taxData?.Address && Array.isArray(taxData.Address) && taxData.Address.length ? taxData.Address[0].Country : null;
    //     country = country && allCountries ? allCountries.find((item) => item.value == country) : null;
    //     setCountry(country);
    // }, [taxData]);

    // const showTaxBlock = taxData?.TaxID || taxData?.Religion || federalState?.label || country?.label;
    const showIdentification =
        userData?.FirstName || userData?.LastName || userData?.TypeGender?.label || userData?.TypeTitle?.label;
    // const _selectedCountry = selectedCountry(country?.value) || country;

    const userKYCStatusReKYC = statusKYCSelectedUser === KYC_STATUS_LOCAL.REKYC;

    const isKYCComplete = statusKYCSelectedUser === KYC_STATUS_LOCAL.COMPLETED;

    const userDataIsNotFilled = statusKYCSelectedUser === KYC_STATUS_LOCAL.INCOMPLETE;

    return (
        <div className={`data-block ${backSlideEffect}`}>
            {!hideMobileModal && (
                <MobileModal
                    isChangeData={userPhoneData.verifiedMobileNumber}
                    open={mobilePhoneModalFlag}
                    onBackButtonClick={() => setMobilePhoneModalFlag(false)}
                    onCloseButtonClick={() => {
                        setMobilePhoneModalFlag(false);
                        onCloseButtonClick();
                    }}
                />
            )}

            {sectionsToShow?.canShowPersonal && (
                <div className="menu-tab">
                    <TwoCellRow
                        containerClassname="w-90 my-3 mx-5 pb-4 border-bottom"
                        rClassname="change primary-color text-16 d-block"
                        renderLeft={() => (
                            <span className="flex align-center text-14 fw-600 user-svg">
                                <UserSvg />
                                &nbsp;{formatMessage(messages.identificationData)}
                            </span>
                        )}
                        removeRClass
                        rClick={() => {
                            setStep(2);
                            setBackSlideEffect("step-slide-left");
                        }}
                        right={formatMessage(messages.changeData)}
                        renderRight={() => {
                            if (isEditNotAllowed) return null;
                            return (
                                <span
                                    className="primary-shade text-14 fw-400 pointer"
                                    onClick={() => {
                                        setStep(2);
                                        setBackSlideEffect("step-slide-left");
                                    }}
                                >
                                    {showIdentification ? formatMessage(messages.changeData) : formatMessage(messages.addNumber)}
                                </span>
                            );
                        }}
                    />

                    {!showIdentification && (
                        <TwoCellRow
                            removeRClass
                            lClassname="text-14 another-grey-color"
                            containerClassname="w-90 my-3 mx-5"
                            left={formatMessage(messages.notSpecified)}
                        />
                    )}
                    {userData?.TypeGender?.value && (
                        <TwoCellRow
                            removeRClass
                            lClassname={"text-14"}
                            containerClassname="w-90 my-3 mx-5"
                            left={
                                userData?.TypeGender?.label +
                                " " +
                                ((userData?.TypeTitle?.value && userData?.TypeTitle?.label) || "")
                            }
                        />
                    )}

                    {userData?.FirstName && (
                        <TwoCellRow
                            removeRClass
                            lClassname={"text-14"}
                            containerClassname="w-90 my-3 mx-5"
                            left={userData?.FirstName + " " + (userData?.LastName || "")}
                        />
                    )}

                    <Fragment>
                        {userData?.Street && (
                            <TwoCellRow
                                removeRClass
                                lClassname={"text-14"}
                                containerClassname="w-90 my-3 mx-5"
                                left={
                                    userData?.Street +
                                    " " +
                                    (userData?.Streetnumber || "") +
                                    " " +
                                    (userData?.StreetAddition || "")
                                }
                            />
                        )}
                        {userData?.Zipcode && (
                            <TwoCellRow
                                removeRClass
                                lClassname={"text-14"}
                                containerClassname="w-90 my-3 mx-5"
                                left={userData?.Zipcode + " " + (userData?.City || "")}
                            />
                        )}
                        {userData?.Country?.label && (
                            <TwoCellRow
                                removeRClass
                                lClassname={"text-14"}
                                containerClassname="w-90 my-3 mx-5"
                                left={userData?.Country?.label}
                            />
                        )}
                    </Fragment>

                    {userData?.Email && (
                        <TwoCellRow
                            removeRClass
                            containerClassname="w-90 my-3 mx-5"
                            renderLeft={() => (
                                <div
                                    className={`d-flex align-center text-14 ${enableEmailModalOpen ? "cursor-pointer" : ""}`}
                                    onClick={() => (enableEmailModalOpen ? setEmailModalOpen(true) : {})}
                                >
                                    <span className="mr-2">{userData?.Email}</span>
                                    {enableEmailModalOpen && <EmailTo />}
                                </div>
                            )}
                        />
                    )}
                </div>
            )}

            {sectionsToShow?.canShowKYC && (
                <div className="menu-tab">
                    <TwoCellRow
                        containerClassname={`w-90 mt-3 pb-3 mx-4 ${
                            statusKYCSelectedUser === KYC_STATUS_LOCAL.INITIATED ? "border-bottom" : ""
                        }`}
                        renderLeft={() => (
                            <span className="flex align-center text-14 fw-600 phone-svg">
                                {isKYCComplete ? <KYCComplete /> : <KYCIncomplete />}
                                &nbsp;{"KYC"}
                            </span>
                        )}
                        removeRClass
                        rClick={() => {
                            setStep(2);
                            setBackSlideEffect("step-slide-left");
                        }}
                        right={formatMessage(messages.changeData)}
                        renderRight={() => {
                            if (selectedUserLoading) {
                                return null;
                            } else if (!isKYCComplete) {
                                return <i className="far fa-hourglass mt-2" />;
                            } else if (kycProviderSelectedUser === PROVIDER_TYPE.IDNOW) {
                                return <img height="26px" src="/images/kyc-IDnow.png" alt="KYC Icon" />;
                            } else if (kycProviderSelectedUser === PROVIDER_TYPE.POSTIDENT) {
                                return <img height="26px" src="/images/kyc-postIdent.png" alt="KYC Icon" />;
                            } else if (kycProviderSelectedUser === PROVIDER_TYPE.MANUAL) {
                                return (
                                    <div className="d-flex">
                                        <img height="26px" src="/images/kyc-manual.svg" alt="KYC Icon" />
                                        <span className="text-14 fw-600 align-self-center">{formatMessage(messages.manual)}</span>
                                    </div>
                                );
                            }
                        }}
                    />
                    {!selectedUserLoading &&
                        (userDataIsNotFilled ||
                            statusKYCSelectedUser === KYC_STATUS_LOCAL.INITIATED ||
                            statusKYCSelectedUser === KYC_STATUS_LOCAL.REKYC) &&
                        isShowManualKYC && (
                            <div className={`p-4 ${userDataIsNotFilled ? "bg-lighter-grey" : ""}`}>
                                {(statusKYCSelectedUser === KYC_STATUS_LOCAL.INITIATED ||
                                    statusKYCSelectedUser === KYC_STATUS_LOCAL.REKYC) && (
                                    <>
                                        <p className="text-14 mb-5">
                                            {formatMessage(
                                                userKYCStatusReKYC ? messages.waitingForUserReKYC : messages.waitingForUserKYC
                                            )}
                                        </p>
                                        <button className="secondary-blue mt-4" onClick={onClickManualKYC}>
                                            {formatMessage(messages.kycManualButton)}
                                        </button>
                                    </>
                                )}
                                {userDataIsNotFilled && (
                                    <div className="bg-grey">
                                        <label className="d-inline-block mb-2">
                                            {formatMessage(messages.pendingPersonalDataHeading)}
                                        </label>
                                        <p className="text-14 mb-0">{formatMessage(messages.pendingPersonalDataContent)}</p>
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            )}

            {sectionsToShow?.canShowPhone && (
                <div className="menu-tab">
                    <TwoCellRow
                        containerClassname="w-90 my-3 mx-5 pb-4 border-bottom"
                        renderLeft={() => (
                            <span className="flex align-center text-14 fw-600 phone-svg">
                                <Phone />
                                &nbsp;{formatMessage(messages.phonePlaceholder)}
                            </span>
                        )}
                        removeRClass
                        rClick={() => {
                            setStep(2);
                            setBackSlideEffect("step-slide-left");
                        }}
                        right={formatMessage(messages.changeData)}
                        renderRight={() => {
                            if (isEditNotAllowed) return null;
                            return (
                                <span
                                    className="primary-shade text-14 fw-400 pointer"
                                    onClick={() => setMobilePhoneModalFlag(!mobilePhoneModalFlag)}
                                >
                                    {userPhoneData.mobileSectionRightTitle}
                                </span>
                            );
                        }}
                    />
                    <TwoCellRow
                        containerClassname="w-90 my-3 mx-5"
                        removeRClass
                        lClassname={`text-14 ${
                            userPhoneData.verifiedMobileNumber || userPhoneData.nonVerifiedMobileNumber
                                ? " "
                                : "another-grey-color"
                        }`}
                        left={
                            userPhoneData.verifiedMobileNumber ||
                            userPhoneData.nonVerifiedMobileNumber ||
                            formatMessage(messages.notSpecified)
                        }
                    />
                </div>
            )}

            {/* {sectionsToShow?.canShowTax && (
                <div className="menu-tab">
                    <TwoCellRow
                        containerClassname="w-90 my-3 mx-5 pb-4 border-bottom"
                        rClassname="text-16 d-block"
                        renderLeft={() => (
                            <span className="flex align-center text-14 fw-600 circle-svg">
                                <CirclePercentage />
                                &nbsp;{formatMessage(messages.TaxHeading)}
                            </span>
                        )}
                        removeRClass
                        rClick={() => {
                            setStep(3);
                            setBackSlideEffect("step-slide-left");
                        }}
                        right={formatMessage(messages.changeData)}
                        renderRight={() => {
                            if (isEditNotAllowed) return null;
                            return (
                                <span
                                    className="primary-shade text-14 fw-400 pointer"
                                    onClick={() => {
                                        setStep(3);
                                        setBackSlideEffect("step-slide-left");
                                    }}
                                >
                                    {showTaxBlock ? formatMessage(messages.changeData) : formatMessage(messages.addNumber)}
                                </span>
                            );
                        }}
                    />
                    {showTaxBlock ? (
                        <Fragment>
                            // TODO: Removed for Ticket FRON-238
                            // {taxData?.TaxID && (
                            //     <TwoCellRow
                            //         removeRClass
                            //         lClassname={`text-14 ${taxData?.TaxID || "another-grey-color"}`}
                            //         containerClassname="w-90 my-3 mx-5"
                            //         left={taxData?.TaxID || formatMessage(messages.notSpecified)}
                            //     />
                            // )}

                            {taxData?.Religion && (
                                <TwoCellRow
                                    removeRClass
                                    lClassname={`text-14 ${taxData?.Religion || "another-grey-color"}`}
                                    containerClassname="w-90 my-3 mx-5"
                                    left={taxData?.Religion || formatMessage(messages.notSpecified)}
                                />
                            )}

                            {country.label && (
                                <TwoCellRow
                                    removeRClass
                                    lClassname={`text-14`}
                                    containerClassname="w-90 my-3 mx-5"
                                    left={country.label || ""}
                                />
                            )}

                            {federalState?.label && country?.value == "DE" && (
                                <TwoCellRow
                                    removeRClass
                                    lClassname={`text-14 ${federalState?.label || "another-grey-color"}`}
                                    containerClassname="w-90 my-3 mx-5"
                                    left={federalState?.label || formatMessage(messages.notSpecified)}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <TwoCellRow
                            removeRClass
                            lClassname="text-14 another-grey-color"
                            containerClassname="w-90 my-3 mx-5"
                            left={formatMessage(messages.notSpecified)}
                        />
                    )}
                </div>
            )} */}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userDetails: selectUserDetails,
    userMobileData: selectUserMobileDetails,
    isCurrentTenantFinexity: selectIsCurrentTenantFinexity,
    locale: selectLocale,
    statusKYCSelectedUser: selectCurrenKYCStatusAdmin,
    kycProviderSelectedUser: selectAdminKYCProvider,
    selectedUserLoading: selectAdminKYCLoading,
});

const mapDispatchToProps = (dispatch) => ({
    setUserMobileData: (data) => dispatch(setUserMobileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
