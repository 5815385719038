/* eslint-disable no-new */
/* eslint-disable prefer-destructuring */
import * as Sentry from "@sentry/nextjs";

import AUTH_CONFIG from "constants/auth0";
import { API_UNAUTHORIZED_MSG, TENANTS_LIST } from "constants/index";
import { clearAllAuthCookie } from "store/user/account/actions";
import {
    getIdToken,
    getHashQueryStringParam,
    parseAuth0JwtToken,
    isClient,
    getAccessToken,
    getRefreshToken,
    isTokenExpiringSoon,
    isTokenExpired,
} from "utils/index";
import { getTenantFromHost, checkValidString } from "common/helper";
import { configureSentryUser } from "./sentry";

const FINX_COOKIE = require("common/constants");

let auth0Pkg = null;
let cookiesPkg = null;

if (isClient()) {
    auth0Pkg = require("auth0-js");
    cookiesPkg = require("js-cookie");
}

class Auth {
    constructor() {
        if (!Auth.instance) {
            if (isClient()) {
                this.auth0 = new auth0Pkg.WebAuth({
                    domain: AUTH_CONFIG.domain,
                    clientID: AUTH_CONFIG.clientId,
                    responseType: "token id_token",
                    audience: AUTH_CONFIG.audience,
                    redirectUri: AUTH_CONFIG.callbackUrl,
                    realm: "Username-Password-Authentication",
                    scope: AUTH_CONFIG.scope,
                });
            }
            Auth.instance = this;
        }
        return Auth.instance;
    }

    signInWithProvider(provider, callback) {
        if (isClient()) {
            localStorage.setItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER, provider);
            this.auth0.authorize(
                {
                    connection: provider,
                    authParamsMap: { tenantName: getTenantFromHost(window.location.host) },
                },
                callback,
            );
        }
    }

    customLogin(credentials) {
        localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
        return fetch("https://login.finexity.com/oauth/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                grant_type: "password",
                realm: "Username-Password-Authentication",
                username: credentials.username,
                password: credentials.password,
                client_id: AUTH_CONFIG.appClientId,
                audience: AUTH_CONFIG.audience,
                scope: AUTH_CONFIG.custom_app_scope,
            }),
        });
    }

    refreshCustomToken() {
        localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
        const refresh_token = getRefreshToken();

        if (!checkValidString(refresh_token)) {
            return new Promise((_, reject) => reject("refresh-token is invalid"));
        }

        return fetch("https://login.finexity.com/oauth/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                grant_type: "refresh_token",
                realm: "Username-Password-Authentication",
                refresh_token: refresh_token,
                client_id: AUTH_CONFIG.appClientId,
                audience: AUTH_CONFIG.audience,
            }),
        });
    }

    login(credentials, callback) {
        if (isClient()) {
            localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
            this.auth0.login(
                {
                    username: credentials.username,
                    password: credentials.password,
                },
                callback,
            );
        }
    }

    reNewSession(isPlatformSourceFromApp) {
        if (isClient()) {
            return new Promise(async (resolve) => {
                const isCallbackRoute = window.location.href.includes(`${window.location.origin}/token#`) === true;
                const connectionProvider = localStorage.getItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);
                const isTenantFinexity = getTenantFromHost(window.location.host) === TENANTS_LIST.FINEXITY;
                const accessTokenPayload = parseAuth0JwtToken(getAccessToken());

                if (!isTenantFinexity || isPlatformSourceFromApp) {
                    if (this.isRefreshingToken) await this.waitForTokenRefresh();

                    let id_token = getIdToken();
                    let access_token = getAccessToken();

                    if (isPlatformSourceFromApp) {
                        const isIdTokenExpired = isTokenExpired(id_token);
                        const isAccessTokenExpired = isTokenExpired(access_token);
                        const idTokenPayload = parseAuth0JwtToken(id_token);

                        if (!isIdTokenExpired) {
                            if (!isAccessTokenExpired) {
                                localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, access_token);
                            } else {
                                configureSentryUser({ email: idTokenPayload.email });
                                Sentry.captureMessage(
                                    `"NativeApp - received ${accessTokenPayload ? "expired" : "invalid"} access token"`,
                                );
                            }

                            localStorage.setItem(FINX_COOKIE.FINX_SESSION, id_token);
                            cookiesPkg.set(FINX_COOKIE.USER_EMAIL, idTokenPayload.email);

                            resolve({ idToken: id_token, accessToken: access_token, email: idTokenPayload.email });
                        } else {
                            if (idTokenPayload) configureSentryUser({ email: idTokenPayload.email });
                            Sentry.captureMessage("NativeApp - received expired id-token");

                            clearAllAuthCookie();
                            resolve({ error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED });
                        }
                    } else {
                        let isIdTokenExpired = isTokenExpiringSoon(id_token);
                        let isAccessTokenExpired = isTokenExpiringSoon(access_token);

                        if (!isIdTokenExpired && !isAccessTokenExpired) {
                            const idTokenPayload = parseAuth0JwtToken(id_token);

                            localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, access_token);
                            localStorage.setItem(FINX_COOKIE.FINX_SESSION, id_token);
                            cookiesPkg.set(FINX_COOKIE.USER_EMAIL, idTokenPayload.email);

                            resolve({ idToken: id_token, accessToken: access_token, email: idTokenPayload.email });
                        } else if (!isTenantFinexity && !connectionProvider) {
                            this.isRefreshingToken = true;
                            this.refreshCustomToken()
                                .then((response) => response.json())
                                .then((response) => {
                                    if (response?.id_token) {
                                        const idTokenPayload = parseAuth0JwtToken(response?.id_token);

                                        cookiesPkg.set(FINX_COOKIE.USER_EMAIL, idTokenPayload.email);
                                        localStorage.setItem(FINX_COOKIE.FINX_SESSION, response.id_token);
                                        localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, response.access_token);

                                        resolve({
                                            idToken: response.id_token,
                                            accessToken: response.access_token,
                                            email: idTokenPayload.email,
                                        });
                                    } else {
                                        clearAllAuthCookie();
                                        resolve({ error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED });
                                    }
                                })
                                .catch(() => {
                                    clearAllAuthCookie();
                                    resolve({ error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED });
                                })
                                .finally(() => {
                                    this.isRefreshingToken = false;
                                });
                        } else if (checkValidString(connectionProvider)) {
                            this.isRefreshingToken = true;
                            this.auth0.checkSession({}, (err, authResult) => {
                                this.isRefreshingToken = false;
                                if (authResult?.accessToken && authResult?.idToken) {
                                    cookiesPkg.set(FINX_COOKIE.USER_EMAIL, authResult.email);
                                    localStorage.setItem(FINX_COOKIE.FINX_SESSION, authResult.idToken);
                                    localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, authResult.accessToken);

                                    if (window.isPlatformFinexity) {
                                        cookiesPkg.set(FINX_COOKIE.FINX_SESSION, authResult.idToken, {
                                            domain: ".finexity.com",
                                        });
                                    }

                                    resolve({
                                        idToken: authResult.idToken,
                                        accessToken: authResult.accessToken,
                                        email: authResult.idTokenPayload.email,
                                        isLoggedInByAuthSdk: true,
                                        isTokenRefreshed: true,
                                    });
                                } else {
                                    // connectionProvider - is to handle social login on in-case of error of cross-domain origin
                                    if (isCallbackRoute) {
                                        id_token = getHashQueryStringParam("id_token") || id_token;
                                        access_token = getHashQueryStringParam("access_token") || access_token;

                                        isIdTokenExpired = isTokenExpired(id_token);
                                        isAccessTokenExpired = isTokenExpired(access_token);
                                    }

                                    if (!isIdTokenExpired && !isAccessTokenExpired) {
                                        const idTokenPayload = parseAuth0JwtToken(id_token);

                                        cookiesPkg.set(FINX_COOKIE.USER_EMAIL, idTokenPayload.email);
                                        localStorage.setItem(FINX_COOKIE.FINX_SESSION, id_token);
                                        localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, access_token);

                                        resolve({
                                            idToken: id_token,
                                            accessToken: access_token,
                                            email: idTokenPayload.email,
                                        });
                                    } else {
                                        clearAllAuthCookie();
                                        resolve({ error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED });
                                    }
                                }
                            });
                        } else {
                            clearAllAuthCookie();
                            resolve({ error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED });
                        }
                    }
                } else {
                    if (this.isRefreshingToken) await this.waitForTokenRefresh();

                    const id_token = getIdToken();
                    const access_token = getAccessToken();

                    if (isTokenExpiringSoon(id_token) || isTokenExpiringSoon(access_token)) {
                        this.isRefreshingToken = true;

                        this.auth0.checkSession({}, (err, authResult) => {
                            this.isRefreshingToken = false;
                            if (authResult?.accessToken && authResult?.idToken) {
                                cookiesPkg.set(FINX_COOKIE.USER_EMAIL, authResult.email);
                                localStorage.setItem(FINX_COOKIE.FINX_SESSION, authResult.idToken);
                                localStorage.setItem(FINX_COOKIE.FINX_ACCESS_TOKEN, authResult.accessToken);

                                if (window.isPlatformFinexity) {
                                    cookiesPkg.set(FINX_COOKIE.FINX_SESSION, authResult.idToken, { domain: ".finexity.com" });
                                }

                                resolve({
                                    idToken: authResult.idToken,
                                    accessToken: authResult.accessToken,
                                    email: authResult.idTokenPayload.email,
                                    isLoggedInByAuthSdk: true,
                                    isTokenRefreshed: true,
                                });
                            } else {
                                clearAllAuthCookie();
                                resolve({ error: API_UNAUTHORIZED_MSG.AUTH_LOGIN_REQUIRED });
                            }
                        });
                    } else {
                        this.isRefreshingToken = false;
                        const idTokenPayload = parseAuth0JwtToken(id_token);
                        resolve({
                            idToken: id_token,
                            accessToken: access_token,
                            email: idTokenPayload.email,
                            isLoggedInByAuthSdk: true,
                        });
                    }
                }
            });
        }
    }

    customLogout(optionalParams) {
        const redirectToLogin = optionalParams ? optionalParams?.redirectToLogin : true;
        const returnTo = optionalParams?.returnTo || `${window.location.origin}/login`;

        return new Promise(async (resolve) => {
            if (isClient()) {
                localStorage.removeItem(FINX_COOKIE.SOCIAL_LOGIN_PROVIDER);

                const { isUserLoggedInByAuth0Sdk } = window.isLoggedInByAuthSdk
                    ? { isUserLoggedInByAuth0Sdk: true }
                    : await this.checkUserIsLoggedInByAuth0Sdk();

                fetch("/logout").then((response) => {
                    if (response && response.ok) {
                        clearAllAuthCookie();

                        if (isUserLoggedInByAuth0Sdk) {
                            if (returnTo === "none") resolve(true);
                            else this.auth0.logout({ returnTo });
                        } else {
                            resolve(true);
                            if (redirectToLogin) {
                                window.location = "/login";
                            }
                        }
                    } else resolve(false);
                });
            } else resolve(false);
        });
    }

    // Helper function to wait for the token refresh promise
    waitForTokenRefresh() {
        return new Promise((resolve) => {
            const checkRefresh = setInterval(() => {
                if (!this.isRefreshingToken) {
                    clearInterval(checkRefresh);
                    resolve();
                }
            }, 100); // Check every 100 milliseconds
        });
    }

    async checkUserIsLoggedInByAuth0Sdk() {
        return new Promise((resolve) => {
            try {
                this.auth0.checkSession({}, (_, authResult) => {
                    resolve({
                        isUserLoggedInByAuth0Sdk: !!(authResult?.accessToken && authResult?.idToken),
                    });
                });
            } catch {
                resolve({
                    isUserLoggedInByAuth0Sdk: false,
                });
            }
        });
    }
}

const instance = new Auth();

export default instance;
