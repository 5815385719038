import React, { useEffect } from "react";
import TopGreyDiv from "../components/topGreyDiv";
import AccountDetailsBlock from "../components/accountDetailsBlock";
import PrevButton from "components/PrevButton";
import { getTranslatedCountriesList } from "constants/index";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { selectLocale } from "store/app/selectors";
import { connect } from "react-redux";

const messages = {
    payInTitle: "ewallet_payin",
    payInInfoHeading: "deposit_to_your_own_ewallet",
    payInInfoContent1: "your_own_reference_account",
    payInInfoContent2: "choose_the_deposit_amount",
    payInYourTransferDetails: "wallet_transfer_details",
    payInInfoContent3: "after_deposit_to_your_account",
};

const PayInInfo = ({ setShowDate, setCloseTitle, setStep, data, backSlideEffect = "", setBackSlideEffect, locale }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.payInTitle));
    }, []);

    const country =
        getTranslatedCountriesList(locale)?.find((item) => item.value === data?.mangopay?.BankingAlias?.IBAN?.substring(0, 2)) ||
        "";
    if (country && data?.mangopay?.BankingAlias) {
        data.mangopay.BankingAlias.Country = country.label;
    }

    return (
        <div className={`pay-in-info ${backSlideEffect}`}>
            <TopGreyDiv icon />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3 className="mb-6">{formatMessage(messages.payInInfoHeading)}</h3>
                <p className="text-16 mb-8">{formatMessage(messages.payInInfoContent1)}</p>
                <p className="text-16 mb-8">{formatMessage(messages.payInInfoContent2)}</p>
                <span className="text-16 mb-8 secondary-light-color d-inline-block">
                    {formatMessage(messages.payInYourTransferDetails)}
                </span>
                <AccountDetailsBlock data={data?.mangopay?.BankingAlias} />
                <p className="text-16 mb-15 mt-8">{formatMessage(messages.payInInfoContent3)}</p>
            </div>
            <div className="bottom-bar-container">
                <PrevButton
                    onClick={() => {
                        setStep("walletOverview");
                        setBackSlideEffect("step-slide-right");
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    locale: selectLocale(state),
});
export default connect(mapStateToProps)(PayInInfo);
