import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import SelectInput from "components/SelectInput";
import { yearsOptions } from "constants/index";
import { adequacySkipLinkText } from "i18n/messages/buttonTitles";
import { useSideModalScrollToTop } from "hooks";

const InvestmentFunds = ({
    isInvestmentFundsVaild,
    setActiveStep,
    formatMessage,
    messages,
    formData,
    setFormData,
    setAdequacyStart,
    adequacyData,
    backSlideEffect = "",
    setBackSlideEffect,
    open,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    return (
        <>
            <div className={`investment-funds multisteps ${backSlideEffect}`}>
                <div className="header-part">
                    <h4 className="primary-color">{formatMessage(messages.firstHeading)}</h4>
                </div>
                <div className="content">
                    <form method="post" className="survey-form">
                        <div className="first-question">
                            <h3 className="mt-4 mb-7">{formatMessage(messages.firstQuestion)}</h3>
                            <div className="tabs">
                                <div className="position-relative">
                                    <input
                                        className="tab-input-q1"
                                        id="investmentFundsYes"
                                        type="radio"
                                        name="investment-funds-experience"
                                        onChange={() =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                investment_funds: {
                                                    ...prevState.investment_funds,
                                                    IsInvestFundsXPConfirmed: true,
                                                },
                                            }))
                                        }
                                        checked={formData?.investment_funds?.IsInvestFundsXPConfirmed === true}
                                    />
                                    <label
                                        className="medium mw-100"
                                        htmlFor="investmentFundsYes"
                                        onClick={(e) =>
                                            jQuery(e.target).closest(".survey-form").find(".second-question").slideDown()
                                        }
                                    >
                                        {formatMessage(messages.yesText)}
                                    </label>
                                </div>

                                <div className="position-relative">
                                    <input
                                        id="investmentFundsNo"
                                        type="radio"
                                        className="tab-input-q1"
                                        name="investment-funds-experience"
                                        onChange={() =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                investment_funds: {
                                                    ...prevState.investment_funds,
                                                    IsInvestFundsXPConfirmed: false,
                                                    IsInvestFundsXPConfirmedDate: "",
                                                },
                                            }))
                                        }
                                        checked={formData?.investment_funds?.IsInvestFundsXPConfirmed === false}
                                    />
                                    <label
                                        className="medium mw-100"
                                        htmlFor="investmentFundsNo"
                                        onClick={(e) =>
                                            jQuery(e.target).closest(".survey-form").find(".second-question").slideUp()
                                        }
                                    >
                                        {formatMessage(messages.noText)}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            className="second-question"
                            style={{ display: formData.investment_funds.IsInvestFundsXPConfirmed ? "block" : "none" }}
                        >
                            <div className="border-x" />
                            <h4>{formatMessage(messages.firstQuestionOther)}</h4>
                            <div className="">
                                <SelectInput
                                    maxMenuHeight={400}
                                    isSearchable={false}
                                    menuPlacement="top"
                                    className="select form-item"
                                    classNamePrefix="select"
                                    name="investment-funds-experience-years"
                                    options={yearsOptions()}
                                    placeholder={formatMessage(messages.pleaseSelect)}
                                    value={formData?.investment_funds?.IsInvestFundsXPConfirmedDate || ""}
                                    onChange={(selectedOption) => {
                                        setFormData((prevState) => ({
                                            ...prevState,
                                            investment_funds: {
                                                ...prevState.investment_funds,
                                                IsInvestFundsXPConfirmedDate: selectedOption,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                {StatusAdequacy != "renew" && (
                    <span
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-right");
                        }}
                        className="skip-link"
                    >
                        {formatMessage(adequacySkipLinkText)}
                    </span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setAdequacyStart({ step: 5, survey_second_step: { ...formData, step: 1 } });
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        className={`${isInvestmentFundsVaild ? "" : "disable-grey"}`}
                        onClick={() => {
                            setAdequacyStart({
                                step: 6,
                                survey_second_step: { ...adequacyData?.survey_second_step, ...formData, step: 2 },
                            });
                            isInvestmentFundsVaild && setActiveStep(2);
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default InvestmentFunds;
