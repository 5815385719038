import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createStructuredSelector } from "reselect";

import { selectUserDetails } from "store/user/account/selectors";
import { fetchUserDetailsSuccess } from "store/user/account/actions";

import Checkbox from "components/Checkbox";
import EuroImage from "assets/images/svg/euro.svg";
import { withFormik } from "formik";

import { useFormattedMessage, useSideModalScrollToTop } from "hooks";

import { serviceNotAvailable } from "i18n/messages/errors";

import { mangopayOnboarding } from "api";
import NewLoader from "components/NewLoader";

const TermsNCondition = ({
    setShowDate,
    setCloseTitle,
    isSubmitting,
    submitForm,
    setFieldValue,
    values,
    status,
    backSlideEffect = "",
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setShowDate(false);
        setCloseTitle("Euro-Wallet");
    }, []);

    const label = () => {
        return (
            <label htmlFor="checkBoxPrivate">
                I accept the <span className="primary-color cursor-pointer">terms and conditions</span> of Mangopay and want to
                create an wallet
            </label>
        );
    };

    const handleCheckBox = (event) => {
        setFieldValue("isAgree", "" + event.target.checked);
    };

    return (
        <div className={`terms-n-condition ${backSlideEffect}`}>
            <div className="hf-50 flex justify-center align-center bg-dark">
                <EuroImage />
            </div>
            <div className="py-10 px-5 mw-450 mx-auto">
                <h3 className="mb-10">Please accept the terms and conditions to continue</h3>
                <p className="text-16 mb-6">
                    Lorem ipsum dolor sit amet, duo id wisi porro iudico. Prompta forensibus reprehendunt mea te, regione delectus
                    intellegat cu ius. Quo adhuc regione scriptorem cu. Vide vocent gubergren qui te, et quo audire aliquid
                    nominavi.
                </p>
                <h5 className="mb-3">Lorem ipsum dolor sit amet,</h5>
                <p className="text-16 mb-6">
                    Lorem ipsum dolor sit amet, duo id wisi porro iudico. Prompta forensibus reprehendunt mea te, regione delectus
                    intellegat cu ius. Quo adhuc regione scriptorem cu. Vide vocent gubergren qui te, et quo audire aliquid
                    nominavi.
                </p>
                <h5 className="mb-3">Lorem ipsum dolor sit amet,</h5>
                <p className="text-16">
                    Lorem ipsum dolor sit amet, duo id wisi porro iudico. Prompta forensibus reprehendunt mea te, regione delectus
                    intellegat cu ius. Quo adhuc regione scriptorem cu. Vide vocent gubergren qui te, et quo audire aliquid
                    nominavi.
                </p>
            </div>
            <div className="px-6">
                <Checkbox
                    className={`euro-terms-checkbox ${status && status.requestError ? "mb-4" : "mb-10"}`}
                    inputId="chekBoxPrivate"
                    value="true"
                    label={label}
                    name="isAgree"
                    onChange={handleCheckBox}
                    values={values.isAgree}
                    isError={status && status.requestError}
                />
                {status && status.requestError ? (
                    <div className="flex">
                        <span className="text-14 pl-11 mb-10 alert-red-color">{formatMessage(serviceNotAvailable)}</span>
                    </div>
                ) : null}
                <button
                    className={isSubmitting || values.isAgree === "false" ? "disable-grey" : ""}
                    disabled={isSubmitting || values.isAgree === "false"}
                    onClick={submitForm}
                >
                    {isSubmitting ? (
                        <>
                            <NewLoader />
                            Creating
                        </>
                    ) : (
                        "Create a Euro Wallet"
                    )}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    user: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserDetailsSuccess: (data) => dispatch(fetchUserDetailsSuccess(data)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: () => ({ isAgree: "false" }),
        handleSubmit: (values, { props, setSubmitting, setStatus }) => {
            setSubmitting(true);

            const payload = {
                uid: props.user && props.user.uid,
                Mangopay: {
                    uid: props.user && props.user.Mangopay && props.user.Mangopay.uid,
                    IsMangopayTermsConditionsConfirmed: "true",
                },
            };

            mangopayOnboarding({ user: payload })
                .then((res) => {
                    setSubmitting(false);

                    if (!res || res.errors || res.data.errors || res.data.data.errors) {
                        setStatus({ requestError: true });
                        return;
                    }

                    props.fetchUserDetailsSuccess(res.data.data.mangopayOnboarding.user);
                    props.setStep("walletOverview");
                    props.setBackSlideEffect("step-slide-left");
                })
                .catch(() => {
                    setSubmitting(false);
                });
        },
    })
)(TermsNCondition);
