import AppActionTypes from "./types";

export const setPlatformSource = (source) => ({
    type: AppActionTypes.SET_PLATFORM_SOURCE,
    payload: source,
});

export const setMobilePlatform = (mobilePlatform) => ({
    type: AppActionTypes.SET_MOBILE_PLATFORM,
    payload: mobilePlatform,
});

export const setMpinFlag = (source) => ({
    type: AppActionTypes.MPIN,
    payload: source,
});

export const setMobileNotification = (source) => ({
    type: AppActionTypes.MOBILE_NOTIFICATION,
    payload: source,
});

export const setIsClubdeal = (source) => ({
    type: AppActionTypes.IS_CLUBDEAL,
    payload: source,
});

export const setTenant = (tenant) => ({
    type: AppActionTypes.SET_TENANT,
    payload: tenant,
});

export const setFinxConfig = (config) => ({
    type: AppActionTypes.SET_FINX_CONFIG,
    payload: config,
});

export const setHost = (host) => ({
    type: AppActionTypes.SET_HOST,
    payload: host,
});

export const setDocumentHost = (documentHost) => ({
    type: AppActionTypes.SET_DOCUMENT_HOST,
    payload: documentHost,
});

export const setEnteredUrl = (payload) => ({ type: AppActionTypes.SET_ENTERED_URL, payload });

export const fetchTenantStart = (result) => ({
    type: AppActionTypes.FETCH_TENANT_START,
    payload: result,
});

export const fetchTenantSuccess = (payload) => ({
    type: AppActionTypes.FETCH_TENANT_SUCCESS,
    payload: payload,
});

export const fetchTenantFailure = (error) => ({
    type: AppActionTypes.FETCH_TENANT_FAILURE,
    payload: error,
});

//locale
export function setLocale(locale) {
    return { type: AppActionTypes.SET_LOCALE, payload: locale };
}
