import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import KycModal from "components/KycModal";
import { useFormattedMessage } from "hooks";
import { ACCOUNT_HASH_VARIABLES } from "constants/index";
import { KYC_STATUS_LOCAL, TILE_STATUS } from "constants/status";
import ArrowNext from "assets/images/svg/next.svg";
import { addOverlay } from "utils";
import { selectKYCServerError } from "store/user/kyc/selectors";

const messages = {
    kycTileTitle: "your_identification",
    kycTileContent: "trust_and_security",
    kycTileErrorContent: "error_identifying",
    kycTileSuccessContent: "identification_completed",
    kycTileInitiatedContent: "identification_started",
    kycTilePendingContent: "confirmation_by_email",
    kycTileShowReKYCPendingContent: "identification_data_outdated",
    kycTileInitiatedReKYCPendingContent: "change_registration_address",
    tileComingSoonDeactivateButtonText: "available_shortly",
    tileComingSoonPendingButtonText: "in_processing",
    kycServerError: "kyc_server_error",
};

const WrappedKycModal = ({
    currentKYCStatus,
    setKycModalFlag,
    kycModalFlag,
    onCloseButtonClick,
    addHash,
    removeHash,
    isKYCServerError,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [activeKycStep, setActiveKycStep] = useState(false);
    const [kycAtStep1, setKycAtStep1] = useState(false);

    const kycMessages = {
        [KYC_STATUS_LOCAL.ERROR]: formatMessage(messages.kycTileErrorContent),
        [KYC_STATUS_LOCAL.COMPLETED]: formatMessage(messages.kycTileSuccessContent),
        [KYC_STATUS_LOCAL.INITIATED]: formatMessage(messages.kycTileInitiatedContent),
        [KYC_STATUS_LOCAL.PENDING]: formatMessage(messages.kycTilePendingContent),
        [KYC_STATUS_LOCAL.REKYC]: formatMessage(messages.kycTileShowReKYCPendingContent),
        [KYC_STATUS_LOCAL.REKYC_PENDING]: formatMessage(messages.kycTileInitiatedReKYCPendingContent),
    };

    const kycMessage = (status) => {
        return kycMessages[status] || formatMessage(messages.kycTileContent);
    };

    const comingSoonMessages = {
        [TILE_STATUS.DEACTIVATE]: formatMessage(messages.tileComingSoonDeactivateButtonText),
        [TILE_STATUS.PENDING]: formatMessage(messages.tileComingSoonPendingButtonText),
    };

    const statusClassName = {
        //new
        [KYC_STATUS_LOCAL.COMPLETED]: "tile-success",
        [KYC_STATUS_LOCAL.INITIATED]: "tile-initiate",
        [KYC_STATUS_LOCAL.PENDING]: "tile-deactive",
        [KYC_STATUS_LOCAL.ERROR]: "tile-error",
        //old
        success: "tile-success",
        deactivate: "tile-deactive",
        initiated: "tile-initiate",
        pending: "tile-deactive",
        error: "tile-error",
    };

    const statusIcon = {
        success: "check",
        error: "times",
    };

    useEffect(() => {
        if (kycAtStep1) {
            setKycModalFlag(false);
            setKycAtStep1(false);
        }
    }, [kycAtStep1]);

    useEffect(() => {
        kycModalFlag && addOverlay();
    }, [kycModalFlag]);

    return (
        <Fragment>
            <KycModal
                open={kycModalFlag}
                onBackButtonClick={() => {
                    removeHash();
                    setKycModalFlag(false);
                }}
                onCloseButtonClick={onCloseButtonClick}
                activeStep={activeKycStep}
                setActiveStep={() => setActiveKycStep(false)}
                setKycAtStep1={() => setKycAtStep1(true)}
            />

            <div
                className={`identity-block identification-block border-radius pointer bg-primary-light ${
                    statusClassName[currentKYCStatus] || ""
                }`}
                onClick={() => {
                    if (!isKYCServerError) {
                        setKycModalFlag(true);
                        addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_KYC);
                    }
                }}
            >
                {currentKYCStatus === KYC_STATUS_LOCAL.COMPLETED && (
                    <div className="status-icon-container">
                        <div className="status-icon">
                            <i className={`fas fa-${statusIcon[currentKYCStatus] || ""}`} />
                        </div>
                    </div>
                )}

                <div className="d-flex justify-between slign-center">
                    <span className="body-medium">{formatMessage(messages.kycTileTitle)}</span>
                    {isKYCServerError ? null : (
                        <span className="next-arrow">
                            <ArrowNext />
                        </span>
                    )}
                </div>
                <div className="description">
                    <div className="my-0 text-16 fw-600">
                        {isKYCServerError ? formatMessage(messages.kycServerError) : kycMessage(currentKYCStatus)}
                    </div>
                </div>
                {currentKYCStatus !== KYC_STATUS_LOCAL.INITIATED && comingSoonMessages[currentKYCStatus] && (
                    <button className="coming-soon-btn medium mb-0">{comingSoonMessages[currentKYCStatus]}</button>
                )}
            </div>
        </Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    isKYCServerError: selectKYCServerError,
});

export default connect(mapStateToProps)(WrappedKycModal);
