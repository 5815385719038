import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useFormattedMessage, useUtils } from "hooks";
import Link from "components/NavLink";

// import Insta from "assets/images/svg/insta.svg";
// import Facebook from "assets/images/svg/fb.svg";
// import Linkedin from "assets/images/svg/linkedin.svg";
// import Xing from "assets/images/svg/xing.svg";
// import Youtube from "assets/images/svg/youtube.svg";
// import Twitter from "assets/images/svg/twitter.svg";
import Finexity from "assets/images/svg/FINEXITY_logo_black.svg";
// import MobileApp from "components/MobileApp";
// import { GENERAL_CONSTANTS, TENANTS_WITH_REGISTRATION_DISABLED, WEB_FLOW_ROUTES } from "constants/index";
import { TENANTS_WITH_REGISTRATION_DISABLED, WEB_FLOW_ROUTES } from "constants/index";
import { selectLocale } from "store/app/selectors";

const messages = {
    companyTitle: "company",
    companyAbout: "about_us",
    companyTeam: "our_management",
    companyContact: "contact",
    hiring: "we_are_hiring",
    pressTitle: "press_title",
    pressBlog: "press_blog",
    valuableKnowledge: "knowledge_base_link",
    valuableGlossary: "valuable_glossary",
    valuableFaq: "faq",
    socialMediaTitle: "follow_us",
    downloadAppTitle: "download_app",
    imprint: "imprint",
    dataProtection: "footer_privacy_policy",
    footerTnC: "scc_terms_conditions",
    footerBottomLine: "footer_bottom_line",
    footerInvestmentTitle: "investment_platform",
    footerPartner: "partner_program",
    footerSaas: "by_fnxt",
    footerAmbassador: "investment_ambassador",
    footerDiscoverTitle: "discover_title",
    clubdeal: "club_deal",
    footerWPC: "whistleblower_protection_act",
};

const Footer = ({ locale }) => {
    const { formatMessage } = useFormattedMessage();
    const { isTenantFinexity, tenant } = useUtils();
    const ssgAgbLinks = { en: tenant.URLSCCGDPR_EN, de: tenant.URLSCCGDPR_DE };

    return (
        <footer id="mainFooter" className="footer broad-container article multi-asset-footer">
            <div className="container flex-row">
                <div className={`flex-row footer__bottom align-center ${isTenantFinexity ? "" : "tenant__footer"}`}>
                    <div className="footer__item">
                        <div className="flex-row footer__bottom-links justify-end">
                            <p className="footer__bottom-link">
                                <Link
                                    className="new-link"
                                    href={isTenantFinexity ? `${WEB_FLOW_ROUTES.IMPRINT}` : tenant?.URLImprint}
                                >
                                    {formatMessage(messages.imprint)}
                                </Link>
                            </p>
                            <p className="footer__bottom-link">
                                <Link
                                    className="new-link"
                                    href={isTenantFinexity ? `${WEB_FLOW_ROUTES.PRIVACY_POLICY}` : tenant?.URLDataPrivacy}
                                >
                                    {formatMessage(messages.dataProtection)}
                                </Link>
                            </p>
                            <p className="footer__bottom-link">
                                <Link className="new-link" href={ssgAgbLinks[locale]}>
                                    {formatMessage(messages.footerTnC)}
                                </Link>
                            </p>
                            {!!(tenant?.WPALink && tenant?.WPALink.trim()) && (
                                <p className="footer__bottom-link">
                                    <Link className="new-link" href={tenant?.WPALink}>
                                        {formatMessage(messages.footerWPC)}
                                    </Link>
                                </p>
                            )}
                            {!!(tenant?.CodeOfConductUrl && tenant?.CodeOfConductUrl.trim()) && (
                                <p className="footer__bottom-link">
                                    <Link className="new-link" href={tenant?.CodeOfConductUrl}>
                                        {locale === "en" ? tenant?.CodeOfConduct_EN : tenant?.CodeOfConduct_DE}
                                    </Link>
                                </p>
                            )}
                        </div>
                        {!TENANTS_WITH_REGISTRATION_DISABLED.includes(tenant?.TechnicalName) && (
                            <p className="footer-bottom-line body-small text-right">
                                {`${tenant?.LegalName} ${formatMessage(messages.footerBottomLine)}`}
                            </p>
                        )}
                    </div>
                    <div className="footer__logo">
                        {isTenantFinexity ? (
                            <Link className="new-link logo" href="/">
                                <Finexity width={108} height={16} />
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>
        </footer>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps)(Footer);
