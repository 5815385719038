// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";

const FINX_COOKIE = require("common/constants");

const checkSentryEnabledNonProdEnvs = () => process.env.SENTRY_ENVIRONMENT !== "prod" && process.env.NODE_ENV === "production";

function initiateSentry() {
    const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

    Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        enabled: process.env.NODE_ENV === "production",
        release: process.env.SENTRY_RELEASE,
        environment: process.env.SENTRY_ENVIRONMENT,
    });
}

// Listening if user has already accepted the consent
const isCookieConsentAccepted = !!(
    Cookies.get(FINX_COOKIE.COOKIEFIRST_CONSENT) && localStorage.getItem(FINX_COOKIE.COOKIEFIRST_CONSENT)
);

if (isCookieConsentAccepted || checkSentryEnabledNonProdEnvs()) {
    initiateSentry();
} else {
    //Listening the event when user accept the consent.
    window.addEventListener("cf_consent", initiateSentry);
    if (window?.cmp_cdn === "cdn.consentmanager.net") {
        // eslint-disable-next-line no-undef
        __cmp("addEventListener", ["consentapproved", initiateSentry, false], null);
    }
}
