import { KYC_STATUS_LOCAL } from "constants/status";
import FnxtSingletonApi from "./FnxtSingletonApi";

//UMBRELLA
export function umbrellaResendUser(uuid) {
    return FnxtSingletonApi.post("/umbrellaResendUser", { uuid });
}

export function umbrellaResendInvestment(uid) {
    return FnxtSingletonApi.post("/umbrellaResendInvestment", { uid: uid });
}

export function umbrellaUpdateUsers({ userIds, isApprove }) {
    return FnxtSingletonApi.post("/umbrellaUpdateUsers", { user_uids: userIds, approved: isApprove });
}

export function umbrellaUpdateInvestments({ investmentIDs, isApprove }) {
    return FnxtSingletonApi.post("/umbrellaUpdateInvestments", { investment_uids: investmentIDs, approved: isApprove });
}

//KYC
export function getKYC() {
    return FnxtSingletonApi.get("/kyc/v1/user");
}

export function getKYCCombined() {
    return FnxtSingletonApi.get("/kyc/v1/kyc");
}

export function submitKYC(payload, kycStatus) {
    if (kycStatus && kycStatus !== KYC_STATUS_LOCAL.INCOMPLETE) {
        return FnxtSingletonApi.patch("/kyc/v1/user", payload);
    } else {
        return FnxtSingletonApi.post("/kyc/v1/user", payload);
    }
}

export function updateKYC({ payload }) {
    return FnxtSingletonApi.post("/kyc/v1/user/", payload);
}

export function getReKYCFlag() {
    return FnxtSingletonApi.get("/kyc/v1/rekyc");
}

export function setReKYCFlag({ payload }) {
    return FnxtSingletonApi.post("/kyc/v1/rekyc", payload);
}

export function getPostident(tenantName) {
    return FnxtSingletonApi.get("/kyc/v1/postident/" + tenantName);
}

export function getPostidentPDF(tenantName) {
    return FnxtSingletonApi.get(`/kyc/v1/postident/${tenantName}/coupon/`, {}, { responseType: "arraybuffer" });
}

export function setPostident() {
    return FnxtSingletonApi.post("/kyc/v1/postident/");
}

export function submitPostident(payload) {
    return FnxtSingletonApi.post("/kyc/v1/postident", payload);
}

export function getIDnowLink(tenantName) {
    return FnxtSingletonApi.get("/kyc/v1/idnow/" + tenantName);
}

export function getIDnowEstimatedTime() {
    return FnxtSingletonApi.get("/kyc/v1/idnow/estimated-waiting-time");
}

export function submitIDnow(payload) {
    return FnxtSingletonApi.post("/kyc/v1/idnow/", payload);
}

export function checkIBAN(iban) {
    return FnxtSingletonApi.get("/kyc/v1/iban/" + iban);
}

//KYC: Admin
export function getUserKYCDetailsFromAdmin(userid) {
    return FnxtSingletonApi.get("/kyc/v1/admin/user/" + userid);
}

export function getUserPostidentCouponFromAdmin(userid, tenantName) {
    return FnxtSingletonApi.get(
        `/kyc/v1/admin/user/${userid}/postident/${tenantName}/coupon`,
        {},
        { responseType: "arraybuffer" },
    );
}
export function createUserPostidentCouponFromAdmin(userid, tenantName) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/user/${userid}/postident/${tenantName}`);
}

export function enableUserReKYCFromAdmin(userid) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/user/${userid}/rekyc`);
}

export function updateIDNowIdentificationFromAdmin(userid) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/user/${userid}/idnow`);
}

export function setManualKYC(payload, kycData) {
    return FnxtSingletonApi.post(
        `/kyc/webhook/manual/${payload.uid}?Tenant=${payload.tenant}&IDNumber=${payload.IDDocumentNumber}&IDIssueDate=${payload.IDIssueDate}&IDExpiryDate=${payload.IDExpiryDate}`,
        kycData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    );
}

export function idNowMockSuccessFromAdmin(payload) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/idnow/mock-success`, payload);
}

export function idNowMockFailureFromAdmin(payload) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/idnow/mock-failure`, payload);
}

export function idNowMockPendingFromAdmin(payload) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/idnow/mock-pending`, payload);
}

export function idNowMockExpiredFromAdmin(payload) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/idnow/mock-expired`, payload);
}

export function postidentMockSuccessFromAdmin(payload) {
    return FnxtSingletonApi.post(`/kyc/v1/admin/postident/mock-success`, payload);
}
