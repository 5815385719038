import React, { useEffect } from "react";
import { withFormik } from "formik";
import { compose } from "recompose";

import * as yup from "yup";
import { useFormattedMessage } from "hooks";

import PasswordInput from "components/Input/passwordInput";

import { changePassword } from "api";
import { sendFormError, commonPassword, weakPassword, incorrectPassword } from "i18n/messages/errors";

import { validationObject } from "utils/yupValidation";
import { password } from "i18n/messages/formElements";
import NewLoader from "components/NewLoader";

const messages = {
    sendFormError: sendFormError,
    commonPassword: commonPassword,
    weakPassword: weakPassword,
    incorrectPassword: incorrectPassword,
};

const ChangePasswordForm = ({
    values,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldTouched,
    submitForm,
    status,
    validateForm,
}) => {
    const { formatMessage } = useFormattedMessage();
    const onEnterPress = (event) => {
        if (event.key === "Enter") {
            submitForm();
        }
    };

    useEffect(() => {
        validateForm();
    }, [values, touched]);
    return (
        <div className="form-holder">
            <form method="post" onSubmit={handleSubmit}>
                <PasswordInput
                    name="oldPassword"
                    id="ch-oldPassword"
                    className="mb-5"
                    onKeyUp={onEnterPress}
                    placeholder={formatMessage(password.oldPassword)}
                    inputClassName="text-input form-item ch-new-password-input"
                    onChange={(e) => {
                        handleChange(e);
                        setFieldTouched("oldPassword", true);
                    }}
                    label={formatMessage(password.oldPassword)}
                    value={values.oldPassword}
                    onBlur={handleBlur}
                    errorMessage={errors.oldPassword ? formatMessage(password[errors.oldPassword]) : null}
                    error={touched.oldPassword && errors.oldPassword}
                />

                <PasswordInput
                    name="newPassword"
                    id="ch-newPassword"
                    className="mb-5"
                    onKeyUp={onEnterPress}
                    placeholder={formatMessage(password.newPasswordPlaceholder)}
                    inputClassName="text-input form-item ch-new-password-input"
                    onChange={(e) => {
                        handleChange(e);
                        setFieldTouched("newPassword", true);
                    }}
                    label={formatMessage(password.newPasswordPlaceholder)}
                    value={values.newPassword}
                    onBlur={handleBlur}
                    errorMessage={errors.newPassword ? formatMessage(password[errors.newPassword]) : null}
                    error={touched.newPassword && errors.newPassword}
                />

                <PasswordInput
                    name="confirmPassword"
                    id="ch-confirmPassword"
                    className="mb-5"
                    onKeyUp={onEnterPress}
                    placeholder={formatMessage(password.confirmPasswordPlaceholder)}
                    inputClassName="text-input form-item ch-new-password-input"
                    onChange={(e) => {
                        handleChange(e);
                        setFieldTouched("confirmPassword", true);
                    }}
                    label={formatMessage(password.confirmPasswordPlaceholder)}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    errorMessage={errors.confirmPassword ? formatMessage(password[errors.confirmPassword]) : null}
                    error={touched.confirmPassword && errors.confirmPassword}
                />

                <div className="flex column mt-10">
                    {errors.password || (status && status.requestError) ? (
                        <span className="error pl-0 mw-100">
                            {errors.password === "weakPassword"
                                ? formatMessage(messages.weakPassword)
                                : errors.password === "commonPassword"
                                ? formatMessage(messages.commonPassword)
                                : status.requestError
                                ? formatMessage(messages.sendFormError)
                                : null}
                        </span>
                    ) : null}

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`mw-100 ${isSubmitting || Object.keys(errors).length ? "disable-grey" : ""}`}
                    >
                        {isSubmitting ? (
                            <>
                                <NewLoader /> {formatMessage(password.changePassword)}
                            </>
                        ) : (
                            formatMessage(password.changePassword)
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default compose(
    withFormik({
        mapPropsToValues: () => ({ oldPassword: "", newPassword: "", confirmPassword: "" }),
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: () =>
            yup.object().shape({
                oldPassword: validationObject.oldPassword,
                newPassword: yup
                    .string()
                    .test("newPassword", "oldNewPasswordSame", (value, context) => {
                        const { oldPassword } = context?.from[0]?.value;
                        return oldPassword !== value;
                    })
                    .min(6, "minimumPasswordLength")
                    .required("newPasswordRequired"),
                confirmPassword: validationObject.confirmPassword,
            }),
        handleSubmit: (values, { setSubmitting, setStatus, resetForm, setFieldError, props }) => {
            try {
                setSubmitting(true);
                setStatus({ requestError: null, success: false });
                changePassword({ password: values.newPassword, oldPassword: values.oldPassword })
                    .then((res) => {
                        setSubmitting(false);
                        if (res.data.errors) {
                            const error = res.data.errors[0].message;
                            if (error === "weakPassword") {
                                setFieldError("password", "weakPassword");
                            } else if (error === "unauthorized") {
                                setFieldError("password", "unauthorized");
                            } else if (error === "commonPassword") {
                                setFieldError("password", "commonPassword");
                            } else if (error === "old password incorrect" || error.includes("old password incorrect")) {
                                setFieldError("oldPassword", "incorrectPassword");
                            } else {
                                setStatus({ requestError: true });
                            }
                        } else {
                            resetForm({});
                            props && props.onSuccessOfChangePassword && props.onSuccessOfChangePassword();
                        }
                    })
                    .catch(() => {
                        setStatus({ requestError: true, success: false });
                        setSubmitting(false);
                    });
            } catch (error) {
                setStatus({ requestError: true, success: false });
                setSubmitting(false);
            }
        },
        displayName: "ChangePasswordForm",
    })
)(ChangePasswordForm);
