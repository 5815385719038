import KYCActionTypes from "./types";

export const callTheInitialKYCMethods = (reqData) => ({
    type: KYCActionTypes.CALL_INITIAL_KYC_METHODS,
    payload: reqData,
});

export const callTheInheritUserInitialKYCMethod = (reqData) => ({
    type: KYCActionTypes.CALL_INHERIT_USER_INITIAL_KYC_METHOD,
    payload: reqData,
});

export const setKYCStart = (reqData) => ({
    type: KYCActionTypes.SET_KYC_START,
    payload: reqData,
});

export const setKYCSuccess = (kycData) => ({
    type: KYCActionTypes.SET_KYC_SUCCESS,
    payload: kycData,
});

export const setKYCFailure = (error) => ({
    type: KYCActionTypes.SET_KYC_FAILURE,
    payload: error,
});

//Update local form data
export const updateKYCFormData = (newKycData) => ({
    type: KYCActionTypes.UPDATE_KYC_FORM_DATA,
    payload: newKycData,
});

//KYC-data
export const fetchKYCStart = () => ({
    type: KYCActionTypes.FETCH_KYC_START,
});

export const fetchKYCSuccess = (kycData) => ({
    type: KYCActionTypes.FETCH_KYC_SUCCESS,
    payload: kycData,
});

export const fetchKYCFailure = (error) => ({
    type: KYCActionTypes.FETCH_KYC_FAILURE,
    payload: error,
});

//ID-NOW
export const fetchIDnowStart = (payload) => ({
    type: KYCActionTypes.FETCH_IDNOW_START,
    payload: payload,
});

export const fetchIDnowSuccess = (payload) => ({
    type: KYCActionTypes.FETCH_IDNOW_SUCCESS,
    payload: payload,
});

export const fetchIDnowFailure = (payload) => ({
    type: KYCActionTypes.FETCH_IDNOW_FAILURE,
    payload: payload,
});

//PostIdent
export const fetchPostIdentStart = (payload) => ({
    type: KYCActionTypes.FETCH_POSTIDENT_START,
    payload: payload,
});

export const fetchPostIdentSuccess = (payload) => ({
    type: KYCActionTypes.FETCH_POSTIDENT_SUCCESS,
    payload: payload,
});

export const fetchPostIdentFailure = (error) => ({
    type: KYCActionTypes.FETCH_POSTIDENT_FAILURE,
    payload: error,
});

//RE_KYC
export const fetchReKycStart = (payload) => ({
    type: KYCActionTypes.FETCH_REKYC_START,
    payload: payload,
});

export const fetchReKycSuccess = (payload) => ({
    type: KYCActionTypes.FETCH_REKYC_SUCCESS,
    payload: payload,
});

export const fetchReKycFailure = (error) => ({
    type: KYCActionTypes.FETCH_REKYC_FAILURE,
    payload: error,
});

// SET RE_KYC
export const setReKycStart = (payload) => ({
    type: KYCActionTypes.SET_REKYC_START,
    payload: payload,
});

export const setReKycSuccess = (payload) => ({
    type: KYCActionTypes.SET_REKYC_SUCCESS,
    payload: payload,
});

export const setReKycFailure = (error) => ({
    type: KYCActionTypes.SET_REKYC_FAILURE,
    payload: error,
});

//KYC-FROM_PROVIDER
export const fetchKycFromProviderStart = () => ({
    type: KYCActionTypes.FETCH_KYC_FROM_PROVIDER_START,
});

export const fetchKycFromProviderSuccess = (payload) => ({
    type: KYCActionTypes.FETCH_KYC_FROM_PROVIDER_SUCCESS,
    payload: payload,
});

export const fetchKycFromProviderFailure = (error) => ({
    type: KYCActionTypes.FETCH_KYC_FROM_PROVIDER_FAILURE,
    payload: error,
});

export const fetchKycServerError = (error) => ({
    type: KYCActionTypes.FETCH_KYC_SERVER_ERROR,
    payload: error,
});

// Reset the form data back to KYCdata
export const resetKYCFormData = (payload) => ({
    type: KYCActionTypes.RESET_KYC_FORM_DATA,
    payload: payload,
});

///ADMIN APIs

//KYC-data
export const fetchKYCStartFromAdmin = (payload) => ({
    type: KYCActionTypes.FETCH_KYC_START_ADMIN,
    payload: payload,
});

export const fetchKYCSuccessFromAdmin = (kycData) => ({
    type: KYCActionTypes.FETCH_KYC_SUCCESS_ADMIN,
    payload: kycData,
});

export const updateKYCDataFromAdmin = (kycData) => ({
    type: KYCActionTypes.UPDATE_KYC_ADMIN,
    payload: kycData,
});

export const fetchKYCFailureFromAdmin = (error) => ({
    type: KYCActionTypes.FETCH_KYC_FAILURE_ADMIN,
    payload: error,
});
