import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "js-cookie";

import translationEN from "../../public/locales/en/translation.json";
import translationDE from "../../public/locales/de/translation.json";

const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

// Get the value of the 'finexity-language' cookie
const finexityLanguageCookie = Cookies.get("finexity-language");

// Set the initial language to the value of the cookie, or 'en' if the cookie is not found or has an invalid value
const initialLanguage = finexityLanguageCookie || "en";

// Create the i18next instance
const i18nInstance = createInstance({
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources, // Set the translation resources
    lng: initialLanguage, // Set the initial language
    fallbackLng: "en", // Set the fallback language
});

// Initialize the i18next instance with the required plugins and configurations
i18nInstance
    .use(initReactI18next) // Use the react-i18next plugin
    .use(LanguageDetector) // Use the i18next-browser-languagedetector plugin
    .init({
        detection: {
            order: ["cookie"], // Look for the cookie first to determine the language preference
            caches: ["cookie"], // Cache the language preference in the cookie
            lookupCookie: "finexity-language", // Specify the name of the cookie to look for
            lookupFromCookieFirst: true, // Look for the cookie before checking other sources
        },
    });

// Export the i18next instance for use in the application
export default i18nInstance;
