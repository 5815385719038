import React from "react";
import { connect } from "react-redux";
import { useFormattedMessage } from "hooks";
import countries from "i18n-iso-countries";
import SelectInput from "./SelectInput";
import { selectLocale } from "store/app/selectors";
import { groupedCountries } from "constants/index";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const messages = {
    pleaseSelect: "please_select",
    oftenUsed: "often_used",
    allCountries: "all_countries",
};

const CountryPicker = ({
    locale,
    label = "",
    className = "mb-8",
    isSearchable = true,
    name = "Country",
    selectClassName = "id-nationality-input",
    placeholder = "",
    value,
    onCountryChange = () => {},
    errorMessage = "",
    isError = false,
    onBlur = () => {},
    ...rest
}) => {
    const { formatMessage } = useFormattedMessage();
    return (
        <SelectInput
            className={className}
            label={label}
            maxMenuHeight={400}
            isSearchable={isSearchable}
            classNamePrefix="select"
            name={name}
            selectClassName={selectClassName}
            options={groupedCountries(locale)}
            placeholder={placeholder || formatMessage(messages.pleaseSelect)}
            value={value}
            onChange={onCountryChange}
            onBlur={onBlur}
            errorMessage={errorMessage}
            error={isError}
            {...rest}
        />
    );
};
const mapStateToProps = (state) => ({
    locale: selectLocale(state),
});
export default connect(mapStateToProps)(CountryPicker);
