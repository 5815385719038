import { useFormattedMessage } from "hooks";
import React, { useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";

const messages = {
    noCameraAccess: "no_browser_camera_access",
};

const Live = ({ title, content, stepHeading, text, buttonText, onCapture, backSlideEffect = "" }) => {
    const { formatMessage } = useFormattedMessage();
    const webcamRef = useRef(null);
    const [camHeight] = useState(window.outerWidth < 768 ? 450 : "auto");
    const [isUserMediaAllowed, setUserMediaAllowed] = useState(true);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot({ width: 550, height: 400 });
        onCapture(imageSrc);
    }, [webcamRef]);

    const videoConstraints = {
        facingMode: "environment",
    };

    return (
        <div className={backSlideEffect}>
            <h3 className="mb-6">{title}</h3>
            <p className="text-16 mb-6">{content}</p>
            <h4 className="mb-6">{stepHeading}</h4>
            <span className="text-14">{text}</span>
            <Webcam
                className="w-100 mw-100 mb-8 border-dashed"
                audio={false}
                height={camHeight}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                videoConstraints={videoConstraints}
                onUserMedia={() => setUserMediaAllowed(true)}
                onUserMediaError={() => setUserMediaAllowed(false)}
            />
            {!isUserMediaAllowed ? <p className="error pl-0 mb-4">{formatMessage(messages.noCameraAccess)}</p> : ""}
            <button
                className={`mw-100 ${isUserMediaAllowed ? "" : "disable-grey"}`}
                onClick={() => (isUserMediaAllowed ? capture() : {})}
            >
                {buttonText}
            </button>
        </div>
    );
};

export default Live;
