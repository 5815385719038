import { useEffect } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";

import Auth from "utils/auth0";
import { RESPONSE_ERRORS, PRIVATE_ROUTE } from "constants/index";
import { fetchUserDetailsFailure, logout } from "store/user/account/actions";
import FINX_COOKIE from "common/constants";

const EmailNotFoundInToken = ({ children, errorMessage, isLoggedIn, logout, fetchUserDetailsFailure }) => {
    const router = useRouter();

    useEffect(() => {
        const isPathPrivate =
            PRIVATE_ROUTE.findIndex((route) => router.pathname.search(route) > -1) > -1 ||
            window.location.href.includes(`${window.location.origin}/token#`);

        if (errorMessage === RESPONSE_ERRORS.EMAIL_NOT_FOUND_IN_TOKEN && isPathPrivate && isLoggedIn) {
            localStorage.setItem(FINX_COOKIE.EMAIL_NOT_FOUND_IN_TOKEN, true);
            Auth.customLogout({ returnTo: `${window.location.origin}/provide-email`, redirectToLogin: false }).then(() => {
                logout();
                router.push("/provide-email");
            });
        }

        return () => {
            fetchUserDetailsFailure(undefined);
        };
    }, [errorMessage]);

    return children;
};

const mapStateToProps = (state) => ({
    errorMessage: state.user.error,
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    fetchUserDetailsFailure: (payload) => dispatch(fetchUserDetailsFailure(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotFoundInToken);
