import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
    selectIsLoggedIn,
    selectEmail,
    selectUserDashboardDetails,
    selectUserDetails,
    selectUserMangopay,
    selectStatusMangopay,
    selectStatusSecondaryMarket,
    selectUserPhone,
} from "store/user/account/selectors";
import { fetchUserDetailsStart } from "store/user/account/actions";
import { MANGOPAY_STATUS, SECONDARY_MARKET_STATUS, KYC_STATUS_LOCAL } from "constants/status";
import { HIDE_ADEQUACY_TILE_STATUS_STEP, PHONE_TYPES } from "constants/index";
import SideModal from "components/SideModal";
import SecondaryMarketStep from "./SecondaryMarketStep";
import { useFormattedMessage } from "hooks";
import { selectCurrenKYCStatus } from "store/user/kyc/selectors";

const messages = {
    heading: "secondary_market_eWallet",
};

const backArrowSteps = ["notRegistered", "pending", "waitingForActivation", "blocked", "failed", "start"];

const SecondaryMarketplaceOnboarding = ({
    open,
    onBackButtonClick,
    onCloseButtonClick,
    userEmail,
    userDashboardDetails,
    openEuroWallet,
    userPhone,
    statusMangopay,
    statusSecondaryMarket,
    isLoggedIn,
    isPropertyDetail = false,
    fetchUserDetailsStart,
    currentKycStatus,
}) => {
    const { formatMessage } = useFormattedMessage();

    const [backSlideEffect, setBackSlideEffect] = useState("");
    const [closeTitle, setCloseTitle] = useState(formatMessage(messages.heading));
    const [isMobileSucceed, setMobileSucceed] = useState(false);

    const { StatusAdequacy } = userDashboardDetails;

    const primaryPhone =
        Array.isArray(userPhone) && userPhone.length > 0 && userPhone.find((x) => x.TypePhone === PHONE_TYPES.TypePhoneMobile);

    const isKycPending = !!(currentKycStatus === KYC_STATUS_LOCAL.INITIATED || currentKycStatus === KYC_STATUS_LOCAL.PENDING);
    const isKycCompleted = currentKycStatus === KYC_STATUS_LOCAL.COMPLETED;
    const isMobileValidated = primaryPhone && primaryPhone.StatusConfirmed === "true";

    const isAdequacyCompleted = !!HIDE_ADEQUACY_TILE_STATUS_STEP.find((status) => status === StatusAdequacy);

    const [step, setStep] = useState("blocked");

    const [isKYCDone, setKYCDone] = useState(false);
    const [isMobileDone, setMobileDone] = useState(false);
    const [isAdequacyDone, setAdequacyDone] = useState(false);

    const [isBackArrowOnTop, setBackArrowOnTop] = useState(false);

    const updateStep = () => {
        if (!isLoggedIn) {
            setStep("notRegistered");
        } else if (statusMangopay && statusMangopay === MANGOPAY_STATUS.PENDING) {
            setStep("pending");
        } else if (statusSecondaryMarket && statusSecondaryMarket === SECONDARY_MARKET_STATUS.PENDING) {
            setStep("waitingForActivation");
        } else if (statusSecondaryMarket && statusSecondaryMarket === SECONDARY_MARKET_STATUS.BLOCKED) {
            setStep("blocked");
        } else if (statusSecondaryMarket && statusSecondaryMarket === SECONDARY_MARKET_STATUS.FAILED) {
            setStep("failed");
        } else if (statusSecondaryMarket === SECONDARY_MARKET_STATUS.INACTIVE) {
            setStep("start");
        } else {
            setStep("blocked");
        }
    };

    useEffect(() => {
        isKycCompleted && setKYCDone(true);
        (isMobileValidated || isMobileSucceed) && setMobileDone(true);
        isAdequacyCompleted && setAdequacyDone(true);
        setTimeout(() => {
            setKYCDone(false);
            setMobileDone(false);
            setAdequacyDone(false);
        }, 300);
    }, [isKycCompleted, isMobileValidated, isAdequacyCompleted, isMobileSucceed]);

    useEffect(() => {
        updateStep();
    }, [statusMangopay, statusSecondaryMarket, open]);

    useEffect(() => {
        statusSecondaryMarket &&
            statusSecondaryMarket === SECONDARY_MARKET_STATUS.INACTIVE &&
            (isKYCDone || isMobileDone || isAdequacyDone) &&
            setStep("start");
        setBackSlideEffect("step-slide-right");
    }, [isKYCDone, isMobileDone, isAdequacyDone]);

    useEffect(() => {
        setBackArrowOnTop(backArrowSteps.includes(step));
    }, [step]);

    const onClose = () => {
        step === "success" && fetchUserDetailsStart();
        onBackButtonClick?.();
        onCloseButtonClick?.();
        reset();
    };

    const reset = () => {
        updateStep();
    };

    const memoizedRenderStep = useMemo(() => {
        return (
            <SecondaryMarketStep
                setCloseTitle={setCloseTitle}
                step={step}
                onCloseButtonClick={onClose}
                isKycPending={isKycPending}
                isKycCompleted={isKycCompleted}
                isMobileValidated={isMobileValidated}
                isAdequacyCompleted={isAdequacyCompleted}
                userEmail={userEmail}
                setStep={setStep}
                isPropertyDetail={isPropertyDetail}
                openEuroWallet={openEuroWallet}
                mobileSuccess={() => setMobileSucceed(true)}
                open={open}
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
            />
        );
    }, [step, isKycPending, isKycCompleted, isMobileValidated, isAdequacyCompleted, userEmail, open]);

    return (
        <SideModal
            className={`secondary-market-side-modal ${step === "userData" ? "kyc-modal" : ""}`}
            wrapClassName={`secondary-market-wrap ${step === "success" ? "px-0" : ""}`}
            containerClassName={step === "success" ? "mw-100 pt-0" : ""}
            heading={closeTitle}
            open={open}
            isBackArrowOnTop={isBackArrowOnTop}
            onBackButtonClick={onBackButtonClick}
            onCloseButtonClick={onCloseButtonClick}
        >
            {memoizedRenderStep}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoggedIn: selectIsLoggedIn,
    userEmail: selectEmail,
    userDashboardDetails: selectUserDashboardDetails,
    userDetails: selectUserDetails,
    userMangopayDetails: selectUserMangopay,
    userPhone: selectUserPhone,
    statusMangopay: selectStatusMangopay,
    statusSecondaryMarket: selectStatusSecondaryMarket,
    currentKycStatus: selectCurrenKYCStatus,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserDetailsStart: () => dispatch(fetchUserDetailsStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryMarketplaceOnboarding);
