import IssuerManagerActionTypes from "./types";

const INITIAL_STATE = {
    selectedIssuer: "",
    issuersList: [],
    isLoading: false,
};

const issuerManagerReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case IssuerManagerActionTypes.FETCH_ISSUERS_LIST_START:
            return {
                ...state,
                isLoading: true,
            };
        case IssuerManagerActionTypes.FETCH_ISSUERS_LIST_SUCCESS:
            return {
                ...state,
                issuersList: payload,
                isLoading: false,
            };
        case IssuerManagerActionTypes.FETCH_ISSUERS_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default issuerManagerReducer;
