import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SideModal from "components/SideModal";
import MobileNumber from "./MobileNumber";
import MobileInfo from "./MobileInfo";
import MobileCode from "./MobileCode";
import MobileSuccess from "./MobileSuccess";
import { sendTwoFactorMail } from "api";
import { MOBILE_STATUS } from "constants/status";
import { useFormattedMessage } from "hooks";
import { setUserMobileData } from "store/user/account/actions";
import { selectUserMobileDetails, selectTypeMobilePhonelDetails } from "store/user/account/selectors";

const messages = {
    heading: "mobile_number",
    headingChange: "mobile_number_change",
};

export const RenderMobileTANStep = ({
    step,
    setStep,
    onBackButtonClick,
    setUserMobileData,
    userMobileData,
    setMobileSuccessFlag,
    isChangeData = false,
    isNumberReadable = false,
    open,
}) => {
    const [backSlideEffect, setBackSlideEffect] = useState("");
    const [isTwoFactorMailLoading, setTwoFactorMailLoading] = useState(false);
    const [isTwoFactorMailSent, setTwoFactorMailSent] = useState(false);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setTwoFactorMailSent(false);
        }, 5000);
    }, [isTwoFactorMailSent]);

    useEffect(() => {
        if (counter > 0) {
            const interval = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [counter]);

    useEffect(() => {
        setCounter(0);
    }, [open]);

    function renderStep(step) {
        switch (step) {
            case 1:
                return (
                    <MobileInfo
                        isTwoFactorMailLoading={isTwoFactorMailLoading}
                        isTwoFactorMailSent={isTwoFactorMailSent}
                        counter={counter}
                        onButtonClick={async () => {
                            try {
                                setTwoFactorMailLoading(true);
                                const res = await sendTwoFactorMail();
                                res && setTwoFactorMailLoading(false);
                                res && setCounter(90);
                                if (res && res.data && !res.data.errors) {
                                    setTwoFactorMailSent(true);
                                }
                            } catch (error) {
                                /* error */
                            }
                        }}
                    />
                );
            case 2:
                return (
                    <MobileNumber
                        backSlideEffect={backSlideEffect}
                        isReadable={isNumberReadable}
                        isChangeData={isChangeData}
                        formSubmitSuccess={({ ...values }) => {
                            setBackSlideEffect("step-slide-left");
                            setUserMobileData && setUserMobileData({ ...userMobileData, ...values, step: 3 });
                        }}
                    />
                );
            case 3:
                return (
                    <MobileCode
                        backSlideEffect={backSlideEffect}
                        changeNumber={() => {
                            setBackSlideEffect("step-slide-right");
                            setUserMobileData({ ...userMobileData, step: 2 });
                        }}
                        formSubmitSuccess={() => {
                            setBackSlideEffect("step-slide-left");
                            setUserMobileData && setUserMobileData({ ...userMobileData, step: 4 });
                        }}
                    />
                );
            case 4:
                return (
                    <MobileSuccess
                        onCloseButtonClick={() => {
                            onBackButtonClick();
                            //Reset to first step once succeed
                            setStep(1);
                        }}
                        backSlideEffect={backSlideEffect}
                        setMobileSuccessFlag={setMobileSuccessFlag}
                    />
                );
            default:
                return <MobileNumber isChangeData={isChangeData} />;
        }
    }
    return renderStep(step);
};

const MobileModal = ({
    open,
    onCloseButtonClick,
    onBackButtonClick,
    setUserMobileData,
    userMobileData,
    setMobileSuccessFlag,
    modalData,
    isChangeData = false,
    isNumberReadable = false,
}) => {
    const { formatMessage } = useFormattedMessage();

    const [step, setStep] = useState(userMobileData.step);
    const mobileStep = userMobileData.step;
    const statusTwoFactor = modalData.StatusTwoFactor;

    const userEmail = modalData.userEmail;
    const mobilePhone = modalData.nonVerifiedMobilePhone || modalData.mobilePhone;

    useEffect(() => {
        //If user is on success screen user data shouldn't change step.
        step != 4 && setStep(mobileStep);
    }, [mobileStep]);

    useEffect(() => {
        if (
            (statusTwoFactor === "" || statusTwoFactor === MOBILE_STATUS.WAITING_FOR_TAN_CONFIRMATION) &&
            modalData?.nonVerifiedMobilePhone?.Number
        ) {
            setUserMobileData({ ...userMobileData, step: 2 });
        }
    }, [userEmail, open, modalData.nonVerifiedMobilePhone]);

    return (
        <SideModal
            className="mobile-tan-modal"
            heading={formatMessage(isChangeData || mobilePhone.Number ? messages.headingChange : messages.heading)}
            open={open}
            isBackArrowOnTop={step === 1 || step === 2}
            onBackButtonClick={onBackButtonClick}
            onCloseButtonClick={() => {
                setUserMobileData({ ...userMobileData, step: 1 });
                onCloseButtonClick();
            }}
        >
            <RenderMobileTANStep
                open={open}
                isNumberReadable={isNumberReadable}
                isChangeData={isChangeData || mobilePhone.Number}
                setStep={(step) => (step === null ? onBackButtonClick() : setStep(step))}
                step={step}
                setUserMobileData={setUserMobileData}
                userMobileData={userMobileData}
                onCloseButtonClick={onCloseButtonClick}
                onBackButtonClick={onBackButtonClick}
                setMobileSuccessFlag={setMobileSuccessFlag}
            />
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    userMobileData: selectUserMobileDetails,
    modalData: selectTypeMobilePhonelDetails,
});

const mapDispatchToProps = (dispatch) => ({
    setUserMobileData: (data) => dispatch(setUserMobileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);
