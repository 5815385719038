import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import {
    setEnteredUrl,
    setPlatformSource,
    setFinxConfig,
    setTenant,
    setHost,
    setMobilePlatform,
    setDocumentHost,
    setLocale,
} from "store/app/actions";
import { storeAuthTokenData, logout } from "store/user/account/actions";
import { setSelectedProjectId } from "store/project/actions";

import { rootReducer } from "store/root-reducer";
import rootSaga from "store/root-saga";

import { getTenantFromHost, loadCurrentHostConfig, getDocumentsHost } from "common/helper";

const FINX_COOKIE = require("common/constants");

export const initializedServerStore = (props, defaultState) => {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares = [sagaMiddleware];
    const enhancer = compose(applyMiddleware(...middlewares));
    const store = createStore(rootReducer, defaultState, enhancer);
    store.sagaTask = sagaMiddleware.run(rootSaga);

    if (props?.req) {
        store.dispatch(logout());

        props.req.platformSource && store.dispatch(setPlatformSource(props.req.platformSource));
        props.req.authSession && store.dispatch(storeAuthTokenData(props.req.authSession));
        props.req.locale && store.dispatch(setLocale(props.req.locale));

        if (props.req.hostname) {
            store.dispatch(setHost(props.req.hostname));
            store.dispatch(setTenant(getTenantFromHost(props.req.hostname)));
            store.dispatch(setDocumentHost(getDocumentsHost({ location: { host: props.req.hostname } })));
            store.dispatch(setFinxConfig(loadCurrentHostConfig({ location: { host: props.req.hostname } })));
        }

        props.req.params?.id && store.dispatch(setSelectedProjectId(props.req.params.id));
        props.req.mobilePlatform && store.dispatch(setMobilePlatform(props.req.mobilePlatform));

        if (props.req.cookies) {
            props.req.cookies[FINX_COOKIE.ENTERED_URL] &&
                store.dispatch(setEnteredUrl(props.req.cookies[FINX_COOKIE.ENTERED_URL]));
        }
    }
    return store;
};
