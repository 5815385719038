import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useSideModalScrollToTop } from "hooks";

import { selectUserDetails } from "store/user/account/selectors";
import { setInvestmentStepData } from "store/user/investment/actions";

const pick = require("lodash/pick");
import { ADDRESS_TYPES, BANK_ACCOUNT_TYPES, PHONE_TYPES, USER_KYC_DETAILS_INPUT } from "constants/index";

import { RenderKycStep } from "components/KycModal";
import { selectCurrenKYCStatus, selectHasUserInitiated, selectKYCSubmitting, selectKycFormData } from "store/user/kyc/selectors";
import { setKYCStart, updateKYCFormData } from "store/user/kyc/actions";
import { KYCTileAPI } from "api";

const UserData = ({
    setStep,
    setInvestmentStepData,
    userAdditionalData,
    setKYCStartAPI,
    onCloseButtonClick,
    open,
    setBackSlideEffect,
    currentKYCStatus,
    isKYCSubmitting,
    hasUserInitiatedKYC,
    updateKycForm,
}) => {
    useSideModalScrollToTop();

    const [step, setKYCStep] = useState(1);
    const [isKYCTileAPILoading, setKYCTileAPILoading] = useState(false);

    const isKycInitiated = hasUserInitiatedKYC;

    useEffect(() => {
        isKycInitiated && !isKYCSubmitting && setKYCStep(3);
    }, [isKycInitiated, isKYCSubmitting]);

    function setUserDetailsAPI(kycData) {
        setKYCTileAPILoading(true);

        let kycDetails = { ...kycData.personal, ...kycData.identification };
        kycDetails = pick(kycDetails, USER_KYC_DETAILS_INPUT);

        const {
            TypeTitle,
            Country,
            BirthCountry,
            Nationality,
            MaritalStatus,
            IsEffectaCustomerInformationConfirmed,
            ...restWithoutTypeTitle
        } = kycDetails;

        const KYCTileAPIPayload = {
            ...restWithoutTypeTitle,
            TypeTitle: TypeTitle?.value,
            TypePhone: PHONE_TYPES.TypePhoneMobile,
            TypeBankAccount: BANK_ACCOUNT_TYPES.TypeBankAccountUserPrimary,
            TypeAddress: ADDRESS_TYPES.TypeAddressPrimary,
            Country: Country?.value,
            BirthCountry: BirthCountry?.value,
            Nationality: Nationality?.value,
            MaritalStatus: MaritalStatus?.value,
            IsEffectaCustomerInformationConfirmed: IsEffectaCustomerInformationConfirmed === true ? "true" : "false",
        };

        KYCTileAPI(KYCTileAPIPayload)
            .then((res) => {
                setKYCTileAPILoading(false);

                if (res?.data?.data?.kycTile?.user?.Email) {
                    setKYCStartAPI({
                        user: kycDetails,
                        currentKYCStatus,
                    });
                }
            })
            .catch(() => {
                setKYCTileAPILoading(false);
            });
    }

    return useMemo(() => {
        return (
            <RenderKycStep
                isWithSecondaryMarket
                onPersonalBack={() => {
                    setStep("start");
                    setBackSlideEffect("step-slide-right");
                }}
                setStep={(step) => (step === null ? onCloseButtonClick() : setKYCStep(step))}
                step={step}
                userAdditionalData={userAdditionalData}
                setInvestmentStepData={setInvestmentStepData}
                setUserDetails={setUserDetailsAPI}
                kycCompleted={isKycInitiated}
                onCloseButtonClick={onCloseButtonClick}
                open={open}
                updateKycForm={updateKycForm}
                isKYCTileAPILoading={isKYCTileAPILoading}
            />
        );
    }, [step, userAdditionalData, isKycInitiated, open]);
};

const mapStateToProps = createStructuredSelector({
    userAdditionalData: selectKycFormData,
    userData: selectUserDetails,
    currentKYCStatus: selectCurrenKYCStatus,
    isKYCSubmitting: selectKYCSubmitting,
    hasUserInitiatedKYC: selectHasUserInitiated,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setKYCStartAPI: (data) => dispatch(setKYCStart(data)),
    updateKycForm: (data) => dispatch(updateKYCFormData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
