import TenantActionTypes from "./types";

export const fetchTenantsStart = (offset, limit, result) => ({
    type: TenantActionTypes.FETCH_TENANTS_START,
    payload: { offset, limit, result },
});

export const fetchTenantsSuccess = (payload) => ({
    type: TenantActionTypes.FETCH_TENANTS_SUCCESS,
    payload: payload,
});

export const fetchTenantsFailure = (error) => ({
    type: TenantActionTypes.FETCH_TENANTS_FAILURE,
    payload: error,
});
