const CLUB_DEAL_STATUS = {
    REGISTRATION_COMPLETED: "clubDealOnboardingRegistrationCompleted",
    PERSONAL_INFORMATION: "clubDealPersonalInformationPending",
    WEALTH_PORTFOLIO: "clubDealWealthPortfolioPending",
    ALTERNATIVE_INVESTMENTS: "clubDealAlternativeInvestmentsPending",
    VERIFICATION_PENDING: "clubDealOnboardingVerificationPending",
    VERIFICATION_SUCCESS: "clubDealOnboardingVerificationSuccess",
    VERIFICATION_FAILURE: "clubDealOnboardingVerificationFailure",
    COMPLETED: "clubDealOnboardingCompleted",
};

const CLUBDEALS_PROJECT_STATUS = {
    ADVERTISING: "Advertising",
    FUNDING_CLOSED: "Funding Closed",
};

const INVESTMENT_STATUS = {
    RESERVED: "reserved",
    INITIALIZED: "initialized",
    CREATED_PENDING_KYC: "createdpendingkyc",
    CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE: "createdpendingliabilitiyumbrellaclearance",
    CREATED_PENDING_ISSUER_CLEARANCE: "createdpendingissuerclearance",
    CREATED_PENDING_PAYMENT: "createdpendingpayment",
    CREATED_PENDING_PROJECT_FINANCING: "createdpendingprojectfinancing",
    CREATED_PENDING_PROJECT_GO_LIVE: "createdpendingprojectgolive",
    ACTIVE: "active",
    CANCELED: "canceled",
    INVESTMENT_SUCCESSFULLY_PAYED_OUT: "payedout",
    SECONDARY_MARKET_BUY: "secondaryMarketBuy",
    SECONDARY_MARKET_SALE: "secondaryMarketSale",
};

const MOBILE_STATUS = {
    HASH_VARIABLE: "mobileNumber",
    WAITING_FOR_EMAIL_CONFIRMATION: "numberChangeInitiatedWaitingForEmailConfirmation",
    WAITING_FOR_TAN_CONFIRMATION: "numberChangeInitiatedWaitingForTANConfirmation",
    SUCCESSFULLY_COMPLETED: "statusMobileCompleted",
};

const NOTIFICATION_TYPE_STATUS = {
    NotificationStatusSend: "notification_status_send",
    NotificationStatusDraft: "notification_status_draft",
    NotificationStatusTestSend: "notification_status_send_test",
    NotificationStatusScheduled: "notification_status_scheduled",
    NotificationStatusProcessing: "notification_status_processing",
    NotificationStatusSent: "notification_status_sent",
    NotificationStatusFailed: "notification_status_failed",
    NotificationStatusAlreadyInProcessing: "notification_already_inprocess",
};

const PAYMENT_STATUS = {
    CREATED: "created",
    FAILED: "failed",
    SUCCEEDED: "succeeded",
    REFUNDED: "refunded",
    WAITING: "waiting",
};

const PROJECT_STATUS = {
    COMING_SOON: "Coming Soon",
    ADVERTISING: "Advertising",
    VIP_FUNDING: "VIP funding",
    FUNDING_ACTIVE: "Funding Active",
    FUNDING_CLOSED: "Funding Closed",
    FINANCING_COMPLETED: "Financing Completed",
    MARKETPLACE: "Marketplace",
    MARKETPLACE_PAUSED: "Marketplace Paused",
    MARKETPLACE_REVIEW: "Marketplace Review",
    SUCCESSFULLY_PAID_BACK: "Successfully Paid Back",
    CLUB_DEAL: "Club Deal",
    CLUB_DEAL_DIRECT_SELECT: "Club Deal - Direct Select",
    HYBRID: "Hybrid",
    SILENT_SALE: "Silent Sale",
};

const PROJECT_STATUS_ALLOWED_DETAIL_PAGE = {
    [PROJECT_STATUS.ADVERTISING]: true,
    [PROJECT_STATUS.VIP_FUNDING]: true,
    [PROJECT_STATUS.FUNDING_ACTIVE]: true,
    [PROJECT_STATUS.FUNDING_CLOSED]: true,
    [PROJECT_STATUS.FINANCING_COMPLETED]: true,
    [PROJECT_STATUS.MARKETPLACE]: true,
    [PROJECT_STATUS.MARKETPLACE_PAUSED]: true,
    [PROJECT_STATUS.SUCCESSFULLY_PAID_BACK]: true,
    [PROJECT_STATUS.CLUB_DEAL]: true,
    [PROJECT_STATUS.CLUB_DEAL_DIRECT_SELECT]: true,
};

const PROJECT_STATUS_ALLOWED_RESERVATION = {
    [PROJECT_STATUS.ADVERTISING]: true,
    [PROJECT_STATUS.VIP_FUNDING]: true,
    [PROJECT_STATUS.FUNDING_ACTIVE]: true,
    [PROJECT_STATUS.CLUB_DEAL]: true,
    [PROJECT_STATUS.COMING_SOON]: true,
};

const TRANSACTION_STATUS = {
    SUCCESS: "success",
    VERIFIED: "verified",
    PENDING: "pending",
    FAILED: "failed",
};

const UMBRELLA_STATUS = {
    APPROVED: "approved",
    PENDING: "pending",
    REJECTED: "rejected",
    RECLEARANCE: "reclearance",
    LIABILITY_UMBRELLA_APPROVED: "liabilityUmbrella.approved",
    LIABILITY_UMBRELLA_PENDING: "liabilityUmbrella.pending",
    LIABILITY_UMBRELLA_REJECTED: "liabilityUmbrella.rejected",
    LIABILITY_UMBRELLA_RECLEARANCE: "liabilityUmbrella.reclearance",
};

const TILE_STATUS = {
    TRUE: true,
    FALSE: false,
    HIDE: "hide",
    INITIATED: "initiated",
    SUCCESS: "success",
    KYC_COMPLETED_IDNOW: "StatusKYCCompletedIDnow",
    KYC_COMPLETED_POSTIDENT: "StatusKYCCompletedPostident",
    SHOW: "show",
    ERROR: "error",
    PENDING: "pending",
    DEACTIVATE: "deactivate",
    ACTIVE: "active",
};

const ORDER_STATUS = {
    ACTIVE: "active",
    DEACTIVATE: "deactivate",
    INITIATED: "initiated",
    CANCELED: "canceled",
    SUCCESS: "success",
    EXPIRED: "expired",
    FAILED: "failed",
    PROGRESS: "progress",
    CONFIRMED: "confirmed",
};

const PROVISION_STATUS = {
    INITIALIZED: "initialized",
    PENDING: "pending",
    CLEARED: "cleared",
    CANCELED: "canceled",
    PAYED: "payed",
};

const MANGOPAY_STATUS = {
    HIDE: "hide",
    INACTIVE: "inactive",
    PENDING: "pending",
    ACTIVE: "active",
    BLOCKED: "blocked",
};

const SECONDARY_MARKET_STATUS = {
    INACTIVE: "inactive",
    BLOCKED: "blocked",
    FAILED: "failed",
    PENDING: "pending",
    ACTIVE: "active",
};

const USER_REGISTRATION_ONBOARDING_STATUS = {
    COMPLETED: "registrationPendingRegistrationSuccess",
    SUCCESS: "registrationSuccess",
};

const PAYMENT_TYPE = {
    USER_INVEST: "userInvest",
    ISSUER_INTEREST: "issuerInterest",
    ISSUER_PAYOUT: "issuerPayOut",
    USER_TRANSFER: "userTransfer",
    USER_PURCHASE: "userPurchase",
    PAYIN: "payIn",
    PAYOUT: "payOut",
    USER_PAYIN: "userPayIn",
    USER_PAYOUT: "userPayOut",
    USER_TRANSFER_EXTERNAL: "userTransferExternal",
    USER_SECONDARY_MARKET_SALE: "userSecondaryMarketSale",
    USER_SECONDARY_MARKET_BUY: "userSecondaryMarketBuy",
    USER_INVEST_EWALLET_TRANSFER: "userInvestEwalletTransfer",
    USER_DIVIDEND_PAYOUT: "userDividendPayOut",
    USER_EXIT_PAYOUT: "userExitPayOut",
    USER_INVEST_WIRE_TRANSFER: "userInvestWireTransfer",
};

const KYC_STATUS_LOCAL = {
    INCOMPLETE: "incomplete",
    INITIATED: "initiated",
    COMPLETED: "completed",
    REKYC: "rekyc",
    REKYC_INITIATED: "rekyc_intiated",
    REKYC_PENDING: "rekyc_pending",
    ERROR: "error",
    PENDING: "pending",
};

const PROVIDER_STATUS = {
    CREATED: "created",
    PENDING: "pending",
    SUCCESS: "success",
    FAILURE: "failure",
    EXPIRED: "expired",
};

module.exports = {
    CLUB_DEAL_STATUS,
    CLUBDEALS_PROJECT_STATUS,
    INVESTMENT_STATUS,
    MOBILE_STATUS,
    NOTIFICATION_TYPE_STATUS,
    PAYMENT_STATUS,
    PROJECT_STATUS,
    PROJECT_STATUS_ALLOWED_DETAIL_PAGE,
    PROJECT_STATUS_ALLOWED_RESERVATION,
    TRANSACTION_STATUS,
    UMBRELLA_STATUS,
    TILE_STATUS,
    ORDER_STATUS,
    PROVISION_STATUS,
    MANGOPAY_STATUS,
    SECONDARY_MARKET_STATUS,
    USER_REGISTRATION_ONBOARDING_STATUS,
    PAYMENT_TYPE,
    KYC_STATUS_LOCAL,
    PROVIDER_STATUS,
};
