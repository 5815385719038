import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { createStructuredSelector } from "reselect";
import { selectEmail, selectPayInWalletBalance } from "store/user/account/selectors";
import { useFormattedMessage } from "hooks";
import { removeOverlay } from "utils";
import { getOrders, deleteUser } from "api";
import { NOT_DELETE_USER_ACCOUNT_ORDER_STATUS } from "constants/index";

import Caution from "assets/images/svg/caution.svg";
import NewLoader from "components/NewLoader";

const messages = {
    deleteButtonText: "delete_confirmation_text",
    cancelButtonText: "form_cancel",
    importantNoteHeading: "important_note",
    deleteNotableReasonHeading: "delete_your_account_balance",
    deleteNotableReasonActiveOrder: "deactivate_all_your_orders",
    deleteNotableReasonAvailableBalance: "go_to_your_ewallet",
    deleteConfirmationText: "delete_your_account",
};

const DeleteAccountConfirmationModal = ({
    email,
    onBackButtonClick,
    userPayInWalletBalance,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    const router = useRouter();
    const { formatMessage } = useFormattedMessage();

    const [activeOrders, setActiveOrders] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const isShowDeleteNote = parseFloat(userPayInWalletBalance) >= 20 || (Array.isArray(activeOrders) && activeOrders.length > 0);

    useEffect(() => {
        if (email) {
            setLoading(true);
            getOrders({ email })
                .then((res) => {
                    const orders = res?.data?.data?.orders?.orders;
                    const activeOrder = orders?.filter((x) => NOT_DELETE_USER_ACCOUNT_ORDER_STATUS[x.StatusOrder]);
                    setActiveOrders(activeOrder);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error, "getOrders api");
                    setLoading(false);
                });
        }
    }, [email]);

    const onDeleteButtonClicked = () => {
        setSubmitting(true);
        deleteUser({ email: email })
            .then((res) => {
                setSubmitting(false);
                if (res?.data?.data?.deleteUser) {
                    router.push(`/user-deleted?type=${res?.data?.data?.deleteUser?.responseMessage}`);
                    removeOverlay();
                }
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className={`flex column py-3 px-10 ${backSlideEffect}`}>
            {isLoading ? (
                <NewLoader type="primary" className="center" />
            ) : (
                <>
                    <div className="text-22 fw-600 align-center">{formatMessage(messages.deleteConfirmationText)}</div>
                    {isShowDeleteNote ? (
                        <div className="mt-8 menu-tab py-4 px-4">
                            <div className="flex column">
                                <div className="flex justify-between">
                                    <div className="text-14 fw-600">{formatMessage(messages.importantNoteHeading)}</div>
                                    <div className=" text-14">
                                        <Caution width="20px" height="20px" />
                                    </div>
                                </div>
                                <div className="text-14 fw-500">
                                    <p>{formatMessage(messages.deleteNotableReasonHeading)}</p>
                                    {Array.isArray(activeOrders) && activeOrders.length > 0 && (
                                        <p>{formatMessage(messages.deleteNotableReasonActiveOrder)}</p>
                                    )}
                                    {parseFloat(userPayInWalletBalance) >= 20 && (
                                        <p>{formatMessage(messages.deleteNotableReasonAvailableBalance)}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-8">
                            <button
                                className="mw-100 danger"
                                onClick={() => {
                                    onDeleteButtonClicked();
                                }}
                                disabled={isShowDeleteNote || isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        <NewLoader /> {formatMessage(messages.deleteButtonText)}
                                    </>
                                ) : (
                                    formatMessage(messages.deleteButtonText)
                                )}
                            </button>
                        </div>
                    )}
                    <div className="mt-1">
                        <button
                            className="mw-100 secondary"
                            onClick={() => {
                                onBackButtonClick();
                                setBackSlideEffect("step-slide-left");
                            }}
                        >
                            {formatMessage(messages.cancelButtonText)}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    email: selectEmail,
    userPayInWalletBalance: selectPayInWalletBalance,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountConfirmationModal);
