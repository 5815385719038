import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";

import BrokerActionTypes from "./broker/types";
import InvestmentActionTypes from "./investment/types";
import PaymentActionTypes from "./payment/types";
import CrmActionTypes from "./crm/types";
import ProjectActionTypes from "./project/types";
import TransactionActionTypes from "./transaction/types";
import MiscellaneousActionTypes from "./miscellaneous/types";
import TestingSuiteActionTypes from "./testing-suite/types";
import OrdersActionTypes from "./orders/types";
import TokenActionTypes from "./token/types";
import PerformanceActionTypes from "./performance/types";
import TenantActionTypes from "./tenant/types";
import SecondaryMarketActionTypes from "./secondaryMarket/types";
import DocumentsActionTypes from "./documents/types";
import IssuersActionTypes from "./issuers/types";
import { PROJECT_DATA, INVESTMENTS, TENANT_DATA, ORDERS, PAYMENTS, USER_DATA, DOCUMENT } from "constants/apiConstants";

import {
    fetchProjects,
    fetchInvestments,
    fetchUsers,
    fetchPayments,
    fetchTransactions,
    getTemplateTestUsers,
    getOrders,
    getToken,
    getPerformances,
    getProjectTokens,
    getTenants,
    getProducts,
    getDocuments,
    getIssuers,
    fetchProductCatalog,
    calls,
    fetchUserByID,
    activities,
    fetchUserInvestments,
    fetchCompleteInvestment,
    deleteMail as deleteMailAPI,
    activity,
    getNotificationsAPI,
    getNotificationAPI,
} from "api";

import {
    fetchNotificationsSuccess,
    fetchNotificationsFailure,
    fetchNotificationSuccess,
    fetchNotificationFailure,
} from "./notification/actions";

import {
    fetchProjectsSuccess,
    fetchProjectsFailure,
    fetchProductsSuccess,
    fetchProductsFailure,
    fetchProductCatalogFailure,
    fetchProductCatalogSuccess,
} from "./project/actions";

import { updateData, setSelectedCallLog, updateInvestmentData, removeEmail, setSelectedAction } from "./crm/actions";

import { setBrokerTotalUsers, fetchBrokersSuccess, fetchBrokersFailure } from "./broker/actions";

import { fetchInvestmentsSuccess, fetchInvestmentsFailure } from "./investment/actions";

import { setTotalPayments, fetchPaymentsSuccess, fetchPaymentsFailure } from "./payment/actions";

import { fetchTransactionsSuccess, fetchTransactionsFailure } from "./transaction/actions";

import { fetchTestUserEmailsSuccess, fetchTestUserEmailsFailure } from "./miscellaneous/actions";

import { fetchTestingSuiteSuccess, fetchTestingSuiteFailure } from "./testing-suite/actions";

import { fetchOrdersSuccess, fetchOrdersFailure } from "./orders/actions";

import { fetchTokenSuccess, fetchTokenFailure, fetchProjectTokenSuccess, fetchProjectTokenFailure } from "./token/actions";

import { fetchPerformancesSuccess, fetchPerformancesFailure } from "./performance/actions";

import { fetchTenantsSuccess, fetchTenantsFailure } from "./tenant/actions";

import { fetchSecondaryMarketSuccess, fetchSecondaryMarketFailure } from "./secondaryMarket/actions";

import { fetchDocumentsSuccess, fetchDocumentsFailure } from "./documents/actions";

import { fetchIssuersSuccess, fetchIssuersFailure } from "./issuers/actions";
import { Activity_API_Limit, ACTIVITIES_TYPE, iconClassMapping } from "constants";
import { UserEmailActivityData, UserSmsActivityData, userDetailsCallsData } from "constants/apiResults";
import { selectIsUserAdmin } from "store/user/account/selectors";
import NotificationActionTypes from "./notification/types";
import UserActionTypes from "./user/types";
import { fetchUsersSuccess, fetchUsersFailure, setTotalUsers } from "./user/actions";

function* fetchProjectsAsync({ payload: { result } }) {
    try {
        const response = yield call(fetchProjects, result || PROJECT_DATA);
        const { projects } = response.data.data.projects;
        yield put(fetchProjectsSuccess(projects));
    } catch (err) {
        yield put(fetchProjectsFailure(err));
    }
}

function* fetchProductsAsync() {
    try {
        const res = yield call(getProducts);
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const { products } = res.data.data.products;
        yield put(fetchProductsSuccess(products));
    } catch (err) {
        yield put(fetchProductsFailure(err));
    }
}
function* fetchProductCatalogAsync() {
    try {
        const response = yield call(fetchProductCatalog);
        const { productCatalog } = response?.data?.data?.productCatalog;
        yield put(fetchProductCatalogSuccess(productCatalog));
    } catch (err) {
        yield put(fetchProductCatalogFailure(err));
    }
}
function* fetchUsersAsync({ payload: { offset, limit } }) {
    try {
        const response = yield call(fetchUsers, { offset, limit });
        const { users, totalUsers } = response.data.data.users;
        yield put(fetchUsersSuccess(users));
        yield put(setTotalUsers(totalUsers));
    } catch (err) {
        yield put(fetchUsersFailure(err));
    }
}

function* fetchBrokersAsync({ payload: { offset, limit } }) {
    try {
        const response = yield call(fetchUsers, { offset, limit, role: "broker" });
        const { users, totalUsers } = response.data.data.users;
        yield put(fetchBrokersSuccess(users));
        yield put(setBrokerTotalUsers(totalUsers));
    } catch (err) {
        yield put(fetchBrokersFailure(err));
    }
}

function* fetchInvestmentsAsync({ payload: { offset, limit } }) {
    try {
        const response = yield call(fetchInvestments, { offset, limit }, INVESTMENTS);
        const { investments, totalInvestments } = response.data.data.investments;
        yield put(fetchInvestmentsSuccess(investments, totalInvestments));
    } catch (err) {
        yield put(fetchInvestmentsFailure(err));
    }
}

function* fetchPaymentsAsync({ payload }) {
    try {
        const response = yield call(fetchPayments, { reqData: payload.reqData, result: payload.result || PAYMENTS });
        const { payments, totalPayments } = response.data.data.payments;
        yield put(fetchPaymentsSuccess([...payload.oldPayments, ...payments]));
        yield put(setTotalPayments(totalPayments));
    } catch (err) {
        yield put(fetchPaymentsFailure(err));
    }
}

function* fetchTransactionsAsync() {
    try {
        const res = yield call(fetchTransactions);

        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);

        yield put(fetchTransactionsSuccess(res.data.data.transactions.transactions || []));
    } catch (err) {
        yield put(fetchTransactionsFailure(err));
    }
}

function* fetchTestUserEmailsAsync() {
    try {
        const res = yield call(getTemplateTestUsers);

        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);

        let data = res.data.data.getTemplateTestUsers.templatetestusers;

        data = Array.isArray(data) ? data.map((user) => user.Email) : [];

        yield put(fetchTestUserEmailsSuccess(data));
    } catch (err) {
        yield put(fetchTestUserEmailsFailure(err));
    }
}

function* fetchTestUserAsync({ payload: { offset, limit } }) {
    try {
        const res = yield call(getTemplateTestUsers, { offset, limit });
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const data = res.data.data.getTemplateTestUsers.templatetestusers;
        const totalUsers = res.data.data.getTemplateTestUsers.totalUsers;
        yield put(fetchTestingSuiteSuccess(data));
        yield put(setTotalUsers(totalUsers));
    } catch (err) {
        yield put(fetchTestingSuiteFailure(err));
    }
}

function* fetchOrdersAsync({ payload: { offset, limit, result } }) {
    try {
        const res = yield call(getOrders, { offset, limit }, result || ORDERS);
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const data = res.data.data.orders.orders;
        const totalOrders = res.data.data.orders.totalOrders;
        yield put(fetchOrdersSuccess({ ordersData: data, totalOrders: totalOrders }));
    } catch (err) {
        yield put(fetchOrdersFailure(err));
    }
}

function* fetchTokenAsync({ payload: { offset, limit } }) {
    try {
        const res = yield call(getToken, { offset, limit });
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const tokens = res.data.data.tokens.tokens;
        const totalTokens = res.data.data.tokens.totalTokens;
        yield put(fetchTokenSuccess({ tokens, totalTokens }));
    } catch (err) {
        yield put(fetchTokenFailure(err));
    }
}

function* fetchPerformancesAsync({ payload: { offset, limit } }) {
    try {
        const res = yield call(getPerformances, { offset, limit });
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const performances = res.data.data.performances.performances;
        const totalPerformances = res.data.data.performances.totalPerformances;
        yield put(fetchPerformancesSuccess({ performances, totalPerformances }));
    } catch (err) {
        yield put(fetchPerformancesFailure(err));
    }
}

function* fetchProjectTokenAsync({ payload }) {
    try {
        const res = yield call(getProjectTokens, payload);
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const projectTokens = res.data.data.projectTokens.projectTokens;
        yield put(fetchProjectTokenSuccess({ projectTokens }));
    } catch (err) {
        yield put(fetchProjectTokenFailure(err));
    }
}

function* fetchTenantsAsync({ payload: { offset, limit, result } }) {
    try {
        const res = yield call(getTenants, { offset, limit }, result || TENANT_DATA);
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const tenants = res.data.data.tenants.tenants;
        const totalTenants = res.data.data.tenants.totalTenants;
        yield put(fetchTenantsSuccess({ tenants, totalTenants }));
    } catch (err) {
        yield put(fetchTenantsFailure(err));
    }
}

function* fetchUsersSecondaryMarketAsync({ payload: { offset, limit, role, result } }) {
    try {
        const response = yield call(fetchUsers, { offset, limit, role }, result || USER_DATA);
        const { users } = response.data.data.users;
        yield put(fetchSecondaryMarketSuccess(users));
    } catch (err) {
        yield put(fetchSecondaryMarketFailure(err));
    }
}

function* fetchDocumentsAsync({ payload: { offset, limit, TypeDocument, Path, result } }) {
    try {
        const response = yield call(getDocuments, { offset, limit, TypeDocument, Path }, result || DOCUMENT);
        const { documents, totalDocuments } = response.data.data.getDocuments;
        yield put(fetchDocumentsSuccess(documents, totalDocuments));
    } catch (err) {
        yield put(fetchDocumentsFailure(err));
    }
}

function* fetchIssuersAsync({ payload: { offset, limit, managersIssuers } }) {
    try {
        const response = yield call(getIssuers, { offset, limit, managersIssuers });
        const issuers = response.data.data.issuers.issuers;
        const totalIssuers = response.data.data.issuers.totalIssuers;
        yield put(fetchIssuersSuccess({ issuers, totalIssuers }));
    } catch (err) {
        yield put(fetchIssuersFailure(err));
    }
}

//CRM merged from here
function delay(ms) {
    return new Promise((resolve) => setTimeout(() => resolve(true), ms));
}

function* deleteMailSaga({ payload }) {
    try {
        yield put(
            updateData({
                deleteActivityLoader: true,
            }),
        );
        yield call(deleteMailAPI, payload);

        yield put(
            updateData({
                deleteActivityLoader: false,
                deleteActivityStatus: "success",
            }),
        );

        yield call(delay, 3000);
        yield put(removeEmail(payload));
        yield put(
            updateData({
                deleteActivityStatus: "",
            }),
        );
    } catch (error) {
        yield put(
            updateData({
                deleteActivityLoader: false,
                deleteActivityStatus: "error",
            }),
        );
        yield call(delay, 3000);
        yield put(
            updateData({
                deleteActivityStatus: "",
            }),
        );
    }
}
function* fetchCallLogsForAUser({ uid, offset = 0, limit = Activity_API_Limit }) {
    try {
        const callsReponse = yield call(calls, { UserID: uid, offset, limit }, userDetailsCallsData);

        yield put(
            updateData({
                userCallLogs: callsReponse.data.data.calls.calls,
                userCallLogsLoader: false,
                userCallLogsCount: callsReponse.data.data.calls.count,
            }),
        );
    } catch (err) {
        yield put(
            updateData({
                userCallLogs: [],
                userCallLogsLoader: false,
                userCallLogsCount: 0,
            }),
        );
    }
}

function* fetchUserCallLogsSaga({ payload }) {
    try {
        yield put(
            updateData({
                userLoader: true,
            }),
        );

        yield call(fetchCallLogsForAUser, payload);
    } catch (err) {
        yield put(
            updateData({
                userLoader: false,
            }),
        );
    }
}

function* fetchSelectedCallLogSaga({ payload }) {
    try {
        yield put(setSelectedAction(iconClassMapping.callLogs));

        yield put(
            setSelectedCallLog({
                isLoader: true,
                isShow: true,
            }),
        );

        const activityResponse = yield call(activity, { CallID: payload });

        if (
            !activityResponse ||
            activityResponse.errors ||
            !activityResponse.data ||
            activityResponse.data.errors ||
            activityResponse.data.data.errors
        ) {
            yield put(
                setSelectedCallLog({
                    isLoader: false,
                    fetchingError: true,
                }),
            );
            return;
        }

        yield put(
            setSelectedCallLog({
                isLoader: false,
                fetchingError: false,
                callLog: activityResponse.data.data.activity.activity,
            }),
        );
    } catch (err) {
        yield put(
            setSelectedCallLog({
                isLoader: false,
                fetchingError: true,
            }),
        );
    }
}

function* fetchAllInfoForAnInvestmentSaga({ payload }) {
    try {
        yield put(
            updateInvestmentData({
                investmentLoader: true,
            }),
        );

        const investmentResponse = yield call(fetchCompleteInvestment, payload);

        if (
            !investmentResponse ||
            investmentResponse.errors ||
            !investmentResponse.data ||
            investmentResponse.data.errors ||
            investmentResponse.data.data.errors
        ) {
            yield put(
                updateInvestmentData({
                    investmentLoader: false,
                }),
            );
            return;
        }

        const investment = investmentResponse.data.data.investment.investment;

        yield put(
            updateInvestmentData({
                investmentLoader: false,
                investment,
            }),
        );
    } catch (error) {
        yield put(
            updateInvestmentData({
                investmentLoader: false,
            }),
        );
    }
}

function* fetchInvestmentsForAUser(email) {
    try {
        const investmentResponse = yield call(fetchUserInvestments, email);
        yield put(
            updateData({
                userInvestments: investmentResponse.data.data.user.user.LinkInvestments,
            }),
        );
    } catch (err) {
        yield put(
            updateData({
                userInvestments: [],
            }),
        );
    }
}

function* fetchActivitiesForAUser({ uid, offset = 0, limit = Activity_API_Limit }) {
    try {
        const activitiesResponse = yield call(activities, { UserID: uid, TypeActivity: ACTIVITIES_TYPE.Call, offset, limit });

        const activitiesData = activitiesResponse.data.data.activities.activities;

        yield put(
            updateData({
                userActivitiesLoader: false,
                userActivities: activitiesData,
                userActivitiesCount: activitiesResponse.data.data.activities.count,
            }),
        );
    } catch (err) {
        yield put(
            updateData({
                userActivitiesLoader: false,
                userActivities: [],
                userActivitiesCount: 0,
            }),
        );
    }
}

function* fetchMessagesForAUser({ uid, offset = 0, limit = Activity_API_Limit }) {
    try {
        const messagesResponse = yield call(
            activities,
            { UserID: uid, TypeActivity: ACTIVITIES_TYPE.Sms, offset, limit },
            UserSmsActivityData,
        );
        yield put(
            updateData({
                userMessagesLoader: false,
                userMessages: messagesResponse.data.data.activities.activities,
                userMessagesCount: messagesResponse.data.data.activities.count,
            }),
        );
    } catch (err) {
        yield put(
            updateData({
                userMessages: [],
                userMessagesLoader: false,
                userMessagesCount: 0,
            }),
        );
    }
}

function* fetchEmailsForAUser({ uid, offset = 0, limit = Activity_API_Limit }) {
    try {
        const emailsResponse = yield call(
            activities,
            { UserID: uid, TypeActivity: ACTIVITIES_TYPE.Email, offset, limit },
            UserEmailActivityData,
        );
        yield put(
            updateData({
                userEmailsLoader: false,
                userEmails: emailsResponse.data.data.activities.activities,
                userEmailsCount: emailsResponse.data.data.activities.count,
            }),
        );
    } catch (err) {
        yield put(
            updateData({
                userEmailsLoader: false,
                userEmails: [],
                userEmailsCount: 0,
            }),
        );
    }
}

function* fetchAllInfoForAUserSaga({ payload }) {
    try {
        const isAdmin = yield select(selectIsUserAdmin);

        yield put(
            updateData({
                userLoader: true,
                userActivitiesLoader: true,
                userMessagesLoader: true,
                userCallLogsLoader: true,
                userEmailsLoader: true,
            }),
        );
        const userResponse = yield call(fetchUserByID, typeof payload === "string" ? payload : payload.uid, payload.response);

        if (
            !userResponse ||
            userResponse.errors ||
            !userResponse.data ||
            userResponse.data.errors ||
            userResponse.data.data.errors
        ) {
            yield put(
                updateData({
                    userLoader: false,
                    userActivitiesLoader: false,
                    userMessagesLoader: false,
                    userCallLogsLoader: false,
                    userEmailsLoader: false,
                    user: {},
                }),
            );
            return;
        }

        const user = userResponse.data.data.user.user,
            email = user && user.Email,
            uid = user && user.uid;
        yield put(
            updateData({
                userLoader: false,
                user,
            }),
        );

        yield fork(fetchInvestmentsForAUser, email);
        yield fork(fetchCallLogsForAUser, { uid });

        if (isAdmin) {
            yield fork(fetchActivitiesForAUser, { uid });
            yield fork(fetchMessagesForAUser, { uid });
            yield fork(fetchEmailsForAUser, { uid });
        }
    } catch (error) {
        yield put(
            updateData({
                userLoader: false,
                userActivitiesLoader: false,
                userMessagesLoader: false,
                userCallLogsLoader: false,
                userEmailsLoader: false,
            }),
        );
    }
}

//NOTIFICATION
function* fetchNotificationsAsync({ payload: { offset, limit, search, result } }) {
    try {
        const response = yield call(getNotificationsAPI, offset, limit, search, result);
        const { notifications } = response.data.data.getNotifications;
        yield put(fetchNotificationsSuccess({ notifications }));
    } catch (err) {
        yield put(fetchNotificationsFailure(err));
    }
}

function* fetchNotificationAsync({ payload: { ID } }) {
    try {
        const response = yield call(getNotificationAPI, ID);
        const { notification } = response.data.data.getNotification;
        yield put(fetchNotificationSuccess({ notification }));
    } catch (err) {
        yield put(fetchNotificationFailure(err));
    }
}

function* fetchAdminStart() {
    yield takeLatest(ProjectActionTypes.FETCH_PROJECTS_START, fetchProjectsAsync);
    yield takeLatest(ProjectActionTypes.FETCH_PRODUCTS_START, fetchProductsAsync);
    yield takeLatest(ProjectActionTypes.FETCH_PRODUCT_CATALOG_START, fetchProductCatalogAsync);
    yield takeLatest(UserActionTypes.FETCH_ADMIN_USERS_START, fetchUsersAsync);
    yield takeLatest(BrokerActionTypes.FETCH_BROKERS_START, fetchBrokersAsync);
    yield takeLatest(InvestmentActionTypes.FETCH_INVESTMENTS_START, fetchInvestmentsAsync);
    yield takeLatest(PaymentActionTypes.FETCH_PAYMENTS_START, fetchPaymentsAsync);
    yield takeLatest(TransactionActionTypes.FETCH_TRANSACTION_START, fetchTransactionsAsync);
    yield takeLatest(MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_START, fetchTestUserEmailsAsync);
    yield takeLatest(TestingSuiteActionTypes.FETCH_TEST_USER_START, fetchTestUserAsync);
    yield takeLatest(OrdersActionTypes.FETCH_ORDERS_START, fetchOrdersAsync);
    yield takeLatest(TokenActionTypes.FETCH_TOKEN_START, fetchTokenAsync);
    yield takeLatest(PerformanceActionTypes.FETCH_PERFORMANCES_START, fetchPerformancesAsync);
    yield takeLatest(TokenActionTypes.FETCH_PROJECT_TOKEN_START, fetchProjectTokenAsync);
    yield takeLatest(TenantActionTypes.FETCH_TENANTS_START, fetchTenantsAsync);
    yield takeLatest(SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_START, fetchUsersSecondaryMarketAsync);
    yield takeLatest(DocumentsActionTypes.FETCH_DOCUMENTS_START, fetchDocumentsAsync);
    yield takeLatest(IssuersActionTypes.FETCH_ISSUERS_START, fetchIssuersAsync);

    //CRM merged from here
    yield takeLatest(CrmActionTypes.FETCH_USER_CALL_LOGS, fetchUserCallLogsSaga);
    yield takeLatest(CrmActionTypes.FETCH_ALL_INFO_FOR_A_USER, fetchAllInfoForAUserSaga);
    yield takeLatest(CrmActionTypes.FETCH_ALL_INFO_FOR_AN_INVESTMENT, fetchAllInfoForAnInvestmentSaga);
    yield takeLatest(CrmActionTypes.FETCH_SELECTED_CALL_LOG, fetchSelectedCallLogSaga);
    yield takeLatest(CrmActionTypes.FETCH_USER_CALL_LOGS, fetchUserCallLogsSaga);
    yield takeLatest(CrmActionTypes.DELETE_ACTIVITY, deleteMailSaga);

    //NOTIFICATION
    yield takeLatest(NotificationActionTypes.FETCH_NOTIFICATION_START, fetchNotificationAsync);
    yield takeLatest(NotificationActionTypes.FETCH_NOTIFICATIONS_START, fetchNotificationsAsync);
}

export function* adminSagas() {
    yield all([call(fetchAdminStart)]);
}
