import React, { useState, useEffect } from "react";
import { useFormattedMessage, useSideModalScrollToTop, useUtils } from "hooks";
import Card from "assets/images/svg/card.svg";
import TopGreyDiv from "../components/topGreyDiv";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import Info from "./components/info";
import Live from "./components/live";
import NoCameraAccess from "components/NoCameraAccess";

const messages = {
    rifCloseTitle: "wallet_payout_title",
    rifFrontInfoTitle: "activation_for_payouts",
    rifFrontInfoContent: "using_your_webcame",
    rifFrontInfoStepHeading: "front_of_your_id_card",
    rifFrontInfoStepLabelText: "example_recording",
    rifFrontInfoButtonText: "activate_camera",
    rifFrontLiveTitle: "activation_for_payouts",
    rifFrontLiveContent: "capture_the_front_of_your_id",
    rifFrontLiveStepLabelText: "live_video",
    rifFrontLiveButtonText: "take_photo",
    rifFrontCapturedTitle: "activation_for_payouts",
    rifFrontCapturedContent: "capture_the_front_of_your_id",
    rifFrontCapturedStepLabelText: "your_photo",
    rifFrontCapturedBottomContent: "photo_legible_and_data_recognizable",
    rifFrontCapturedButtonText: "retake_photo",
};

const Front = ({
    setCloseTitle,
    setRetakeStep,
    forceStep,
    setStepAndData,
    stepData,
    backSlideEffect = "",
    setBackSlideEffect,
    isCameraPermissionEnabled,
}) => {
    useSideModalScrollToTop();
    const { formatMessage } = useFormattedMessage();
    const [frontStep, setFrontStep] = useState(forceStep || "info");
    const isApp = useUtils().isPlatformSourceFromApp;

    useEffect(() => {
        setCloseTitle(formatMessage(messages.rifCloseTitle));
    }, []);

    useEffect(() => {
        frontStep === "noCameraAccess"
            ? jQuery("body").addClass("overflow-hidden")
            : jQuery("body").removeClass("overflow-hidden");
    }, [frontStep]);

    const renderFrontStep = {
        info: (
            <Info
                backSlideEffect={backSlideEffect}
                title={formatMessage(messages.rifFrontInfoTitle)}
                content={formatMessage(messages.rifFrontInfoContent)}
                stepHeading={formatMessage(messages.rifFrontInfoStepHeading)}
                text={formatMessage(messages.rifFrontInfoStepLabelText)}
                imageClass="border-dashed"
                image={"/images/id-front-preview.jpg"}
                buttonText={formatMessage(messages.rifFrontInfoButtonText)}
                onImageClick={() => {
                    if (isApp && !isCameraPermissionEnabled) {
                        setFrontStep("noCameraAccess");
                    } else {
                        setFrontStep("live");
                        setBackSlideEffect("step-slide-left");
                    }
                }}
                // eslint-disable-next-line sonarjs/no-identical-functions
                onClick={() => {
                    if (isApp && !isCameraPermissionEnabled) {
                        setFrontStep("noCameraAccess");
                    } else {
                        setFrontStep("live");
                        setBackSlideEffect("step-slide-left");
                    }
                }}
            />
        ),
        live: (
            <Live
                backSlideEffect={backSlideEffect}
                title={formatMessage(messages.rifFrontLiveTitle)}
                content={formatMessage(messages.rifFrontLiveContent)}
                stepHeading={formatMessage(messages.rifFrontInfoStepHeading)}
                labelText={formatMessage(messages.rifFrontLiveStepLabelText)}
                buttonText={formatMessage(messages.rifFrontLiveButtonText)}
                onCapture={(imgSrc) => {
                    setStepAndData("retakeIF", { ...stepData, frontImg: imgSrc });
                    setFrontStep("captured");
                    setBackSlideEffect("step-slide-left");
                }}
            />
        ),
        captured: (
            <Info
                backSlideEffect={backSlideEffect}
                title={formatMessage(messages.rifFrontCapturedTitle)}
                content={formatMessage(messages.rifFrontCapturedContent)}
                stepHeading={formatMessage(messages.rifFrontInfoStepHeading)}
                text={formatMessage(messages.rifFrontCapturedStepLabelText)}
                imageClass="mx-auto d-block captured-img"
                image={stepData?.frontImg}
                bottomContent={formatMessage(messages.rifFrontCapturedBottomContent)}
                isButtonSecondary
                buttonText={formatMessage(messages.rifFrontCapturedButtonText)}
                onClick={() => {
                    setFrontStep("live");
                    setBackSlideEffect("step-slide-right");
                }}
            />
        ),
        noCameraAccess: <NoCameraAccess onClose={() => setFrontStep("info")} />,
    };

    const stepRender = (step) => {
        return renderFrontStep[step] || renderFrontStep["info"];
    };

    const onBack = () => {
        setBackSlideEffect("step-slide-right");

        if (frontStep === "info") {
            setRetakeStep("start");
        } else if (frontStep === "live") {
            setFrontStep("info");
        } else if (frontStep === "captured") {
            setFrontStep("live");
        }
    };

    return (
        <div className="retakeif-front">
            {frontStep !== "noCameraAccess" ? <TopGreyDiv icon svgIcon={<Card style={{ maxWidth: "100px" }} />} /> : null}
            <div
                className={`px-5 py-4 mw-450 mx-auto ${
                    frontStep === "noCameraAccess" ? "no-camera-access d-flex align-center" : ""
                }`}
            >
                {stepRender(frontStep)}
            </div>
            {frontStep !== "noCameraAccess" ? (
                <div className="bottom-bar-container">
                    <PrevButton onClick={onBack} />
                    {frontStep === "captured" && (
                        <NextButton
                            onClick={() => {
                                setRetakeStep("back");
                                setBackSlideEffect("step-slide-left");
                            }}
                        />
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default Front;
