import IssuerManagerActionTypes from "./types";

export const fetchManagerIssuersListStart = () => ({
    type: IssuerManagerActionTypes.FETCH_ISSUERS_LIST_START,
});

export const fetchManagerIssuersListSuccess = (data) => ({
    type: IssuerManagerActionTypes.FETCH_ISSUERS_LIST_SUCCESS,
    payload: data,
});

export const fetchManagerIssuersListFailure = () => ({
    type: IssuerManagerActionTypes.FETCH_ISSUERS_LIST_FAILURE,
});
