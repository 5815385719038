import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop, useUtils } from "hooks";
import {
    selectUserId,
    selectMangoPayKycLevel,
    selectIsBlockedInFlowsMangoPay,
    selectIsBlockedOutFlowsMangoPay,
    selectUserPaymentsFilter,
    selectMangopayBlocked,
} from "store/user/account/selectors";
import { fetchUserPaymentsStart } from "store/user/account/actions";
import CustomFormatNumber from "components/CustomFormatNumber";
import TopGreyDiv from "./components/topGreyDiv";
import AllActivitiesBlock from "./components/allActivitiesBlock";
import ArrowRight from "assets/images/svg/arrow-right.svg";
import BusinessAndFinance from "assets/images/svg/business-and-finance.svg";
import MoneyCurrency from "assets/images/svg/money-currency.svg";
import Donation from "assets/images/svg/donation.svg";
import { MANGOPAY_KYC_LEVEL } from "constants/index";

const messages = {
    availableAmount: "available_balance",
    availableOptions: "wallet_have_following_options",
    reservedBalance: "reserved_balance",
    payIn: "payment_type_pay_in",
    payOut: "payment_type_pay_out",
    transfer: "wallet_transfer",
    yourBankAccount: "your_bank_account",
    previousActivities: "previous_activities",
    allActivities: "show_all",
    noActivities: "no_activities_available_yet",
    noActivitiesPayInText: "payin_credit_now",
    payOutKycMessage: "your_approval_for_payments",
    blockedPayInErrorMessage: "temporarily_blocked_your_deposit",
    blockedPayOutErrorMessage: "temporarily_blocked_your_withdrawals",
    blockedTransferErrorMessage: "payment_service_temporary_blocked",
    noWalletBalance: "no_wallet_balance",
    payInBlocked: "deposit_blocked_for_security_reason",
    payOutBlocked: "withdrawal_blocked_for_security_reason",
};

export const BankActivity = ({
    date,
    iconBackgroundColor,
    onClick,
    iconComponent,
    name,
    isGreen,
    amount,
    status,
    type,
    typeColor = "#74757d",
}) => {
    return (
        <div className={`card-container mt-4 flex ${typeof onClick === "function" ? "cursor-pointer" : ""}`} onClick={onClick}>
            <div className="round-icon-container">
                <div className="round-holder" style={{ backgroundColor: iconBackgroundColor }}>
                    {iconComponent()}
                </div>
            </div>
            <div className="w-100 py-4">
                <span className="flex justify-between align-center">
                    <span className="d-inline-block text-16 fw-600">{name}</span>
                    <span className={`d-inline-block text-16 fw-600 mr-4 ${isGreen ? "green-color" : "alert-red-color"}`}>
                        {isGreen ? "+ " : "- "}
                        <CustomFormatNumber value={amount} />
                    </span>
                </span>
                <span className="flex justify-between align-center mt-1">
                    <span className="flex align-center">
                        <span className="text-12 secondary-light-color fw-500">{date}</span>
                        <span className="secondary-light-color mx-2">-</span>
                        <span className="d-inline-block text-12 fw-500 secondary-light-color">{status}</span>
                    </span>
                    <span className="text-12 fw-500 mr-4" style={{ color: typeColor }}>
                        {type}
                    </span>
                </span>
            </div>
        </div>
    );
};

const WalletOverview = ({
    setShowDate,
    setCloseTitle,
    setStepAndData,
    setStep,
    userId,
    payments = [],
    data,
    fetchUserPaymentsStart,
    mangoPayKycLevel,
    isBlockedInFlowsMangoPay,
    isBlockedOutFlowsMangoPay,
    isBlocked,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const { isProdEnv } = useUtils();

    const [actionErrorType, setActionErrorType] = useState("");
    const [actionErrorMessage, setActionErrorMessage] = useState("");

    useEffect(() => {
        userId && fetchUserPaymentsStart(userId);
    }, [userId]);

    useEffect(() => {
        setShowDate(true);
        setCloseTitle("E-Wallet");
    }, []);

    const onPayInClick = () => {
        if (isBlocked) {
            setActionErrorType("payIn");
            setActionErrorMessage("payInBlocked");
        } else if (isBlockedInFlowsMangoPay) {
            setActionErrorType("payIn");
            setActionErrorMessage("blockedPayInErrorMessage");
        } else {
            setActionErrorType("");
            setActionErrorMessage("");
            setStep("payInInfo");
            setBackSlideEffect("step-slide-left");
        }
    };

    const onPayOutClick = () => {
        if (isBlocked) {
            setActionErrorType("payOut");
            setActionErrorMessage("payOutBlocked");
        } else if (mangoPayKycLevel === MANGOPAY_KYC_LEVEL.LIGHT) {
            setActionErrorType("payOut");
            setActionErrorMessage("payOutKycMessage");
        } else if (mangoPayKycLevel === MANGOPAY_KYC_LEVEL.RETAKE) {
            setActionErrorType("");
            setActionErrorMessage("");
            setStep("retakeIF");
            setBackSlideEffect("step-slide-left");
        } else if (isBlockedOutFlowsMangoPay) {
            setActionErrorType("payOut");
            setActionErrorMessage("blockedPayOutErrorMessage");
        } else if (parseFloat(data.payInWalletBalance) === 0) {
            setActionErrorType("payOut");
            setActionErrorMessage("noWalletBalance");
        } else if (mangoPayKycLevel === MANGOPAY_KYC_LEVEL.REGULAR) {
            setActionErrorType("");
            setActionErrorMessage("");
            setStep("payOut");
            setBackSlideEffect("step-slide-left");
        }
    };

    const onTransferMoneyClick = () => {
        if (isBlockedOutFlowsMangoPay) {
            setActionErrorType("transfer");
            setActionErrorMessage("blockedTransferErrorMessage");
        } else if (parseFloat(data.payInWalletBalance) === 0) {
            setActionErrorType("transfer");
            setActionErrorMessage("noWalletBalance");
        } else {
            setActionErrorType("");
            setActionErrorMessage("");
            setStep("transferEMoney");
            setBackSlideEffect("step-slide-left");
        }
    };

    const onBankActivityClick = (activity) => {
        setStepAndData("activityDetails", { activityID: activity.uid, back: "walletOverview" });
        setBackSlideEffect("step-slide-left");
    };

    const onAllActivitiesClicked = () => {
        setStep("allActivities");
        setBackSlideEffect("step-slide-left");
    };

    const onReservedAmountClicked = () => {
        setStep("reserveOrders");
        setBackSlideEffect("step-slide-left");
    };

    return (
        <div className={`wallet-overview pb-15 ${backSlideEffect}`}>
            <TopGreyDiv amount={data.payInWalletBalance} text={formatMessage(messages.availableAmount)} />
            <div className="px-5 mw-450 mx-auto">
                <div
                    onClick={onReservedAmountClicked}
                    className="bg-lighter-grey flex justify-between align-center reserver-balance-container"
                >
                    <div className="flex-column py-2">
                        <span className="text-12 fw-500 ml-6 mb-1 secondary-light-color">
                            {formatMessage(messages.reservedBalance)}
                        </span>
                        <span className="text-16 fw-600 ml-6">
                            <CustomFormatNumber value={data.blockedWalletBalance} />
                        </span>
                    </div>
                    <div>
                        <ArrowRight className="arrow" />
                    </div>
                </div>
                <p className="text-16 secondary-light-color mt-6 pt-4">{formatMessage(messages.availableOptions)}</p>
                <div className="flex mt-6">
                    <div
                        className={`button-icon-container tile-hover-effect ${
                            actionErrorType === "payIn" ? "danger-border" : ""
                        } `}
                        onClick={onPayInClick}
                    >
                        <Donation width="30" height="30" />
                        <span className="text-14 mt-3">{formatMessage(messages.payIn)}</span>
                    </div>
                    <div
                        className={`button-icon-container tile-hover-effect ${
                            actionErrorType === "payOut" ? "danger-border" : ""
                        } `}
                        onClick={onPayOutClick}
                    >
                        <MoneyCurrency width="30" height="30" />
                        <span className="text-14 mt-3">{formatMessage(messages.payOut)}</span>
                    </div>
                    {!isProdEnv ? (
                        <div
                            className={`button-icon-container tile-hover-effect ${
                                actionErrorType === "transfer" ? "danger-border" : ""
                            } `}
                            onClick={onTransferMoneyClick}
                        >
                            <BusinessAndFinance width="30" height="30" />
                            <span className="text-14 mt-3">{formatMessage(messages.transfer)}</span>
                        </div>
                    ) : null}
                </div>
                {/* <div className="flex justify-between text-16 mt-15">
					<span className="secondary-light-color">
						{formatMessage(messages.yourBankAccount)}
					</span>
					// <span className="primary-color">Daten ändern</span>
				</div> */}
                {/* <AccountDetailsBlock
					className="mt-8"
					data={data.bankAccount}
				/> */}

                {actionErrorMessage ? (
                    <span className="error text-12 pl-0 mt-3 d-block mw-400">{formatMessage(messages[actionErrorMessage])}</span>
                ) : null}

                <div className="flex justify-between mt-15 mb-8 align-center">
                    <span className="secondary-light-color text-16">{formatMessage(messages.previousActivities)}</span>
                    {payments && payments.length > 5 ? (
                        <span onClick={onAllActivitiesClicked} className="cursor-pointer primary-color text-14">
                            {formatMessage(messages.allActivities)}
                        </span>
                    ) : null}
                </div>
                {payments && payments.length > 0 ? (
                    <AllActivitiesBlock payments={payments.slice(0, 5)} onBankActivityClick={onBankActivityClick} />
                ) : (
                    <span className="text-14">
                        {formatMessage(messages.noActivities)} -{" "}
                        <span className="primary-color cursor-pointer underline" onClick={onPayInClick}>
                            {formatMessage(messages.noActivitiesPayInText)}
                        </span>
                    </span>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    payments: selectUserPaymentsFilter,
    userId: selectUserId,
    mangoPayKycLevel: selectMangoPayKycLevel,
    isBlockedInFlowsMangoPay: selectIsBlockedInFlowsMangoPay,
    isBlockedOutFlowsMangoPay: selectIsBlockedOutFlowsMangoPay,
    isBlocked: selectMangopayBlocked,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserPaymentsStart: (userId) => dispatch(fetchUserPaymentsStart(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletOverview);
