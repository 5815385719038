import React, { useState, useMemo, useEffect } from "react";
import { useFormattedMessage } from "hooks";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SideModal from "components/SideModal";
import ProfileForm from "components/Profile/ProfileForm";
import UnWrappedProfileView from "components/Profile/ProfileView";
// import TaxForm from "components/Profile/TaxForm";
import WrappedProfileView from "components/Profile/WrappedProfileView";
import { setInvestmentStepData } from "store/user/investment/actions";
import { setUserMobileData } from "store/user/account/actions";
import {
    selectUserMobileDetails,
    selectUserDetails,
    selectUserAdditionalData,
    selectPayInWalletBalance,
} from "store/user/account/selectors";
import { accountProfilePage } from "i18n/messages/meta";
import { getGenders } from "utils";
import { formSectionHeadings } from "i18n/messages/formElements";
import { selectKycFormData } from "store/user/kyc/selectors";

const messages = {
    ...formSectionHeadings,
    // TaxHeading: "meta_title_tax_page",
};

const ProfileView = WrappedProfileView(UnWrappedProfileView);

export const RenderProfileStep = ({
    step,
    setStep,
    userData,
    open,
    userAdditionalData,
    onCloseButtonClick,
    onBackButtonClick,
    setKycModalFlag,
}) => {
    const [backSlideEffect, setBackSlideEffect] = useState("");

    const renderStep = (step) => {
        switch (step) {
            case 1:
                return (
                    <ProfileView
                        kycShow
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        setStep={setStep}
                        step={step}
                        open={open}
                        onCloseButtonClick={onCloseButtonClick}
                        onBackButtonClick={onBackButtonClick}
                        sectionsToShow={{
                            // canShowTax: true,
                            canShowPhone: true,
                            canShowAccount: true,
                            canShowPersonal: true,
                        }}
                    />
                );
            case 2:
                return (
                    <ProfileForm
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        userData={userData}
                        userAdditionalData={userAdditionalData}
                        onCloseButtonClick={onCloseButtonClick}
                        setStep={setStep}
                        step={step}
                        open={open}
                        setKycModalFlag={setKycModalFlag}
                    />
                );
            // case 3:
            //     return (
            //         <TaxForm
            //             backSlideEffect={backSlideEffect}
            //             setBackSlideEffect={setBackSlideEffect}
            //             userData={userData}
            //             setStep={setStep}
            //             step={step}
            //             open={open}
            //         />
            //     );
            default:
                return (
                    <ProfileView
                        kycShow
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        setStep={setStep}
                        step={step}
                        open={open}
                        sectionsToShow={{
                            // canShowTax: true,
                            canShowPhone: true,
                            canShowAccount: true,
                            canShowPersonal: true,
                        }}
                    />
                );
        }
    };
    return renderStep(step);
};

const ProfileModal = ({ open, onCloseButtonClick, onBackButtonClick, userAdditionalData, setKycModalFlag }) => {
    const { formatMessage } = useFormattedMessage();
    const [step, setStep] = useState(1);
    const [userData, setUserData] = useState({});
    const genders = getGenders(formatMessage);

    useEffect(() => {
        setUserData({
            ...userAdditionalData.personal,
            ...userAdditionalData.identification,
            ...userAdditionalData.tile,
            TypeGender:
                (userAdditionalData.personal.TypeGender &&
                    genders &&
                    genders.find((item) => userAdditionalData.personal.TypeGender === item.value)) ||
                null,
        });
    }, [userAdditionalData]);

    const memoizedRenderProfileStep = useMemo(() => {
        return (
            <RenderProfileStep
                step={step}
                setStep={setStep}
                userData={userData}
                open={open}
                userAdditionalData={userAdditionalData}
                onCloseButtonClick={onCloseButtonClick}
                onBackButtonClick={onBackButtonClick}
                setKycModalFlag={setKycModalFlag}
            />
        );
    }, [step, userData, open, userAdditionalData]);

    const renderHeading = (step) => {
        switch (step) {
            case 1:
                return formatMessage(accountProfilePage.title);
            case 2:
                return formatMessage(messages.identificationData);
            case 3:
                return formatMessage(messages.TaxHeading);
            default:
                return formatMessage(accountProfilePage.title);
        }
    };

    useEffect(() => {
        !open && setStep(1);
    }, [open]);

    return (
        <SideModal
            className="invite-friend-modal"
            heading={renderHeading(step)}
            open={open}
            isBackArrowOnTop
            onBackButtonClick={() => onBackButtonClick()}
            onCloseButtonClick={onCloseButtonClick}
        >
            {memoizedRenderProfileStep}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    userAdditionalData: selectUserAdditionalData,
    kycFormData: selectKycFormData,
    userMobileData: selectUserMobileDetails,
    userDetails: selectUserDetails,
    userPayInWalletBalance: selectPayInWalletBalance,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setUserMobileData: (data) => dispatch(setUserMobileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);
