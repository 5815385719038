import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { userDashboardDetailsSuccess } from "store/user/account/actions";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import { selectAdequacy, selectRenewAdequacy, selectFetchAdequacy, selectAdequacyBackup } from "store/user/adequacy/selectors";
import { setAdequacyStart, fetchAdequacySuccess } from "store/user/adequacy/actions";
import PrevButton from "components/PrevButton";
import { setAddequacyNotWantedData } from "api";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import IntlFormattedMessage from "components/IntlFormattedMessage";
import NewLoader from "components/NewLoader";

const messages = {
    heading: "experience_and_kowledge",
    cancelWarningText: "adequacy_cancel_warning_text",
    cancelSettingButtonText: "form_cancel",
    content: "questionnaire_on_adequacy_test",
    continueButtonText: "would_like_to_provide_info",
    cancelContent: "continue_without_information",
    cancelButtonText: "want_to_continue_without_exam",
    continueCheck: "adequacy_continue_check",
};

const CancelStep = ({
    className,
    setAdequacyStart,
    adequacyData,
    onNotWantedSurveyProps,
    userDashboardDetailsSuccess,
    userDashboardData,
    isWithInvestment,
    fetchAdequacySuccess,
    fetchAdequacyData,
    isWithSecondaryMarket,
    isWithOrder,
    isWithSetting,
    onBackButtonClick,
    previousStep,
    adequacyRenewData,
    adequacyBackup,
    open,
    backSlideEffect = "",
    setBackSlideEffect,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [adequacyNotWantedLoading, setAdequacyNotWantedLoading] = useState(false);

    async function onNotWantedSurvey() {
        try {
            setAdequacyNotWantedLoading(true);
            const response = await setAddequacyNotWantedData({ IsNotWantedConfirmed: true });
            const { Email } = response.data.data.adequacyTile.user;
            if (Email) {
                fetchAdequacySuccess({
                    ...fetchAdequacyData,
                    IsNotWantedConfirmed: true,
                    IsNotWantedConfirmedDate: new Date(),
                });
                setAdequacyStart({ step: 9 });
                setAdequacyNotWantedLoading(false);
                setBackSlideEffect("step-slide-left");
            }
            onNotWantedSurveyProps && onNotWantedSurveyProps();
            Email &&
                (isWithInvestment || isWithOrder || isWithSecondaryMarket) &&
                userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "hide" });
        } catch (error) {
            /* error */
        }
    }

    function onWantedSurvey() {
        setBackSlideEffect("step-slide-left");
        setAdequacyStart({ step: 4, survey_first_step: { ...adequacyData.survey_first_step, step: 1 } });
    }

    function onBack() {
        setBackSlideEffect("step-slide-right");
        setAdequacyStart({ step: 1 });
    }

    const cancelAdequacyRenew = () => {
        onBackButtonClick();
        setAdequacyStart({ ...adequacyBackup });
        setBackSlideEffect("step-slide-right");
    };

    const continueCheck = () => {
        setAdequacyStart({ ...adequacyRenewData, ...previousStep });
        setBackSlideEffect("step-slide-left");
    };

    return (
        <>
            {StatusAdequacy == "renew" && isWithSetting ? (
                <div className={`${className} adequacy-container second-step ${backSlideEffect}`}>
                    <div className="header-part">
                        <h4>{formatMessage(messages.heading)}</h4>
                    </div>

                    <div className="content">{formatMessage(messages.cancelWarningText)}</div>

                    <button className="mt-7 mw-100" onClick={continueCheck}>
                        {formatMessage(messages.continueCheck)}
                    </button>

                    <button disabled={adequacyNotWantedLoading} className={"secondary mt-7 mw-100"} onClick={cancelAdequacyRenew}>
                        {adequacyNotWantedLoading ? (
                            <>
                                <NewLoader /> {formatMessage(messages.cancelSettingButtonText)}
                            </>
                        ) : (
                            formatMessage(messages.cancelSettingButtonText)
                        )}
                    </button>
                </div>
            ) : (
                <div className={`${className} adequacy-container second-step ${backSlideEffect}`}>
                    <div className="header-part">
                        <h4>{formatMessage(messages.heading)}</h4>
                    </div>
                    <div className="content">
                        <IntlFormattedMessage id={messages.content} />
                    </div>
                    <button className="mt-7 mw-100" onClick={onWantedSurvey}>
                        {formatMessage(messages.continueButtonText)}
                    </button>
                    <div className="border-x" />
                    <p>{formatMessage(messages.cancelContent)}</p>
                    <button
                        disabled={adequacyNotWantedLoading}
                        className={`secondary mt-7 mw-100 ${adequacyNotWantedLoading ? "disable-grey" : ""}`}
                        onClick={onNotWantedSurvey}
                    >
                        {adequacyNotWantedLoading ? (
                            <>
                                <NewLoader /> {formatMessage(messages.cancelButtonText)}
                            </>
                        ) : (
                            formatMessage(messages.cancelButtonText)
                        )}
                    </button>
                </div>
            )}
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton onClick={onBack} />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
    userDashboardData: selectUserDashboardDetails,
    fetchAdequacyData: selectFetchAdequacy,
    adequacyRenewData: selectRenewAdequacy,
    adequacyBackup: selectAdequacyBackup,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
    fetchAdequacySuccess: (data) => dispatch(fetchAdequacySuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelStep);
