import React, { useEffect } from "react";

import CustomFormatNumber from "components/CustomFormatNumber";

import { useFormattedMessage } from "hooks";
import { setEqualHeight } from "utils";

import Flags from "country-flag-icons/react/1x1"; //country flags

import {
    ORDER_STATUS_TAGS,
    DEACTIVATE_ORDER_STATUS,
    secondaryMarketInvestmentStatuses,
    tagText,
    btnClassName,
    ALL_SELL_ORDER_STATUS,
    ALL_BUY_ORDER_STATUS,
} from "constants/index";
import { getProjectInvestmentStatusLookUp } from "utils/statusLookUp";

import { TILE_STATUS, INVESTMENT_STATUS, ORDER_STATUS, KYC_STATUS_LOCAL } from "constants/status";

const messages = {
    sharePrice: "share_price",
    per: "share_per",
    sharesFor: "digital_shares_for",
    shareFor: "digital_share_for",
    activeUntil: "active_until",
    sold: "order_sold",
    reserved: "order_reserved",
    open: "order_open",
    for: "shares_for",
    initialized: "investment_started",
    createdpendingkyc: "investment_offer_under_review",
    createdpendingliabilitiyumbrellaclearance: "investment_offer_under_review",
    createdpendingissuerclearance: "investment_offer_under_review",
    createdpendingpayment: "payment_transfer_due",
    createdDate: "created_date",
    orderStatusDeactivate: "order_status_deactivate",
    statusBought: "status_bought",
    statusSold: "status_sold",
    marketStatusPrimary: "primary",
    marketStatusSecondary: "secondary",
    shareShortText: "pc",
};

const succeededStatus = [
    INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT,
    INVESTMENT_STATUS.ACTIVE,
    INVESTMENT_STATUS.SECONDARY_MARKET_BUY,
    INVESTMENT_STATUS.SECONDARY_MARKET_SALE,
    INVESTMENT_STATUS.CANCELED,
];

const pendingInvestments = [
    INVESTMENT_STATUS.RESERVED,
    INVESTMENT_STATUS.INITIALIZED,
    INVESTMENT_STATUS.CREATED_PENDING_KYC,
    INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE,
    INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE,
    INVESTMENT_STATUS.CREATED_PENDING_PAYMENT,
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING,
    INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE,
];

const Card = ({
    currentKycStatus,
    onItemClick,
    className = "",
    shares,
    price,
    sharePrice,
    equalTitle,
    equalButton,
    title,
    city,
    isOrder = false,
    isMobileView = false,
    noDateText = false,
    date,
    flag,
    type,
    status,
    isOrderSplit = false,
    soldShares = 0,
    reservedShares = 0,
}) => {
    const { formatMessage } = useFormattedMessage();

    const FlagsByCountry = (flag && Flags[flag]) || null;

    const soldSharesPer = Number.parseFloat((soldShares * 100) / shares).toFixed(0);
    const reservedSharesPer = Number.parseFloat((reservedShares * 100) / shares).toFixed(0);
    const openShares = parseInt(soldSharesPer) + parseInt(reservedSharesPer);

    // const headingText = {
    //     [ORDER_TYPE.BUY]: formatMessage(messages.toBuy),
    //     [ORDER_TYPE.ACCEPT_BUY]: formatMessage(messages.toBuy),
    //     [ORDER_TYPE.ACCEPT_SPLIT_BUY]: formatMessage(messages.toBuy),
    //     [ORDER_TYPE.SELL]: formatMessage(messages.toSell),
    //     [ORDER_TYPE.ACCEPT_SELL]: formatMessage(messages.toSell),
    //     [ORDER_TYPE.ACCEPT_SPLIT_SELL]: formatMessage(messages.toSell),
    // }

    const renderTagText = (val) => {
        return tagText[val] || "";
    };

    useEffect(() => {
        if (window.outerWidth > 767) {
            setEqualHeight("equal-title");
            setEqualHeight("equal-button");
        }
    }, []);

    const investmentStatusLookup = getProjectInvestmentStatusLookUp(
        status === INVESTMENT_STATUS.INITIALIZED || status === INVESTMENT_STATUS.RESERVED
            ? status
            : currentKycStatus === KYC_STATUS_LOCAL.INITIATED
            ? TILE_STATUS.INITIATED
            : currentKycStatus === KYC_STATUS_LOCAL.PENDING
            ? TILE_STATUS.PENDING
            : status
    );

    return (
        <div
            onClick={onItemClick}
            className={`${className} details-container shadow order-item-block px-4 ${isMobileView ? "card-mobile-view" : ""}`}
        >
            <div className={equalTitle ? "equal-title" : ""}>
                <div className="flex justify-between w-100 mb-1 align-baseline">
                    <h6 className="title-city mt-0 text-sm-12 mb-0 flex flex-wrap">
                        {/* <>{type && headingText[type] || formatMessage(messages.toBuy)} {shares} {formatMessage(messages.sharesFor)} <CustomFormatNumber value={price} /></> */}
                        <>
                            <CustomFormatNumber value={shares} fixed={0} symbol="" className="mr-1" />
                            <span className="mr-1">
                                {shares == 1 ? formatMessage(messages.shareFor) : formatMessage(messages.sharesFor)}
                            </span>{" "}
                            <CustomFormatNumber className="text-no-break" value={price} />
                        </>
                    </h6>
                    {/* {date && !isOrder ? (
                    <span className="text-14 text-sm-10 fw-600 my-0 black-color expire-date">
                        {date}
                    </span>) : isOrder && sharePrice ? (
                        <span className="share text-12 fw-600 text-no-break ml-2">
                            <CustomFormatNumber value={sharePrice} />
                            <span className="ml-1">{formatMessage(messages.per)} {formatMessage(messages.shareShortText)}</span>
                        </span>
                    ) : null} */}
                    {!isOrder && sharePrice ? (
                        <span className="share text-14 text-sm-12 fw-600 text-no-break ml-2">
                            <CustomFormatNumber value={sharePrice} />
                            <span className="ml-1">
                                {formatMessage(messages.per)} {formatMessage(messages.shareShortText)}
                            </span>
                        </span>
                    ) : isOrder && sharePrice ? (
                        <span className="share text-14 text-sm-12 fw-600 text-no-break ml-2">
                            <CustomFormatNumber value={sharePrice} />
                            <span className="ml-1">
                                {formatMessage(messages.per)} {formatMessage(messages.shareShortText)}
                            </span>
                        </span>
                    ) : null}
                </div>
                <div className="flex justify-between w-100">
                    <h6 className={"text-12 text-sm-10 shares-price my-0 "}>
                        {title}
                        {city && <span className="light-grey-color fw-500">{` - ${city}`}</span>}
                    </h6>
                    {!isOrder && (secondaryMarketInvestmentStatuses.includes(status) || succeededStatus.includes(status)) ? (
                        <span className="text-12 fw-600 text-sm-10 text-uppercase text-no-break item-label ml-2">
                            {secondaryMarketInvestmentStatuses.includes(status)
                                ? formatMessage(messages.marketStatusSecondary)
                                : formatMessage(messages.marketStatusPrimary)}
                        </span>
                    ) : (
                        date &&
                        isOrder &&
                        status && (
                            <span className="text-12 text-sm-10 fw-500 my-0 light-grey-color text-no-break expire-date text-right">
                                {!noDateText && formatMessage(messages.activeUntil)}
                                {DEACTIVATE_ORDER_STATUS.includes(status) ? (
                                    " *"
                                ) : (
                                    <span className="black-color ml-2 fw-600">{date}</span>
                                )}
                            </span>
                        )
                    )}
                </div>
            </div>
            <div className={`flex justify-between w-100 mt-5 align-end ${equalButton ? "equal-button" : ""}`}>
                <div className="flex align-center">
                    {isOrder &&
                        (status || type) &&
                        (status == ORDER_STATUS.SUCCESS && type ? (
                            <span
                                className={`text-12 text-sm-10 fw-500 flex align-center ${
                                    btnClassName[
                                        ALL_SELL_ORDER_STATUS.includes(type)
                                            ? "sell-success"
                                            : ALL_BUY_ORDER_STATUS.includes(type)
                                            ? "buy-success"
                                            : type
                                    ] || ""
                                }`}
                            >
                                {renderTagText(
                                    ALL_SELL_ORDER_STATUS.includes(type)
                                        ? "sell-success"
                                        : ALL_BUY_ORDER_STATUS.includes(type)
                                        ? "buy-success"
                                        : type
                                )}
                            </span>
                        ) : (
                            <span
                                className={`text-12 text-sm-10 fw-500 ${
                                    btnClassName[(ORDER_STATUS_TAGS.includes(status) && status) || type] || ""
                                }`}
                            >
                                {renderTagText((ORDER_STATUS_TAGS.includes(status) && status) || type)}
                            </span>
                        ))}
                    {!isOrder && (secondaryMarketInvestmentStatuses.includes(status) || succeededStatus.includes(status)) && (
                        <span className={`text-12 text-sm-10 fw-500 investment-tag  ${btnClassName[status]}`}>
                            {renderTagText(status)}
                        </span>
                    )}
                    {!isOrder && pendingInvestments.includes(status) && (
                        <span className={`text-12 text-sm-10 fw-500 ${btnClassName[status]}`}>
                            {investmentStatusLookup?.text}
                        </span>
                    )}
                    {isOrder && flag && <FlagsByCountry className="flag ml-2" />}
                </div>
                {isOrder && isOrderSplit && !ORDER_STATUS_TAGS.includes(status) && (
                    <div className="flex flex-column align-end order-investment-card progress-bar-container">
                        <div className="flex text-12 text-sm-10 align-center">
                            {formatMessage(messages.sold)}{" "}
                            <span className="ml-2 black-color fw-600 same-width text-right">{soldSharesPer}%</span>
                        </div>
                        <div className="flex flex-wrap justify-end">
                            <div className="flex text-12 text-sm-10 ml-3 align-center">
                                {formatMessage(messages.reserved)}{" "}
                                <span className="ml-2 black-color fw-600">{reservedSharesPer}%</span>
                            </div>
                            <div className="flex text-12 text-sm-10 ml-3 align-center">
                                {formatMessage(messages.open)}{" "}
                                <span className="ml-2 black-color same-width text-right fw-600">{100 - openShares}%</span>
                            </div>
                        </div>
                    </div>
                )}
                {/* {!isOrder && sharePrice && (
                    <span className="share text-12 fw-600 text-no-break ml-2">
                        <CustomFormatNumber value={sharePrice} />
                        <span className="ml-1">{formatMessage(messages.per)} {formatMessage(messages.shareShortText)}</span>
                    </span>
                )} */}
                {date && !isOrder && <span className="text-14 text-sm-10 fw-600 my-0 black-color expire-date">{date}</span>}
                {/* {(isOrderSplit && status === ORDER_STATUS.DEACTIVATE) && (
                    <span className="share text-12 fw-600 text-no-break ml-2">
                            <CustomFormatNumber value={sharePrice} />
                        <span className="ml-1">{formatMessage(messages.per)} {formatMessage(messages.shareShortText)}</span>
                    </span>
                )} */}
            </div>
        </div>
    );
};

export default Card;
