import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage } from "hooks";
import SideModal from "components/SideModal";
import SettingsModal from "components/Settings";
import { selectLocale } from "store/app/selectors";
import { selectUserAdditionalData } from "store/user/account/selectors";
import { MOBILE_STATUS } from "constants/status";
import { emailSubscriptionSupportedTenants } from "constants/index";

const messages = {
    heading: "dashboard_nav_submenu_setting",
    settingLanguageTab: "settings_modal_language_heading",
    settingAdequacyTab: "setting_adequacy_tab",
    settingNotificationTab: "setting_notification_tab",
    settingChangePasswordTab: "change_password",
    settingDeleteAccountTab: "delete_account",
    languageEnglish: "language_english",
    languageGerman: "language_german",
    myData: "meta_title_account_profile_page",
};

export const Row = ({ onClick, leftTitle, rightTitle }) => {
    return (
        <div className="flex align-center justify-between p-4 w-100 text-16 cursor-pointer menu-tab" onClick={onClick}>
            <div>
                <div className="fw-500">{leftTitle}</div>
            </div>
            {rightTitle && <div className="black-color">{rightTitle}</div>}
        </div>
    );
};

const WrappedSettingsModal = ({
    open,
    onCloseButtonClick,
    locale,
    setAdequacyModalFlag,
    adequacyCompleted,
    onBackButtonClick,
    userAdditionalData,
    setKycModalFlag,
    currentTenant,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [step, setStep] = useState(null);
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    const languages = {
        en: formatMessage(messages.languageEnglish),
        de: formatMessage(messages.languageGerman),
    };

    useEffect(() => {
        !open && setSettingsModalOpen(false);
    }, [open]);

    useEffect(() => {
        const hash = window?.location?.hash?.substring(1);
        if (hash == MOBILE_STATUS.HASH_VARIABLE) {
            setStep(7);
        }
    }, []);

    const tiltesData = [
        {
            onClick: () => {
                setStep(7);
                setSettingsModalOpen(true);
            },
            leftTitle: formatMessage(messages.myData),
            isShowTile: true,
        },
        {
            onClick: () => {
                setStep(1);
                setSettingsModalOpen(true);
            },
            leftTitle: formatMessage(messages.settingLanguageTab),
            rightTitle: languages[userAdditionalData?.identification?.PreferredLanguage || "de"],
            isShowTile: true,
        },
        {
            onClick: adequacyCompleted
                ? () => {
                      setStep(5);
                      setSettingsModalOpen(true);
                  }
                : () => setAdequacyModalFlag(true),
            leftTitle: formatMessage(messages.settingAdequacyTab),
            isShowTile: true,
        },
        {
            onClick: () => {
                setStep(2);
                setSettingsModalOpen(true);
            },
            leftTitle: formatMessage(messages.settingNotificationTab),
            isShowTile: emailSubscriptionSupportedTenants.includes(currentTenant),
        },
        {
            onClick: () => {
                setStep(6);
                setSettingsModalOpen(true);
            },
            leftTitle: formatMessage(messages.settingChangePasswordTab),
            isShowTile: true,
        },
        {
            onClick: () => {
                setStep(3);
                setSettingsModalOpen(true);
            },
            leftTitle: formatMessage(messages.settingDeleteAccountTab),
            isShowTile: true,
        },
    ];
    return (
        <SideModal
            className="settings-modal"
            heading={formatMessage(messages.heading)}
            open={open}
            BackButtonTitle={" "}
            isBackArrowOnTop
            isCloseArrowOnRight
            onCloseButtonClick={onCloseButtonClick}
            onBackButtonClick={onBackButtonClick}
        >
            <SettingsModal
                locale={locale}
                step={step}
                setStep={setStep}
                open={isSettingsModalOpen}
                onBackButtonClick={() => {
                    setSettingsModalOpen(false);
                }}
                onCloseButtonClick={() => {
                    setSettingsModalOpen(false);
                    onCloseButtonClick();
                }}
                setAdequacyModalFlag={setAdequacyModalFlag}
                preferredLanguage={userAdditionalData?.identification?.PreferredLanguage || "de"}
                setKycModalFlag={setKycModalFlag}
                currentTenant={currentTenant}
            />

            <div className="flex column w-100">
                {tiltesData.map((tile, index) => {
                    if (!tile.isShowTile) return;
                    return (
                        <Row
                            leftTitle={tile.leftTitle}
                            leftContent={tile.leftContent}
                            onClick={tile.onClick}
                            rightTitle={tile.rightTitle}
                            key={index}
                        />
                    );
                })}
            </div>
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
    userAdditionalData: selectUserAdditionalData,
});

export default connect(mapStateToProps)(WrappedSettingsModal);
