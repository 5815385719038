module.exports = {
    RETAIL: "retail",
    BROKER: "broker",
    VIP: "vip",
    ADMIN: "admin",
    TENANT: "tenant",
    ISSUER: "issuer",
    USER: "user",
    CLUBDEALS: "club",
    TESTER: "tester",
    UMBRELLA: "umbrella",
};
