import { put, all, takeLatest, call } from "redux-saga/effects";
import IssuerManagerActionTypes from "./types";

import { fetchManagerIssuersListSuccess, fetchManagerIssuersListFailure } from "./actions";
import { getIssuers } from "api";

function* fetchManagerIssuersList() {
    try {
        const res = yield call(getIssuers);
        if (!res || res.errors || res.data.errors || res.data.data.errors)
            throw new Error(res.errors || res.data.errors || res.data.data.errors);
        const issuers = res.data.data.issuers.issuers;
        yield put(
            fetchManagerIssuersListSuccess(
                issuers?.map((item) => {
                    return {
                        label: item?.LegalName,
                        value: item?.uid,
                    };
                })
            )
        );
    } catch {
        yield put(fetchManagerIssuersListFailure());
    }
}

function* fetchIssuerManagerStart() {
    yield takeLatest(IssuerManagerActionTypes.FETCH_ISSUERS_LIST_START, fetchManagerIssuersList);
}

export function* issuerManagerSagas() {
    yield all([call(fetchIssuerManagerStart)]);
}
