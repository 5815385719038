import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PrevButton from "components/PrevButton";
import TopGreyDiv from "./components/topGreyDiv";
import FormatDateWithLocale from "components/FormatDateWithLocale";
import OrderItem from "components/OrderItem";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { getOrders } from "api";
import { BUY_ORDER_TYPE } from "constants/index";
import { ORDER_STATUS } from "constants/status";
import { selectEmail } from "store/user/account/selectors";
import { selectProjectDetails } from "store/project/selectors";
import NewLoader from "components/NewLoader";

const messages = {
    reserveOrdersTitle: "your_active_offers_to_buy",
    reserveAmountToEwallet: "reserved_balance",
    activeOrderTitle: "active_offers_to_buy",
    deactivateOrderTitle: "no_longer_valid",
    noCreditReserved: "no_credit_reserved",
};

const ReserveOrders = ({
    setShowDate,
    setCloseTitle,
    setStep,
    data,
    userEmail,
    backSlideEffect = "",
    setBackSlideEffect,
    allProjects,
}) => {
    useSideModalScrollToTop();

    // const router = useRouter();
    const { formatMessage } = useFormattedMessage();
    const [isUserOrdersLoading, setUserOrdersLoading] = useState(false);
    const [allUserOrders, setAllUserOrders] = useState([]);
    const [userOrders, setUserOrders] = useState([]);

    // const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.reserveOrdersTitle));
    }, []);

    // Fetch user's orders
    async function fetchUserOrders(email) {
        try {
            setUserOrdersLoading(true);
            const response = await getOrders({ email });
            response && setUserOrdersLoading(false);
            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.orders &&
                response.data.data.orders.orders
            ) {
                setAllUserOrders(response.data.data.orders.orders);
            }
        } catch (error) {
            /* error */
        }
    }

    useEffect(() => {
        userEmail && fetchUserOrders(userEmail);
    }, [userEmail]);

    useEffect(() => {
        const filteredOrders = {
            [ORDER_STATUS.ACTIVE]: [],
            [ORDER_STATUS.DEACTIVATE]: [],
            [ORDER_STATUS.INITIATED]: [],
            [ORDER_STATUS.CANCELED]: [],
            [ORDER_STATUS.SUCCESS]: [],
            [ORDER_STATUS.EXPIRED]: [],
            [ORDER_STATUS.FAILED]: [],
            [ORDER_STATUS.PROGRESS]: [],
            [ORDER_STATUS.CONFIRMED]: [],
        };
        allUserOrders.map((order) => {
            if (BUY_ORDER_TYPE.includes(order.TypeOrder)) {
                filteredOrders[order.StatusOrder].push(order);
            }
        });
        setUserOrders(filteredOrders);
    }, [allUserOrders, allUserOrders?.length]);

    const onBackClick = () => {
        setStep("walletOverview");
        setBackSlideEffect("step-slide-right");
    };

    const singleProject = (id) => allProjects?.find((item) => item.projectID === id) || {};

    return (
        <div className={`reserve-orders ${backSlideEffect}`}>
            <TopGreyDiv text={formatMessage(messages.reserveAmountToEwallet)} amount={data && data.blockedWalletBalance} />
            <div className="px-5 py-8 mw-450 mx-auto">
                {isUserOrdersLoading ? (
                    <NewLoader type="primary" className="center mt-5" />
                ) : Array.isArray(userOrders[ORDER_STATUS.ACTIVE]) && userOrders[ORDER_STATUS.ACTIVE]?.length > 0 ? (
                    <div className="active-orders">
                        <span className="text-16 mb-4 secondary-light-color d-inline-block">
                            {formatMessage(messages.activeOrderTitle)}
                        </span>
                        {userOrders[ORDER_STATUS.ACTIVE].map((order, index) => {
                            const project = singleProject(order?.LinkProduct?.ProductID);
                            return (
                                <OrderItem
                                    key={index}
                                    // className="cursor-pointer"
                                    onClickModal={() => {}}
                                    tag={BUY_ORDER_TYPE.includes(order?.TypeOrder) ? "buy" : "sell"}
                                    split={order?.IsSplitAllowed === "true"}
                                    image={project?.images?.teaserImage?.path}
                                    title={project?.title}
                                    city={project?.address?.city}
                                    shares={order?.Shares}
                                    remainingShares={order?.AvailableShares}
                                    price={parseFloat(order?.Amount)}
                                    date={
                                        order?.ExpirationDate && (
                                            <FormatDateWithLocale date={order?.ExpirationDate.split("_")[0]} />
                                        )
                                    }
                                    perSharePrice={order?.SharePrice}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <span className="text-16 mb-4 secondary-light-color d-inline-block">
                        {formatMessage(messages.noCreditReserved)}
                    </span>
                )}
                {/* <span className="text-16 mt-15 mb-4 secondary-light-color d-inline-block">{formatMessage(messages.deactivateOrderTitle)}</span>
            {
                Array.isArray(userOrders[ORDER_STATUS.DEACTIVATE]) && userOrders[ORDER_STATUS.DEACTIVATE].length > 0 &&
                <div className="deactivate-orders">
                    {
                        userOrders[ORDER_STATUS.DEACTIVATE].map((order, index) => {
                            const project = singleProject(order?.LinkProduct?.ProductID);
                            return (
                                <OrderItem
                                    key={index}
                                    className="disable"
                                    tag={BUY_ORDER_TYPE.includes(order?.TypeOrder) ? "buy" : "sell"}
                                    split={!!(order?.IsSplitAllowed === "true")}
                                    image={project?.images?.teaserImage?.path}
                                    title={project?.title}
                                    city={project?.address?.city}
                                    shares={order?.Shares}
                                    remainingShares={order?.AvailableShares}
                                    price={parseFloat(order?.Amount)}
                                    date={order?.ExpirationDate && <FormatDateWithLocale date={order?.ExpirationDate.split('_')[0]} />}
                                    pricePerShare={order?.SharePrice}
                                />
                            );
                        })
                    }
                </div>
            } */}
                <div className="bottom-bar-container">
                    <PrevButton onClick={onBackClick} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userEmail: selectEmail,
    allProjects: selectProjectDetails,
});

export default connect(mapStateToProps)(ReserveOrders);
