import React, { useEffect } from "react";

import CustomFormatNumber from "components/CustomFormatNumber";

import { setEqualHeight } from "utils";
import { useFormattedMessage } from "hooks";

//couunty flags
import Flags from "country-flag-icons/react/1x1";
import {
    tagText,
    btnClassName,
    ORDER_STATUS_TAGS,
    DEACTIVATE_ORDER_STATUS,
    ACTIVE_ORDER_STATUS,
    ALL_BUY_ORDER_STATUS,
    ALL_SELL_ORDER_STATUS,
} from "constants/index";

import { getProjectInvestmentStatusLookUp } from "utils/statusLookUp";
import { ORDER_STATUS } from "constants/status";

const messages = {
    boughtAt: "bought_at",
    sharePrice: "share_price",
    per: "share_per",
    sharesFor: "digital_shares_for",
    shareFor: "digital_share_for",
    activeUpto: "active_upto",
    executionDate: "execution_date",
    digitalSharesAvailable: "digital_shares_available",
    sold: "order_sold",
    reservedText: "order_reserved",
    open: "order_open",
    for: "for",
    createdDate: "created_date",
    expiryDate: "expiry_date",
    shareShortText: "pc",
    offerUnavailable: "offer_unavailable",
};

const OrderItem = ({
    onClickModal,
    className = "",
    image,
    title,
    city,
    shares,
    // price,
    date,
    executionDate,
    perSharePrice,
    boughtDate,
    salePrice,
    availableShares,
    location,
    split = false,
    reserveContent,
    reserveDateContent,
    reserveEdit,
    mergeLocationShares = false,
    btnBlockClassName = "",
    isOrderSplit = false,
    orderFlag,
    soldShares = 0,
    reservedShares = 0,
    innerClassName = "",
    // isInitiated = false,
    equalTitle = true,
    orderStatus,
    createdDate,
    investmentStatus,
    // remainingShares,
    tag,
    tagClassName,
    tagTitle,
    isPendingInvestment,
}) => {
    const { formatMessage } = useFormattedMessage();
    const soldSharesPer = Number.parseFloat((soldShares * 100) / parseInt(shares)).toFixed(0);
    const reservedSharesPer = Number.parseFloat((reservedShares * 100) / parseInt(shares)).toFixed(0);
    const openShares = parseInt(soldSharesPer) + parseInt(reservedSharesPer);
    const FlagsByCountry = orderFlag ? Flags[orderFlag] : null;

    const renderTagText = (val) => {
        return tagText[val] || "";
    };

    useEffect(() => {
        if (window.outerWidth > 767) {
            setEqualHeight("equal-title");
            setEqualHeight("equal-height");
            setEqualHeight("equal-content");
        }
    }, []);

    const totalShareValue = parseFloat(shares) * parseFloat(perSharePrice);
    const investmentStatusLookup = getProjectInvestmentStatusLookUp(investmentStatus);
    return (
        <div onClick={onClickModal} className={`${className} shadow flex flex-wrap order-item-block`}>
            <div className={"img-block bg-lighter-grey"}>{image && <img className="image" src={image} alt="" />}</div>
            <div className="details-container mb-0">
                <div className={`${innerClassName}`}>
                    <div className="flex justify-between">
                        {title && !city && (
                            <h6 className="title-city mt-0 property-item-text-overflow" title={title}>
                                {title}
                            </h6>
                        )}
                        {title && city && (
                            <h6 className="title-city mt-0">
                                {title}
                                {/* <span className="light-grey-color fw-500">{` - ${city}`}</span>  */} {/*remove city */}
                            </h6>
                        )}
                    </div>
                    {!mergeLocationShares && location && <span className="text-14 shares-price fw-500">{location}</span>}
                    <div className="d-flex justify-between">
                        {shares && (
                            <h6 className={`text-12 shares-price my-0 ${equalTitle ? "equal-title" : ""}`}>
                                <CustomFormatNumber value={shares} fixed={0} symbol="" />{" "}
                                {shares == 1 ? formatMessage(messages.shareFor) : formatMessage(messages.sharesFor)}{" "}
                                <CustomFormatNumber value={totalShareValue} />
                            </h6>
                        )}
                        {split && <img className="flag" src={"/images/split.png"} alt="split" />}
                    </div>
                    {reserveContent && <span className="text-12 fw-600 d-block shares-price mt-0">{reserveContent}</span>}

                    {executionDate ? (
                        <div className="flex justify-between align-center mb-4 mt-2">
                            <span className="text-12 fw-500 my-0 light-grey-color expire-date">
                                {formatMessage(messages.executionDate)} <span className="black-color"> {executionDate}</span>
                            </span>
                        </div>
                    ) : null}
                    {date || createdDate || isOrderSplit || perSharePrice ? (
                        <div className="flex justify-between align-center mb-4 mt-2">
                            {DEACTIVATE_ORDER_STATUS.includes(orderStatus) && tag && (
                                <span className={"text-12 fw-500 my-0 light-grey-color mr-1"}>{renderTagText(tag)} </span>
                            )}
                            {
                                date && orderStatus === ORDER_STATUS.SUCCESS ? (
                                    <span className="text-12 fw-500 my-0 light-grey-color expire-date">
                                        {formatMessage(messages.activeUpto)}
                                        {" *"}
                                    </span>
                                ) : (date && !orderStatus) || (date && ACTIVE_ORDER_STATUS.includes(orderStatus)) ? (
                                    <span className="text-12 fw-500 my-0 light-grey-color expire-date">
                                        {formatMessage(messages.activeUpto)} <span className="black-color"> {date}</span>
                                    </span>
                                ) : date && orderStatus === ORDER_STATUS.CANCELED ? (
                                    <span className="text-12 fw-500 my-0 light-grey-color expire-date">
                                        {` ${formatMessage(messages.offerUnavailable)}`}
                                    </span>
                                ) : null
                                // removed active upto * text in deactivate order status
                                // DEACTIVATE_ORDER_STATUS.includes(orderStatus) &&
                                // <span className="text-12 fw-500 my-0 light-grey-color expire-date">
                                //     {formatMessage(messages.activeUpto)}{" *"}
                                // </span>
                            }
                            {createdDate && !orderStatus && (
                                <span className="text-12 fw-500 my-0 light-grey-color expire-date">
                                    {formatMessage(isPendingInvestment ? messages.expiryDate : messages.createdDate)}
                                    <span className="black-color"> {createdDate}</span>
                                </span>
                            )}
                            {(salePrice || perSharePrice) && (
                                <span className="share text-12 fw-500 ml-auto">
                                    {salePrice && (
                                        <>
                                            {formatMessage(messages.sharePrice)} <br />
                                        </>
                                    )}
                                    <CustomFormatNumber value={perSharePrice || salePrice} />{" "}
                                    {formatMessage(messages.shareShortText)}
                                </span>
                            )}
                            {/* {isOrderSplit == "true" && perSharePrice ?
                                    <span className="share text-12 fw-500">
                                        <CustomFormatNumber value={perSharePrice} />
                                        {` `}{formatMessage(messages.shareShortText)}
                                        </span>
                                    : null} */}
                        </div>
                    ) : null}

                    {reserveDateContent && (
                        <span className="text-12 fw-500 light-grey-color expire-date mb-3 d-block">{reserveDateContent}</span>
                    )}

                    {!mergeLocationShares && availableShares ? (
                        <span className="text-14 fw-500 expire-date">
                            <CustomFormatNumber value={availableShares} noSymbol fixed={0} />
                            {` ${formatMessage(messages.digitalSharesAvailable)}`}
                        </span>
                    ) : null}

                    {location && availableShares && mergeLocationShares ? (
                        <span className="d-block text-14 shares-price fw-500">
                            {location}
                            {`, ${availableShares} ${formatMessage(messages.digitalSharesAvailable)}`}
                        </span>
                    ) : null}

                    {isOrderSplit ? (
                        <div className="progress-bar-container">
                            <div className="position-relative progress-bar">
                                <div className="sold-shares" style={{ width: `${soldSharesPer}%` }} />
                                <div className="reserved-shares" style={{ width: `${openShares}%` }} />
                            </div>
                            <div className="flex mt-2 align-center">
                                <div className="flex align-center">
                                    <div className="dot bg-primary mr-1" />
                                    {formatMessage(messages.sold)} <span className="ml-1 black-color">{soldSharesPer}%</span>
                                </div>
                                <div className="flex ml-3 align-center">
                                    <div className="dot bg-primary-light mr-1" />
                                    {formatMessage(messages.reservedText)}{" "}
                                    <span className="ml-1 black-color">{reservedSharesPer}%</span>
                                </div>
                                <div className="flex ml-3 align-center">
                                    <div className="dot bg-another-grey mr-1" />
                                    {formatMessage(messages.open)} <span className="ml-1 black-color">{100 - openShares}%</span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className={`flex justify-between align-center ${btnBlockClassName ? btnBlockClassName : "mt-5"}`}>
                    {investmentStatus && (
                        <span className={`text-12 text-sm-10 fw-500 ${btnClassName[investmentStatus] || ""}`}>
                            {investmentStatusLookup?.text}
                        </span>
                    )}
                    {(orderStatus || tag || orderFlag || tagTitle) && (
                        <div className="flex align-center">
                            {orderStatus == ORDER_STATUS.SUCCESS && tag ? (
                                <span
                                    className={`text-12 text-sm-10 fw-500 flex align-center 
                                ${
                                    btnClassName[
                                        ALL_SELL_ORDER_STATUS.includes(tag)
                                            ? "sell-success"
                                            : ALL_BUY_ORDER_STATUS.includes(tag)
                                            ? "buy-success"
                                            : tag
                                    ] || ""
                                }`}
                                >
                                    {renderTagText(
                                        ALL_SELL_ORDER_STATUS.includes(tag)
                                            ? "sell-success"
                                            : ALL_BUY_ORDER_STATUS.includes(tag)
                                            ? "buy-success"
                                            : tag
                                    )}
                                </span>
                            ) : (
                                <span
                                    className={`text-12 text-sm-10 fw-500 flex align-center ${
                                        tagClassName ||
                                        btnClassName[(ORDER_STATUS_TAGS.includes(orderStatus) && orderStatus) || tag] ||
                                        ""
                                    }`}
                                >
                                    {tagTitle || renderTagText((ORDER_STATUS_TAGS.includes(orderStatus) && orderStatus) || tag)}
                                </span>
                            )}
                            {FlagsByCountry && <FlagsByCountry className="flag ml-2" />}
                        </div>
                    )}
                    {boughtDate && (
                        <span className="text-12 fw-500 light-grey-color">
                            {formatMessage(messages.boughtAt)} <br />
                            {boughtDate}
                        </span>
                    )}
                    {reserveEdit && <span className="share text-12">Edit</span>}
                </div>
            </div>
        </div>
    );
};

export default OrderItem;
