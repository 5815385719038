export const investmentDetailsPage = {
    title: "meta_title_investment_details_page",
};

export const campaign1Page = {
    title: "meta_title_campaign1_page",
};

export const campaign3Page = {
    title: "meta_title_campaign3_page",
};

export const multiasset1Page = {
    title: "meta_title_multiasset1_page",
    description: "meta_description_multiasset1_page",
    keywords: "meta_keywords_multiasset1_page",
};

export const authPage = {
    title: "meta_title_auth_page",
};
export const registerPage = {
    title: "meta_title_register_page",
};

export const userDeleted = {
    title: "meta_title_user_deleted_page",
};

export const neuprop0401 = { title: "meta_title_neuprop0401_page" };
export const neuprop0402 = { title: "meta_title_neuprop0402_page" };
export const neuprop0403 = { title: "meta_title_neuprop0403_page" };
export const neuprop0404 = { title: "meta_title_neuprop0404_page" };
export const neuprop0405 = { title: "meta_title_neuprop0405_page" };
export const neuprop0406 = { title: "meta_title_neuprop0406_page" };
export const neuprop0407 = { title: "meta_title_neuprop0407_page" };
export const neuprop0408 = { title: "meta_title_neuprop0408_page" };
export const neuprop0409 = { title: "meta_title_neuprop0409_page" };
export const neuprop04010 = { title: "meta_title_neuprop04010_page" };
export const neuprop04011 = { title: "meta_title_neuprop04011_page" };
export const neuprop04012 = { title: "meta_title_neuprop04012_page" };
export const neuprop04013 = { title: "meta_title_neuprop04013_page" };
export const neuprop04014 = { title: "meta_title_neuprop04014_page" };
export const neuprop04015 = { title: "meta_title_neuprop04015_page" };
export const neuprop04016 = { title: "meta_title_neuprop04016_page" };
export const neuprop04019 = { title: "meta_title_neuprop04019_page" };
export const neuprop04020 = { title: "meta_title_neuprop04020_page" };
export const neuprop04026 = { title: "meta_title_neuprop04026_page" };
export const neutouristik01 = { title: "meta_title_neutouristik01_page" };
export const frdAndalucia1 = { title: "meta_title_frd_andalucia1_page" };
export const arelBaubetreuung = { title: "meta_title_arel_baubetreuung_page" };

export const neupe01 = { title: "meta_title_neupe01_page" };
export const neupe02 = { title: "meta_title_neupe02_page" };
export const neupe03 = { title: "meta_title_neupe03_page" };
export const neupe04 = { title: "meta_title_neupe04_page" };

export const neuart01 = { title: "meta_title_neuart01_page" };
export const neuart02 = { title: "meta_title_neuart02_page" };

export const neucollect01 = { title: "meta_title_neucollect01_page" };
export const neucollect02 = { title: "meta_title_neucollect02_page" };
export const neucollect03 = { title: "meta_title_neucollect03_page" };
export const neucollect04 = { title: "meta_title_neucollect04_page" };

export const siVermoeGen1 = { title: "meta_title_sivermoegen1_page" };
export const siVermoeGen2 = { title: "meta_title_sivermoegen2_page" };
export const siVermoeGen3 = { title: "meta_title_sivermoegen3_page" };
export const siVermoeGen4 = { title: "meta_title_sivermoegen4_page" };
export const zemitzSolar = { title: "meta_title_zemitzsolar_page" };

export const proptechPalma = {
    title: "meta_title_assetta_prop_tech_palma_page",
};

export const promocionNovaOne = {
    title: "meta_title_promocion_nova_one_page",
};

export const auroraOne = {
    title: "meta_title_aurora_one_page",
};

export const solaraOne = {
    title: "meta_title_solara_one_page",
};

export const watchvestAsset = {
    title: "meta_title_watchvest_asset_page",
};

export const BC277 = {
    title: "meta_title_bc277_page",
};

export const winvestaProp = {
    title: "meta_title_winvestaprop_page",
};

export const winvestaGreenProp = {
    title: "meta_title_winvestagreenporp_page",
};

export const clubDealOnboardingPage = {
    title: "meta_title_clubdeal_onboarding_page",
};

export const brokerOnboardingPage = {
    title: "meta_title_broker_onboarding_page",
};

export const appPage = {
    title: "meta_title_app_page",
};

export const provision = {
    title: "dashboard_nav_submenu_provision",
};

export const personalData = {
    title: "meta_title_personal_data_page",
};

export const advertisingMaterialsPage = {
    title: "meta_title_advertising_material_page",
};

export const information = {
    title: "information",
};

export const brokerDashboard = {
    title: "meta_title_broker_dashboard_page",
};

export const support = {
    title: "meta_title_support_page",
};

export const performancePage = {
    title: "meta_title_performance_page",
};

export const customerPage = {
    title: "meta_title_customer_page",
};

export const adminUsersPage = {
    title: "meta_title_admin_user_page",
};

export const adminProjectsPage = {
    title: "meta_title_admin_projects_page",
};

export const issuerProductsPage = {
    title: "meta_title_admin_projects_page",
};

export const miscellaneousPage = {
    title: "dashboard_nav_submenu_miscellaneous",
};

export const adminTenant = {
    title: "nav_tenant",
};

export const adminTranslationPage = {
    title: "META_TITLE_ADMIN_TRANSLATION",
};

export const adminInvestmentsPage = {
    title: "confirmation_investment",
};

export const adminTestingSuitesPage = {
    title: "meta_title_admin_testing_suite_page",
};

export const adminOrderPage = {
    title: "meta_title_admin_order_page",
};

export const adminPaymentsPage = {
    title: "meta_title_admin_payment_page",
};

export const brokerPage = {
    title: "meta_title_broker_page",
};

export const adminBlockchainPage = {
    title: "meta_title_admin_blockchain_page",
};

export const adminDocumentsPage = {
    title: "meta_title_admin_documents_page",
};

export const adminSecondaryMarketPage = {
    title: "dashboard_nav_submenu_secondary_market",
};

export const adminIssuerPage = {
    title: "dashboard_nav_submenu_issuers",
};
export const adminPayoutsPage = {
    title: "meta_title_admin_payouts_page",
};
export const adminPlatformSettingsPage = {
    title: "meta_title_admin_platform_settings_page",
};
export const tenantProductCatalog = {
    title: "dashboard_nav_submenu_product_catalog",
};

export const accountReferralPage = {
    title: "meta_title_account_referral_page",
};

export const accountProfilePage = {
    title: "meta_title_account_profile_page",
};

export const changePassword = {
    title: "change_password",
};

export const userDetailPage = {
    title: "meta_title_user_detail_page",
};

export const userMobilePage = {
    title: "meta_title_user_mobile_page",
};

export const clubDealsDetailPage = {
    title: "meta_title_clubdeals_detail_page",
};

export const newsletterSubscriptionErrorPage = {
    title: "meta_title_newsletter_subscription_error_page",
};

export const emailConfirmSuccess = {
    title: "meta_title_email_subscription_success_page",
};

export const confirmEmail = {
    title: "meta_title_email_subscription_page",
};

export const dashboardPage = {
    title: "meta_title_dashboard_page",
};

export const propertyPage = {
    title: "marketplace",
    description: "meta_description_property_page",
};

export const propertyDetailsPage = {
    title: "meta_title_property_details_page",
};

export const clubDealsPage = {
    title: "meta_title_clubdeals_page",
    description: "meta_description_clubdeals_page",
};

export const passwordRemindPage = {
    title: "meta_title_password_remind_page",
};

export const resetPasswordPage = {
    title: "meta_title_password_reset_page",
};

export const unsubscribeSuccessPage = {
    title: "meta_title_unsubscribe_success_page",
};

export const reserveShareSuccessPage = {
    title: "meta_title_reserve_share_success_page",
};

export const reserveShareFailPage = {
    title: "meta_title_reserve_share_fail_page",
};

export const tenantOverviewPage = {
    title: "overview",
};

export const tenantSettingPage = {
    title: "setting",
};

export const adminNotificationPage = {
    title: "meta_title_news_letter_tags_page",
};

export const reserveShareOutdatedStatePage = {
    title: "meta_title_reserve_share_outdated",
};

export const umbrellaUsersPage = {
    title: "meta_title_admin_user_page",
};

export const umbrellaInvestmentsPage = {
    title: "confirmation_investment",
};

export const issuerOverviewPage = {
    title: "overview",
};

export const issuerAccountPage = {
    title: "account_heading",
};

export const issuerEWalletPage = {
    title: "e-wallet",
};

export const issuerUpdatePage = {
    title: "update_text",
};

export const investorsPage = {
    title: "meta_title_investors_page",
};

export const kaloNuernberg = { title: "meta_title_kalo_nuernberg_page" };

export const louisYachting = {
    title: "meta_title_louis_yachting_page",
};

export const toCo = {
    title: "meta_title_to_co_page",
};
