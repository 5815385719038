import React, { useState, useEffect } from "react";

import Start from "./start";
import Front from "./front";
import Back from "./back";
import Success from "./success";

const RetakeIF = ({
    setShowDate,
    data,
    setCloseTitle,
    setStep,
    setStepAndData,
    stepData,
    backSlideEffect = "",
    setBackSlideEffect,
    isCameraPermissionEnabled,
}) => {
    const [retakeStep, setRetakeStep] = useState("start");
    const [frontForceStep, setFrontForceStep] = useState(null);

    useEffect(() => {
        setShowDate(false);
    }, []);

    const renderRetakeStep = {
        start: (
            <Start
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
                setRetakeStep={setRetakeStep}
            />
        ),
        front: (
            <Front
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setCloseTitle={setCloseTitle}
                setRetakeStep={setRetakeStep}
                forceStep={frontForceStep}
                setStepAndData={setStepAndData}
                stepData={stepData}
                isCameraPermissionEnabled={isCameraPermissionEnabled}
            />
        ),
        back: (
            <Back
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setCloseTitle={setCloseTitle}
                setRetakeStep={setRetakeStep}
                backToFront={(step) => setFrontForceStep(step)}
                setStepAndData={setStepAndData}
                stepData={stepData}
                isCameraPermissionEnabled={isCameraPermissionEnabled}
            />
        ),
        success: (
            <Success
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
    };

    return renderRetakeStep[retakeStep];
};

export default RetakeIF;
