const Chip = ({ label, chipId, disabled = false, onRemoveSelectedTenant = () => {} }) => {
    return (
        <div
            className={`chip mb-1 text-12 mr-2 px-2 py-1 d-flex align-center ${
                disabled ? "disable-pointer disable-input-bg" : ""
            }`}
            disabled={disabled}
        >
            <span className="d-flex align-center">{label}</span>
            {!disabled ? (
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    className="cursor-pointer ml-1"
                    onClick={() => onRemoveSelectedTenant(chipId)}
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect
                                id="Rectangle_3915"
                                data-name="Rectangle 3915"
                                width="12"
                                height="12"
                                transform="translate(-0.764 -0.763)"
                                fill="#fff"
                                stroke="#707070"
                                strokeWidth="1"
                            />
                        </clipPath>
                    </defs>
                    <g id="Mask_Group_70" data-name="Mask Group 70" transform="translate(0.764 0.763)" clipPath="url(#clip-path)">
                        <g id="Group_10940" data-name="Group 10940" transform="translate(1.424 1.425)">
                            <path
                                id="Path_5635"
                                data-name="Path 5635"
                                d="M-23457-22296.006l7.623,7.627"
                                transform="translate(23457 22296.006)"
                                fill="none"
                                stroke="#171926"
                                strokeWidth="1.5"
                            />
                            <path
                                id="Path_5636"
                                data-name="Path 5636"
                                d="M-23457-22288.379l7.623-7.627"
                                transform="translate(23457 22296.006)"
                                fill="none"
                                stroke="#171926"
                                strokeWidth="1.5"
                            />
                        </g>
                    </g>
                </svg>
            ) : (
                ""
            )}
        </div>
    );
};

export default Chip;
