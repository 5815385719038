import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import TopGreyDiv from "../components/topGreyDiv";
import cn from "classnames";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { sendFormError } from "i18n/messages/errors";
import { withdrawAmount as amountMessages } from "i18n/messages/formElements";
import { selectPayInWalletBalance } from "store/user/account/selectors";
import { fetchPayInWalletBalanceSuccess } from "store/user/account/actions";

import { mangopayPayOut } from "api";
import OTPInput from "components/OTPInput";
import NewLoader from "components/NewLoader";

const messages = {
    payOutTitle: "wallet_payout_title",
    withdrawalAmountToBank: "withdrawal_amount_to_bank",
    payOutConfirmHeading: "confirm_payment",
    note: "payout_confirm_note",
    noteHeading: "payout_confirm_note_heading",
    yourPayoutAccount: "your_payout_account",
    releaseTransaction: "release_transaction",
    releaseTransactionContent: "otp_code",
    invalidTAN: "invalid_code",
    mobileTANcodeExpired: "mobile_tan_code_expired",
    resendTAN: "resend_otp_code",
    buttonText: "request_payout_now",
    blockedPayOutErrorMessage: "wallet_blocked_payout_error_message",
    sendCodeAgainIn: "send_code_again",
};

const PayOutConfirmation = ({
    setShowDate,
    setCloseTitle,
    setStep,
    setStepAndData,
    payInAmount,
    stepData,
    fetchPayInWalletBalanceSuccess,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [requestError, setRequestError] = useState("");
    const [errorCode, setErrorCode] = useState("");
    const [OTP, setOTP] = useState("");
    const [isSendingCode, setSendingCode] = useState(false);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.payOutTitle));
    }, []);

    useEffect(() => {
        if (errorCode === "invalid_input") {
            setTimeout(() => {
                setOTP("");
                setErrorCode("");
            }, 500);
        }
    }, [errorCode]);

    useEffect(() => {
        OTP.length === 0 && setRequestError("");
    }, [OTP]);

    const onPayout = () => {
        setIsLoading(true);
        mangopayPayOut({
            bankAccountID: stepData.bankAccountID,
            paymentAmount: stepData.paymentAmount,
            TAN: OTP,
        })
            .then((res) => {
                setIsLoading(false);
                if (res.data && res.data.errors && res.data.errors[0]) {
                    setErrorCode(res.data.errors[0].message);
                    setRequestError(
                        formatMessage(
                            res.data.errors[0].message === "invalid_input"
                                ? messages.invalidTAN
                                : res.data.errors[0].message === "TAN_expired"
                                ? messages.mobileTANcodeExpired
                                : res.data.errors[0].message === "mangopay_out_flows_blocked"
                                ? messages.blockedPayOutErrorMessage
                                : res.data.errors[0].message === "mangopay_invalid_min_payout_amount"
                                ? amountMessages["minimumWithdrawAmount"]
                                : res.data.errors[0].message === "mangopay_wallet_balance_insufficient"
                                ? amountMessages["amountExceed"]
                                : sendFormError
                        )
                    );
                }

                if (!res || res.errors || res.data.errors || res.data.data.errors) return;

                // Update payIn wallet balance
                const Amount = parseFloat(payInAmount) - parseFloat(stepData.paymentAmount);

                fetchPayInWalletBalanceSuccess({ Amount });

                setStepAndData("payOutOverview", {
                    bankAccountID: stepData.bankAccountID,
                    paymentAmount: stepData.paymentAmount,
                });
                setBackSlideEffect("step-slide-left");
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const onResetOTP = () => {
        const data = {
            bankAccountID: stepData.bankAccountID,
            paymentAmount: stepData.paymentAmount,
        };
        setSendingCode(true);
        mangopayPayOut(data)
            .then((res) => {
                res && setSendingCode(false);
                setCounter(90);
                if (res?.data?.errors[0]) {
                    setRequestError({ requestError: res.data.errors[0].message });
                }

                if (!res || res?.errors || res?.data?.errors || res?.data?.data?.errors) return;
            })
            .catch(() => {
                setSendingCode(false);
            });
    };

    useEffect(() => {
        if (counter > 0 && !isSendingCode) {
            const interval = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [isSendingCode, counter]);

    return (
        <div className={`pay-out-confirmation ${backSlideEffect}`}>
            <TopGreyDiv text={formatMessage(messages.withdrawalAmountToBank)} amount={stepData && stepData.paymentAmount} />
            <div className="px-5 py-4 mw-450 mx-auto">
                <h3 className="mb-6 ">{formatMessage(messages.payOutConfirmHeading)}</h3>
                <p className="mb-10">{formatMessage(messages.note)}</p>
                <h4>{formatMessage(messages.releaseTransaction)}</h4>
                <p className="mb-5">{formatMessage(messages.releaseTransactionContent)}</p>

                <OTPInput
                    errorCode={errorCode}
                    value={OTP}
                    onChange={(value) => setOTP(value)}
                    isSubmitting={isLoading}
                    errorMessage={requestError}
                    shouldAutoFocus
                />
                <div className="flex justify-end">
                    <span
                        className={`light-grey-color text-12 mb-5 ${isSendingCode || counter !== 0 ? "" : "cursor-pointer"}`}
                        onClick={() => {
                            isSendingCode || counter !== 0 ? null : onResetOTP();
                        }}
                        disabled={counter !== 0}
                    >
                        {isSendingCode ? (
                            <>
                                <NewLoader type="primary" />
                                {formatMessage(messages.resendTAN)}
                            </>
                        ) : counter !== 0 ? (
                            `${formatMessage(messages.sendCodeAgainIn)} ${counter} sec`
                        ) : (
                            formatMessage(messages.resendTAN)
                        )}
                    </span>
                </div>

                {!isLoading && requestError && <span className="error pl-0">{requestError}</span>}
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setStep("payOut");
                            setBackSlideEffect("step-slide-right");
                        }}
                        disable={isLoading}
                    />
                    <NextButton
                        className={
                            cn({
                                "disable-grey": isLoading || OTP?.length !== 6,
                            }) + " mw-fit"
                        }
                        disable={isLoading || OTP?.length !== 6}
                        onClick={onPayout}
                        title={formatMessage(messages.buttonText)}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    payInAmount: selectPayInWalletBalance,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPayInWalletBalanceSuccess: (value) => dispatch(fetchPayInWalletBalanceSuccess(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayOutConfirmation);
