import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import PrevButton from "components/PrevButton";
import { getApiGatewayUrl } from "common/helper";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import { selectPlatformSourceFromApp, selectTenant } from "store/app/selectors";
import IntlFormattedMessage from "components/IntlFormattedMessage";
import { getPostidentPDF } from "api/FnxtApis";
import Loader from "components/Loader";

const messages = {
    content: "identify_yourself",
    stepFirstTitle: "download_print",
    stepFirstContent: "download_print_this_pdf_file",
    stepFirstButtonText: "download_pdf",
    stepTwoTitle: "identify_in_the_branch",
    stepTwoContent: "visit_post_office",
    stepTwoButtonText: "complete_identification",
};

const DOCUMENT_HOST = getApiGatewayUrl();

const PostidentPdfInstructions = ({
    className = "",
    onBack,
    onDownloadClick,
    open,
    isApp,
    backSlideEffect = "",
    currentTenant,
}) => {
    useSideModalScrollToTop();

    const [isDownloaded, setDownloaded] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const { formatMessage } = useFormattedMessage();

    const getLink = () => {
        return `${DOCUMENT_HOST}/kyc/v1/postident/${currentTenant}/coupon/`;
    };

    function openPDF() {
        setLoading(true);
        getPostidentPDF(currentTenant)
            .then((response) => {
                setLoading(false);
                if (response.status == 200) {
                    setDownloaded(true);
                    var file = new Blob([response.data], { type: "application/pdf" });
                    var fileURL = URL.createObjectURL(file);
                    setDownloaded(true);
                    window.open(fileURL);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className={`postident-container mt-6 ${className} ${backSlideEffect}`}>
                <img className="mb-8" src={"/images/postident.png"} alt="" />
                <p className="pt-2 pb-7">{formatMessage(messages.content)}</p>
                <div className="postident-block download-print">
                    <div className="flex align-center">
                        <span className="step-num">1</span>
                        <span className="text-14 fw-600">{formatMessage(messages.stepFirstTitle)}</span>
                    </div>
                    <div className="flex justify-center align-center image-container">
                        <img src={"/images/pdf.png"} alt="" />
                        <div className="border-x-dotted" />
                        <img src={"/images/drucker.png"} alt="" />
                    </div>
                    <p>{formatMessage(messages.stepFirstContent)}</p>
                    {isApp ? (
                        <button
                            className=""
                            onClick={() => {
                                setDownloaded(true);
                                window?.ReactNativeWebView?.postMessage(JSON.stringify({ postidentLink: getLink() }));
                            }}
                        >
                            {isLoading ? <Loader /> : null}
                            {formatMessage(messages.stepFirstButtonText)}
                        </button>
                    ) : (
                        <button
                            className=""
                            onClick={() => {
                                openPDF();
                            }}
                        >
                            {isLoading ? <Loader /> : null}
                            {formatMessage(messages.stepFirstButtonText)}
                        </button>
                    )}
                </div>
                <div className="postident-block identify-store">
                    <div className="flex align-center">
                        <span className="step-num">2</span>
                        <span className="text-14 fw-600">{formatMessage(messages.stepTwoTitle)}</span>
                    </div>
                    <div className="flex justify-center align-center image-container">
                        <img src={"/images/brief.png"} alt="" />
                        <div className="border-x-dotted" />
                        <img src={"/images/filiale.png"} alt="" />
                    </div>
                    <p>
                        <IntlFormattedMessage id={messages.stepTwoContent} />
                    </p>
                </div>

                <button
                    onClick={() => {
                        isDownloaded && onDownloadClick && onDownloadClick();
                    }}
                    className={`mw-100 mb-0 ${!isDownloaded ? "disable-grey" : ""}`}
                >
                    {formatMessage(messages.stepTwoButtonText)}
                </button>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton className="ppd-back-button" onClick={onBack} />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userDashboardData: selectUserDashboardDetails,
    isApp: selectPlatformSourceFromApp,
    currentTenant: selectTenant,
});

// const mapDispatchToProps = (dispatch) => ({
//     userDashboardDetailsRequest: () => dispatch(userDashboardDetailsRequest()),
// });

export default connect(mapStateToProps)(PostidentPdfInstructions);
