import React from "react";
import { useFormattedMessage } from "hooks";

const messages = {
    deleteAccountButton: "delete_account",
    cancelButtonText: "form_cancel",
    deleteAccountSubHeadingFirst: "delete_account_sub_heading_first",
    deleteAccountSubHeadingSecond: "delete_account_sub_heading_second",
};

const DeleteAccountModal = ({ step, setStep, onBackButtonClick, backSlideEffect = "", setBackSlideEffect }) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`flex column py-3 ${backSlideEffect}`}>
            <div>
                <div className="mb-3 text-16 fw-600">{formatMessage(messages.deleteAccountSubHeadingFirst)}</div>
                <div className="text-16">{formatMessage(messages.deleteAccountSubHeadingSecond)}</div>
            </div>
            <div className="mt-8">
                <button
                    className="mw-100"
                    onClick={() => {
                        setStep(step + 1);
                        setBackSlideEffect("step-slide-left");
                    }}
                >
                    {formatMessage(messages.deleteAccountButton)}
                </button>
            </div>
            <div className="mt-1">
                <button
                    className="mw-100 secondary"
                    onClick={() => {
                        onBackButtonClick();
                        setBackSlideEffect("step-slide-right");
                    }}
                >
                    {formatMessage(messages.cancelButtonText)}
                </button>
            </div>
        </div>
    );
};

export default DeleteAccountModal;
