import AccountActionTypes from "store/user/account/types";
import ProjectActionTypes from "./project/types";
import BrokerActionTypes from "./broker/types";
import InvestmentActionTypes from "./investment/types";
import PaymentActionTypes from "./payment/types";
import UserActionTypes from "./crm/types";
import AdminUserActionTypes from "./user/types";
import TransactionActionTypes from "./transaction/types";
import MiscellaneousActionTypes from "./miscellaneous/types";
import TestingSuiteActionTypes from "./testing-suite/types";
import OrdersActionTypes from "./orders/types";
import TokenActionTypes from "./token/types";
import PerformanceActionTypes from "./performance/types";
import TenantActionTypes from "./tenant/types";
import SecondaryMarketActionTypes from "./secondaryMarket/types";
import DocumentsActionTypes from "./documents/types";
import IssuersActionTypes from "./issuers/types";
import { iconClassMapping } from "constants/index";
import NotificationActionTypes from "./notification/types";

const INITIAL_STATE = {
    users: [],
    projects: [],
    products: [],
    productCatalogs: [],
    investments: [],
    totalInvestments: 0,
    payments: [],
    totalPayments: 0,
    totalUsers: 0,
    brokers: [],
    transactions: [],
    brokerTotalUsers: 0,
    testUserEmails: [],
    testUserData: [],
    ordersData: [],
    totalOrders: 0,
    tokens: [],
    totalTokens: 0,
    performances: [],
    totalPerformances: 0,
    projectTokens: [],
    tags: "",
    androidTestTags: "",
    iosTestTags: "",
    groups: [],
    elements: [],
    brokerAdditional: {
        onBoarding: {
            isEditable: false,
            isUpdating: false,
            isUpdatedClicked: false,
        },
        account: {
            isEditable: false,
            isUpdating: false,
            isUpdatedClicked: false,
        },
    },
    tenants: [],
    totalTenants: 0,
    issuers: [],
    totalIssuers: 0,
    secondarymarket: [],
    totalsecondarymarket: 0,
    documents: [],
    totalDocuments: 0,
    isLoading: false,
    tenantUsers: [],
    totalTenantUsers: 0,

    //CRM merged from here
    user: {},
    userInvestments: [], //duplicate
    userMessagesCount: 0,
    userEmailsCount: 0,
    userCallLogsCount: 0,
    userMessages: [],
    userEmails: [],
    deleteActivityLoader: false,
    deleteActivityStatus: "",

    userLoader: false,
    userMessagesLoader: false,
    userEmailsLoader: false,
    createNewUser: false,
    prefillNumber: {},
    extraCreateLeadData: {
        callNote: "",
    },
    triggerCreateLeadSubmit: false,
    userCreated: false,
    actionsData: {
        callNote: "",
        sms: "",
        email: "",
        isCallNoteCreating: false,
        isSmsSending: false,
        selectedAction: iconClassMapping.magicWand,
    },
    selectedCallLogState: {
        isLoader: false,
        fetchingError: false,
        isShow: false,
        callLog: {},
    },
    investmentDetails: {
        investmentLoader: false,
        investment: {},
        actionsData: {
            selectedAction: iconClassMapping.workflow,
        },
    },

    //Notification
    notifications: [],
    error: "",
    notification: {},
    isNotificationLoading: false,
};

const adminReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case AdminUserActionTypes.SET_TOTAL_USERS:
            return {
                ...state,
                totalUsers: payload,
            };
        case PaymentActionTypes.SET_TOTAL_PAYMENTS:
            return {
                ...state,
                totalPayments: payload,
            };
        case BrokerActionTypes.SET_BROKER_TOTAL_USERS:
            return {
                ...state,
                brokerTotalUsers: payload,
            };

        case AdminUserActionTypes.FETCH_ADMIN_USERS_START:
        case BrokerActionTypes.FETCH_BROKERS_START:
        case ProjectActionTypes.FETCH_PROJECTS_START:
        case ProjectActionTypes.FETCH_PRODUCTS_START:
        case InvestmentActionTypes.FETCH_INVESTMENTS_START:
        case PaymentActionTypes.FETCH_PAYMENTS_START:
        case TransactionActionTypes.FETCH_TRANSACTION_START:
        case MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_START:
        case TestingSuiteActionTypes.FETCH_TEST_USER_START:
        case OrdersActionTypes.FETCH_ORDERS_START:
        case TokenActionTypes.FETCH_TOKEN_START:
        case PerformanceActionTypes.FETCH_PERFORMANCES_START:
        case TokenActionTypes.FETCH_PROJECT_TOKEN_START:
        case TestingSuiteActionTypes.FETCH_TAGS_START:
        case TenantActionTypes.FETCH_TENANTS_START:
        case IssuersActionTypes.FETCH_ISSUERS_START:
        case TestingSuiteActionTypes.FETCH_ANDROID_TEST_TAGS_START:
        case TestingSuiteActionTypes.FETCH_IOS_TEST_TAGS_START:
        case SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_START:
        case DocumentsActionTypes.FETCH_DOCUMENTS_START:
        case IssuersActionTypes.FETCH_ISSUERS_START:
            return {
                ...state,
                isLoading: true,
            };

        case AdminUserActionTypes.FETCH_ADMIN_USERS_SUCCESS:
            return {
                ...state,
                users: payload,
                isLoading: false,
            };

        case BrokerActionTypes.UPDATE_BROKER_ONBOARDING_ADDITIONAL:
            return {
                ...state,
                brokerAdditional: {
                    ...state.brokerAdditional,
                    onBoarding: {
                        ...state.brokerAdditional.onBoarding,
                        ...payload,
                    },
                },
            };

        case BrokerActionTypes.UPDATE_BROKER_ACCOUNT_ADDITIONAL:
            return {
                ...state,
                brokerAdditional: {
                    ...state.brokerAdditional,
                    account: {
                        ...state.brokerAdditional.account,
                        ...payload,
                    },
                },
            };

        case BrokerActionTypes.FETCH_BROKERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                brokers: payload,
            };

        case ProjectActionTypes.FETCH_PROJECTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                projects: payload,
            };

        case ProjectActionTypes.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: payload,
            };
        case ProjectActionTypes.FETCH_PRODUCT_CATALOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                productCatalog: payload,
            };
        case InvestmentActionTypes.FETCH_INVESTMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                investments: payload.investmentsData,
                totalInvestments: payload.totalInvestments || state.totalInvestments,
            };

        case PaymentActionTypes.FETCH_PAYMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                payments: payload,
            };

        case TransactionActionTypes.FETCH_TRANSACTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                transactions: payload,
            };

        case MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                testUserEmails: payload,
            };

        case TestingSuiteActionTypes.FETCH_TEST_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                testUserData: payload,
            };
        case OrdersActionTypes.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ordersData: payload.ordersData || state.ordersData,
                totalOrders: payload.totalOrders || state.totalOrders,
            };

        case TokenActionTypes.FETCH_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tokens: payload.tokens || state.tokens,
                totalTokens: payload.totalTokens || state.totalTokens,
            };

        case TokenActionTypes.FETCH_PROJECT_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                projectTokens: payload.projectTokens || state.projectTokens,
            };

        case PerformanceActionTypes.FETCH_PERFORMANCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                performances: payload.performances || state.performances,
                totalPerformances: payload.totalPerformances || state.totalPerformances,
            };
        case SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                secondarymarket: payload || state.secondarymarket,
                totalsecondarymarket: (payload && payload.length) || state.totalsecondarymarket,
            };
        case DocumentsActionTypes.FETCH_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                documents: payload.documents || state.documents,
                totalDocuments: payload.totalDocuments || state.totalDocuments || 0,
            };

        case IssuersActionTypes.FETCH_ISSUERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                issuers: payload?.issuers || state.issuers,
                totalIssuers: payload?.totalIssuers || state.totalIssuers,
            };

        case TenantActionTypes.FETCH_TENANTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tenants: [...(payload?.tenants || state.tenants)]?.map((tenant) => {
                    return { ...tenant, TechnicalName: tenant?.TechnicalName || tenant?.Name?.toLowerCase().replaceAll(" ", "") };
                }),
                totalTenants: payload?.totalTenants || state.totalTenants,
            };

        case AdminUserActionTypes.FETCH_ADMIN_USERS_FAILURE:
        case BrokerActionTypes.FETCH_BROKERS_FAILURE:
        case ProjectActionTypes.FETCH_PROJECTS_FAILURE:
        case ProjectActionTypes.FETCH_PRODUCTS_FAILURE:
        case InvestmentActionTypes.FETCH_INVESTMENTS_FAILURE:
        case PaymentActionTypes.FETCH_PAYMENTS_FAILURE:
        case TransactionActionTypes.FETCH_TRANSACTION_FAILURE:
        case MiscellaneousActionTypes.FETCH_TEST_USER_EMAILS_FAILURE:
        case TestingSuiteActionTypes.FETCH_TEST_USER_FAILURE:
        case OrdersActionTypes.FETCH_ORDERS_FAILURE:
        case TokenActionTypes.FETCH_TOKEN_FAILURE:
        case PerformanceActionTypes.FETCH_PERFORMANCES_FAILURE:
        case TokenActionTypes.FETCH_PROJECT_TOKEN_FAILURE:
        case TenantActionTypes.FETCH_TENANTS_FAILURE:
        case IssuersActionTypes.FETCH_ISSUERS_FAILURE:
        case SecondaryMarketActionTypes.FETCH_SECONDARY_MARKET_FAILURE:
        case DocumentsActionTypes.FETCH_DOCUMENTS_FAILURE:
        case IssuersActionTypes.FETCH_ISSUERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };

        case AccountActionTypes.LOGOUT:
            return state;

        //CRM merged from here
        case UserActionTypes.REMOVE_Email:
            const updatedActivities = state.userEmails.filter((activity) => activity.uid != payload);

            return {
                ...state,
                userEmails: updatedActivities,
                userEmailsCount: updatedActivities.length,
            };

        case UserActionTypes.UPDATE_USER_ACTIVITY:
            const { uid } = payload;

            let isUpdated = false;

            const { userActivities } = state;

            let updatedUserActivities = [];

            for (let i = 0, len = userActivities.length; i < len; i++) {
                if (userActivities[i].uid === uid) {
                    isUpdated = true;
                    updatedUserActivities[i] = payload;
                    continue;
                }
                updatedUserActivities[i] = userActivities[i];
            }

            if (!isUpdated) {
                updatedUserActivities = [payload, ...updatedUserActivities];
            }

            return {
                ...state,
                userActivities: updatedUserActivities,
            };

        case UserActionTypes.SET_SELECTED_CALL_LOG:
            return {
                ...state,
                selectedCallLogState: {
                    ...state.selectedCallLogState,
                    ...payload,
                },
            };
        case UserActionTypes.FETCH_ALL_INFO_FOR_A_USER:
            return {
                ...state,
            };
        case UserActionTypes.UPDATE_DATA:
            return {
                ...state,
                ...payload,
            };
        case UserActionTypes.SET_PREFILL_NUMBER:
            return {
                ...state,
                prefillNumber: payload,
            };
        case UserActionTypes.SET_CREATE_NEW_USER_STATUS:
            return {
                ...state,
                createNewUser: payload,
            };
        case UserActionTypes.SET_ACTIONS_DATA:
            return {
                ...state,
                actionsData: payload,
            };
        case UserActionTypes.ADD_ACTIVITY:
            return {
                ...state,
                userActivities: [payload, ...state.userActivities],
            };
        case UserActionTypes.SET_SELECTED_ACTION:
            return {
                ...state,
                actionsData: {
                    ...state.actionsData,
                    selectedAction: payload,
                },
            };
        case UserActionTypes.FETCH_ALL_INFO_FOR_AN_INVESTMENT:
            return {
                ...state,
            };
        case UserActionTypes.UPDATE_INVESTMENT_DATA:
            return {
                ...state,
                investmentDetails: {
                    ...state.investmentDetails,
                    ...payload,
                },
            };
        case UserActionTypes.SET_INVESTMENT_ACTIONS_DATA:
            return {
                ...state,
                investmentDetails: {
                    ...state.investmentDetails,
                    actionsData: {
                        ...state.investmentDetails.actionsData,
                        ...payload,
                    },
                },
            };
        case UserActionTypes.ADD_EMAIL:
            return {
                ...state,
                userEmails: [payload, ...state.userEmails],
                userEmailsCount: state.userEmailsCount + 1,
            };

        //NOTIFICATION

        case NotificationActionTypes.FETCH_NOTIFICATION_START:
        case NotificationActionTypes.FETCH_NOTIFICATIONS_START:
            return {
                ...state,
                notification: {},
                isNotificationLoading: true,
            };
        case NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: payload.notifications || [],
                isNotificationLoading: false,
            };

        case NotificationActionTypes.PUSH_NOTIFICATION:
            return {
                ...state,
                notifications: [payload.notification, ...state.notifications],
            };

        case NotificationActionTypes.FETCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification: payload.notification || {},
                isNotificationLoading: false,
            };

        case NotificationActionTypes.FETCH_NOTIFICATION_FAILURE:
        case NotificationActionTypes.FETCH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                error: payload,
                isNotificationLoading: false,
            };
        default:
            return state;
    }
};

export default adminReducer;
