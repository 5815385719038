import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PrevButton from "components/PrevButton";
import CustomFormatNumber from "components/CustomFormatNumber";
import RoundIconContainer from "./components/roundIconContainer";
import AccountDetailsBlock from "./components/accountDetailsBlock";
// import OrderItem from "components/OrderItem";
import FormatDateWithLocale from "components/FormatDateWithLocale";
import Card from "components/Card";
import InvestmentPictureOverview from "components/InvestmentPictureOverview";
import { fetchCompletePayment, fetchCompleteInvestment } from "api";
import { selectLocale } from "store/app/selectors";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { selectDataFromUserDetails } from "store/user/account/selectors";
import { splitDateStringForProperDate, formateDate, getInitialsFromEmail } from "utils";
import { paymentStatusMapping, paymentTypeMapping } from "constants/index";
import { PAYMENT_TYPE } from "constants/status";
import BusinessAndFinance from "assets/images/svg/business-and-finance.svg";
import MoneyCurrency from "assets/images/svg/money-currency.svg";
import Donation from "assets/images/svg/donation.svg";
import EwalletTransferIcon from "assets/images/svg/profit-euro.svg";
import { selectProjectDetails } from "store/project/selectors";
import NewLoader from "components/NewLoader";

const messages = {
    closeTitle: "payment_details",
    youReceived: "you_received",
    youPayedOut: "you_payed_out",
    youEwalletTransfer: "you_ewallet_transfer",
    transferSentPrefix: "transfer_sent_over",
    transferSentSuffix: "activity_detail_sender_suffix",
    transferRecievedPrefix: "receive_transfer_over",
    transferRecievedSuffix: "activity_detail_sender_suffix",
    Amount: "amount",
    Date: "date",
    Type: "type",
    transaction: "transaction",
    accountAsSender: "credit_sender",
    transferRecipient: "credit_recipient",
    transferDetails: "your_transfer_details",
    activityDetailSaleOver: "sale_over",
    activityDetailPurchaseOver: "purchase_over",
    activityDetailInvestmentData: "investment_data",
    perShare: "per_share",
    digitalShareFor: "digital_share_for",
    digitalSharesFor: "digital_shares_for",
    dividendPayoutSend: "dividend_payout_send",
    exitPayoutSend: "exit_payout_send",
    dividendPayoutDeposit: "dividend_payout_deposit",
    exitPayoutDeposit: "exit_payout_deposit",
    product: "product",
};

const displayIcon = (typePayment) => {
    if (typePayment === PAYMENT_TYPE.USER_PAYIN) return <Donation width="30" height="30" />;

    if (typePayment === PAYMENT_TYPE.USER_PAYOUT) return <MoneyCurrency width="30" height="30" />;

    if (typePayment === PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER) return <EwalletTransferIcon width="45" height="45" />;
    if (typePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT || typePayment === PAYMENT_TYPE.USER_EXIT_PAYOUT)
        return <img src="/images/dividends.png" height={30} width={30} />;
    if (
        typePayment === PAYMENT_TYPE.USER_TRANSFER_EXTERNAL ||
        typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE ||
        typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY
    )
        return <BusinessAndFinance width="30" height="30" />;

    return <div className="wf-8 hf-8" />;
};

const ActivityDetails = ({
    stepData,
    setStep,
    setShowDate,
    setCloseTitle,
    locale,
    userEmail,
    backSlideEffect = "",
    setBackSlideEffect,
    allProjects,
}) => {
    useSideModalScrollToTop();

    const typeMapping = paymentTypeMapping();
    const statusMapping = paymentStatusMapping();

    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({});
    const [investmentInfo, setInvestmentInfo] = useState({});
    const [textMessage, setTextMessage] = useState("");
    const [statusColorClass, setStatusColorClass] = useState("");
    const [amountColorClass, setAmountColorClass] = useState(null);
    const [transferMessage, setTransferMessage] = useState("");
    const [payoutMessage, setPayoutMessage] = useState("");
    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        setCloseTitle(formatMessage(messages.closeTitle));
        setShowDate(false);
    }, []);

    useEffect(() => {
        (async () => {
            setPaymentLoading(true);

            const res = await fetchCompletePayment(stepData.activityDetails.activityID);

            res && setPaymentLoading(false);

            if (!res || res.errors || !res.data || res.data.errors || res.data.data.errors) return;

            const payment = res.data.data.payment.payment,
                typePayment = payment.TypePayment,
                statusPayment = payment.StatusPayment;
            let message = "",
                transferMessage = "",
                statusColorClass = "alert-red-color",
                amountColorClass = null,
                payoutMessage = "";

            (statusPayment && statusPayment === "created") || statusPayment === "succeeded"
                ? (statusColorClass = "green-color")
                : (statusColorClass = "alert-red-color");

            if (typePayment === PAYMENT_TYPE.USER_PAYIN) {
                (message = formatMessage(messages.youReceived)), (amountColorClass = true);
            } else if (typePayment === PAYMENT_TYPE.USER_TRANSFER_EXTERNAL) {
                if (payment.Creditor === userEmail) {
                    (amountColorClass = false),
                        ((message = formatMessage(messages.transferSentPrefix)),
                        (transferMessage = formatMessage(messages.transferSentSuffix)));
                } else {
                    (amountColorClass = true),
                        ((message = formatMessage(messages.transferRecievedPrefix)),
                        (transferMessage = formatMessage(messages.transferRecievedSuffix)));
                }
            } else if (typePayment === PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER) {
                if (payment.Creditor === userEmail) {
                    (amountColorClass = false), (message = formatMessage(messages.youEwalletTransfer));
                } else {
                    (amountColorClass = true), (message = formatMessage(messages.youEwalletTransfer));
                }
            } else if (typePayment === PAYMENT_TYPE.USER_PAYOUT) {
                (message = formatMessage(messages.youPayedOut)), (amountColorClass = false);
            } else if (typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE) {
                (message = formatMessage(messages.activityDetailSaleOver)), (amountColorClass = true);
            } else if (typePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY) {
                (message = formatMessage(messages.activityDetailPurchaseOver)), (amountColorClass = false);
            } else if (typePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT || typePayment === PAYMENT_TYPE.USER_EXIT_PAYOUT) {
                if (payment.Creditor === userEmail) {
                    (amountColorClass = false),
                        (payoutMessage = formatMessage(
                            typePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT
                                ? messages.dividendPayoutSend
                                : messages.exitPayoutSend
                        ));
                } else {
                    (amountColorClass = true),
                        (message = formatMessage(
                            typePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT
                                ? messages.dividendPayoutDeposit
                                : messages.exitPayoutDeposit
                        ));
                }
            }
            setTextMessage(message);
            setTransferMessage(transferMessage);
            setPaymentInfo(payment);
            setAmountColorClass(amountColorClass);
            setStatusColorClass(statusColorClass);
            setPayoutMessage(payoutMessage);
        })();
    }, [stepData]);

    useEffect(() => {
        paymentInfo.ReferenceInvestmentID &&
            (async () => {
                setPaymentLoading(true);

                const res = await fetchCompleteInvestment(paymentInfo.ReferenceInvestmentID);

                res && setPaymentLoading(false);

                if (!res || res.errors || !res.data || res.data.errors || res.data.data.errors) return;

                const investment = res.data.data.investment.investment;
                setInvestmentInfo(investment);
            })();
    }, [paymentInfo.ReferenceInvestmentID]);

    const isAccountDetailsBlockVisible =
        paymentInfo &&
        paymentInfo.TypePayment !== PAYMENT_TYPE.USER_TRANSFER_EXTERNAL &&
        paymentInfo.TypePayment !== PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER &&
        paymentInfo.TypePayment !== PAYMENT_TYPE.USER_DIVIDEND_PAYOUT &&
        paymentInfo.TypePayment !== PAYMENT_TYPE.USER_EXIT_PAYOUT;

    const isInvestmentShow =
        paymentInfo &&
        (paymentInfo.TypePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE ||
            paymentInfo.TypePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY);

    const isProductTileVisible = paymentInfo?.TypePayment === PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER;

    const singleProject = (id) => allProjects?.find((item) => item.projectID === id) || {};

    const singleProjectData = singleProject(paymentInfo?.ReferenceProjectID) || {};
    const singleProjectDataAddress = `${singleProjectData?.address?.street}, ${singleProjectData?.address?.city}`;

    return (
        <>
            {isPaymentLoading ? (
                <NewLoader type="primary" className="mx-auto mt-25 d-block" />
            ) : (
                <div className={backSlideEffect}>
                    <div className={"top-grey-div bg-dark hf-50 flex-column justify-center align-center"}>
                        <RoundIconContainer
                            iconBackgroundColor="#ececec"
                            iconComponent={() => displayIcon(paymentInfo.TypePayment)}
                        />
                        <span className="d-inline-block mt-3 text-18">
                            {textMessage}
                            <CustomFormatNumber className="d-inline-block ml-1 fw-500" value={paymentInfo.Amount} />{" "}
                            {locale === "en" ? null : <span>{transferMessage}</span>}
                        </span>

                        <i className="d-inline-block mt-1 text-14 light-grey-color">
                            {payoutMessage
                                ? payoutMessage
                                : paymentInfo.StatusPayment
                                ? statusMapping[paymentInfo.StatusPayment].toLowerCase()
                                : ""}
                        </i>
                    </div>
                    <div className="pb-10 px-5 mw-450 mx-auto">
                        <div className="mt-9 text-16 ">
                            <span className="d-inline-block mb-4 light-grey-color">Details</span>
                            <div className="fw-500 flex justify-between mb-4">
                                <span>{formatMessage(messages.Amount)}</span>
                                <span
                                    className={`d-flex text-16 fw-500 white-space-nowrap ${
                                        amountColorClass ? "green-color" : "alert-red-color"
                                    }`}
                                >
                                    {amountColorClass ? "+ " : "- "}
                                    <CustomFormatNumber value={paymentInfo.Amount} />
                                </span>
                            </div>
                            <div className="fw-500 flex justify-between mb-4 text-16">
                                <span>Status</span>
                                <span className={statusColorClass}>
                                    {paymentInfo.StatusPayment
                                        ? statusMapping[paymentInfo.StatusPayment].toLowerCase()
                                        : paymentInfo.StatusPayment}
                                </span>
                            </div>
                            <div className="fw-500 flex justify-between text-16">
                                <span>{formatMessage(messages.Date)}</span>
                                <span>
                                    {paymentInfo.CreatedDate
                                        ? formateDate(
                                              new Date(...splitDateStringForProperDate(paymentInfo.CreatedDate)),
                                              "date",
                                              "month",
                                              "year",
                                              "."
                                          )
                                        : ""}
                                </span>
                            </div>
                        </div>
                        <div className="mt-9 text-16">
                            <span className="d-inline-block mb-4 light-grey-color">{formatMessage(messages.transaction)}</span>
                            <div className="fw-500 flex justify-between mb-4">
                                <span>ID</span>
                                <span>{paymentInfo.uid}</span>
                            </div>
                            <div className="fw-500 flex justify-between mb-4">
                                <span>{formatMessage(messages.Type)}</span>
                                <span>{paymentInfo.TypePayment ? typeMapping[paymentInfo.TypePayment] : ""}</span>
                            </div>
                        </div>
                        {!isInvestmentShow ? (
                            <>
                                {isAccountDetailsBlockVisible && (
                                    <div className="mt-9 mb-25">
                                        <span className="d-inline-block mb-4 light-grey-color text-16">
                                            {" "}
                                            {formatMessage(messages.transferDetails)}
                                        </span>
                                        <AccountDetailsBlock data={paymentInfo.LinkBankAccount} />
                                    </div>
                                )}

                                {!isAccountDetailsBlockVisible && !isProductTileVisible && (
                                    <>
                                        {/* <div className="flex align-center justify-between mb-4 py-4"></div> */}
                                        {paymentInfo.Creditor === userEmail ? (
                                            <>
                                                <span className="text-16 d-inline-block mb-4 mt-9 light-grey-color ">
                                                    {formatMessage(messages.transferRecipient)}
                                                </span>
                                                <div className="card-container flex ">
                                                    <div className="round-icon-container">
                                                        <div className="round-holder bg-dark">
                                                            {getInitialsFromEmail(paymentInfo.Beneficiary)}
                                                        </div>
                                                    </div>
                                                    <div className="flex align-center hf-20">
                                                        <span className="text-16 fw-500 d-inline-block">
                                                            {paymentInfo.Beneficiary}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-16 d-inline-block mb-4 mt-9 light-grey-color">
                                                    {formatMessage(messages.accountAsSender)}
                                                </span>
                                                <div className="card-container flex mb-12">
                                                    <div className="round-icon-container">
                                                        <div className="round-holder bg-dark">
                                                            {getInitialsFromEmail(paymentInfo.Creditor)}
                                                        </div>
                                                    </div>
                                                    <div className="flex align-center hf-20">
                                                        <span className="text-16 fw-500 d-inline-block">
                                                            {paymentInfo.Creditor}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            Object.keys(investmentInfo).length > 0 && (
                                <div className="mt-9 text-16">
                                    <span className="d-inline-block mb-4 light-grey-color">
                                        {formatMessage(messages.activityDetailInvestmentData)}
                                    </span>
                                    <Card
                                        shares={investmentInfo?.Shares}
                                        className="mt-0"
                                        price={parseFloat(investmentInfo?.Amount)}
                                        sharePrice={investmentInfo?.SharePrice}
                                        title={singleProject(investmentInfo?.LinkProduct?.ProductID)?.title}
                                        date={<FormatDateWithLocale date={investmentInfo?.CreatedDate?.split("_")[0]} />}
                                        status={investmentInfo?.StatusInvestment}
                                    />
                                </div>
                            )
                        )}
                        {isProductTileVisible && Object.keys(singleProjectData).length > 0 && (
                            <div className="mt-9 text-16">
                                <span className="d-inline-block mb-4 light-grey-color">{formatMessage(messages.product)}</span>
                                <InvestmentPictureOverview
                                    className="mt-0"
                                    image={singleProjectData?.images?.teaserImage?.path}
                                    title={singleProjectData?.title}
                                    location={singleProjectDataAddress}
                                    shares={investmentInfo?.Shares}
                                    amount={investmentInfo?.Amount}
                                    createdDate={
                                        paymentInfo.CreatedDate
                                            ? formateDate(
                                                  new Date(...splitDateStringForProperDate(paymentInfo.CreatedDate)),
                                                  "date",
                                                  "month",
                                                  "year",
                                                  "."
                                              )
                                            : ""
                                    }
                                    price={1}
                                />
                            </div>
                        )}
                        <div className="bottom-bar-container">
                            <PrevButton
                                onClick={() => {
                                    setStep(stepData.activityDetails.back);
                                    setBackSlideEffect("step-slide-right");
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    locale: selectLocale,
    userEmail: selectDataFromUserDetails(state, ["Email"]),
    allProjects: selectProjectDetails(state),
});

export default connect(mapStateToProps)(ActivityDetails);
