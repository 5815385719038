import React from "react";

const typeWiseClass = {
    primary: "new-loader-primary",
    secondary: "new-loader-secondary",
};

const NewLoader = ({ className = "", style = {}, type = "" }) => {
    return (
        <div className={`new-loader-container ${className}`} style={style}>
            <div className={`new-loader ${typeWiseClass[type] || ""}`} />
        </div>
    );
};

export default NewLoader;
