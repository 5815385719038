import React from "react";

import NewLoader from "components/NewLoader";
import { nextButtonText } from "i18n/messages/buttonTitles";
import { useFormattedMessage } from "hooks";

const NextButton = ({
    bId = "nextButton",
    className = "",
    title,
    onClick,
    isLoading = false,
    disable = false,
    type = "button",
    ...rest
}) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <button
            {...rest}
            id={bId}
            type={type}
            disabled={disable || isLoading}
            className={`next medium ${className} ${disable ? "disable-grey" : ""}`}
            onClick={() => onClick && onClick()}
        >
            {isLoading ? (
                <>
                    <NewLoader /> {title || formatMessage(nextButtonText)}
                </>
            ) : (
                title || formatMessage(nextButtonText)
            )}
        </button>
    );
};

export default NextButton;
