module.exports = {
    MOBILE_PLATFORM: "mobile-platform",
    LOCALE: "finexity-language",
    FINX_SESSION: "finexity-session",
    FINX_ACCESS_TOKEN: "fnxt-access-token",
    REFRESH_TOKEN: "refresh-token",
    PLATFORM_SOURCE: "platform-source",
    SOURCE_END: "source",
    IS_MPIN: "mpin",
    IS_CLUBDEAL: "isClubdeal",
    IS_NOTIFICATION: "notification",
    USER_ROLE: "finexity-auth_role",
    USER_EMAIL: "email",
    ENTERED_URL: "entered-url",
    CLUB_DEAL_STATUS: "club-deals-status",
    FINEXITY_TENANT: "finexity-tenant",
    INHERIT_USER_TOKEN: "inherit-user-token",
    INHERIT_USER_EMAIL: "inherit-user-email",
    INHERIT_USER_TOKEN_EXPIRATION: "inherit-user-token-expiration",
    LAST_VISITED_URL: "last-visited-url",
    IS_USER_INITIAL_LOGIN: "is-user-initial-login",
    DONT_SHOW_SUBSCRIBE_WINDOW: "dont-show-subscribe-modal",
    REDIRECT_URL: "redirect-url",
    PROJECT_SELECTOR_LIMITED: "project_selector_type_limited",
    SOCIAL_LOGIN_PROVIDER: "social-connection-provider",
    CAMERA_PERMISSION_ENABLED: "camera-permission-enabled",
    APP_VERSION: "app-version",
    EMAIL_NOT_FOUND_IN_TOKEN: "email-not-found-in-token",
    COOKIEFIRST_CONSENT: "cookiefirst-consent",
};
