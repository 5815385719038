import React from "react";
import TextArea from "components/TextArea";
import cn from "classnames";
import * as yup from "yup";
import { withFormik } from "formik";
import { compose } from "recompose";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { contactRequest } from "api";
import { connect } from "react-redux";
import { selectUserDetails } from "store/user/account/selectors";
import NewLoader from "components/NewLoader";

const messages = {
    requiredField: "required_field",
    heading: "contact_messaage_form_contact_message_heading",
    content: "contact_messaage_form_contact_message_content",
    placeholderText: "your_message",
    buttonLoadingText: "contact_form_submit",
    buttonText: "contact_form_submit",
};

function ContactMessageForm({
    handleChange,
    isSubmitting,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    submitForm,
    backSlideEffect = "",
}) {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`contact-message-form ${backSlideEffect}`}>
            <img className="my-15 intro-image" src={"/images/mail-success.png"} alt="" />
            <h3 className="mt-0">{formatMessage(messages.heading)}</h3>
            <p className="body-medium">{formatMessage(messages.content)}</p>
            <form method="post" className="" onSubmit={handleSubmit}>
                <TextArea
                    name="contactMessage"
                    value={values.contactMessage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={formatMessage(messages.placeholderText)}
                    errorMessage={errors.contactMessage ? formatMessage(messages[errors.contactMessage]) : null}
                    error={touched.contactMessage && errors.contactMessage}
                    disabled={isSubmitting}
                />
                <button
                    className={cn({
                        "mt-10 mb-0 mw-100": true,
                        "disable-grey": Object.keys(errors).length,
                    })}
                    type="button"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    {isSubmitting ? (
                        <>
                            <NewLoader /> {formatMessage(messages.buttonLoadingText)}
                        </>
                    ) : (
                        formatMessage(messages.buttonText)
                    )}
                </button>
            </form>
        </div>
    );
}

export default compose(
    connect(
        (state) => ({
            user: selectUserDetails(state),
        }),
        {}
    ),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: () => {
            return {
                contactMessage: "",
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: () =>
            yup.object().shape({
                contactMessage: yup.string().required("requiredField").nullable(true),
            }),
        handleSubmit: (values, { setSubmitting, props }) => {
            setSubmitting(true);
            contactRequest({
                name: props.user.FirstName + " " + props.user.LastName,
                message: values.contactMessage,
                source: "Dashboard",
            }).then(() => {
                setSubmitting(false);
                props.messageSent();
            });
        },
        displayName: "ContactMessageForm",
    })
)(ContactMessageForm);
