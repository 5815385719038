import React, { useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as yup from "yup";
import { withFormik } from "formik";
import { compose } from "recompose";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import cn from "classnames";
import IntlTelInput from "components/IntlTelInput";
import PrevButton from "components/PrevButton";
import { setUserMobileDetails } from "api";
import { phoneRegex } from "constants/validationRegex";
import { PHONE_TYPES } from "constants/index";
import { formatPhoneNumber, getOriginNumber } from "utils";

import { selectUserMobileDetails, selectUserDetails, selectTypeMobilePhonelDetails } from "store/user/account/selectors";
import NewLoader from "components/NewLoader";

const messages = {
    mobileNumberRequired: "mobile_number_required",
    shouldNotMatch: "should_not_match",
    mobileTANFormInputPhoneNumber: "your_mobile_number",
    mobileTANFormPhoneNumberValidationValid: "invalid_mobile_number",
    mobileSomethingWrong: "mobile_tan_something_wrong",
    processing: "processing",
    heading: "mobile_number",
    headingChange: "mobile_number_change",
    content: "increase_security_use_mobiletan_current_number",
    changeContent: "security_check_enter_new_mobile_number",
    buttonText: "mobile_request_code",
    invalidMobileNumberRespError: "mobile_tile_invalid_mobile_number_response_error",
    generalError: "send_form_error",
    userBlocked: "user_blocked",
    verifyMobileNumberError: "verify_mobile_number_error_message",
};

let counter = 0;

const MobileNumber = ({
    className = "",
    handleSubmit,
    values,
    heading,
    content,
    inputLabel,
    setFieldValue,
    touched,
    isRequiredBackButton = false,
    onBack,
    setFieldTouched,
    errors,
    validateForm,
    submitForm,
    status,
    modalData,
    userMobileDetails,
    isChangeData = false,
    isReadable = false,
    backSlideEffect = "",
    isSubmitting,
}) => {
    useSideModalScrollToTop();
    const elementRef = useRef(null);

    const { formatMessage } = useFormattedMessage();
    const nonVerifiedMobilePhone = (userMobileDetails?.CountryCode && userMobileDetails) || modalData?.nonVerifiedMobilePhone;

    const verifiedMobilePhone = modalData?.mobilePhone?.Number;

    useEffect(() => {
        validateForm();
    }, [values, touched]);

    const onFormatSelection = () => {
        const inputRef = document.getElementById("Number");
        const startSelection = inputRef?.value?.length;
        inputRef?.setSelectionRange(startSelection, startSelection);
    };

    useEffect(() => {
        setTimeout(() => {
            onFormatSelection();
        }, 0);
    }, [values.Number, values.DialCode, values.CountryCode]);

    useEffect(() => {
        if (counter > 0 && !isSubmitting) {
            const interval = setInterval(() => {
                counter = counter - 1;
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [isSubmitting, counter]);

    useEffect(() => {
        counter = 0;
    }, [elementRef?.current]);

    return (
        <Fragment ref={elementRef}>
            <div className={`mobile-tan-container ${className} ${backSlideEffect}`}>
                <h3>
                    {heading || formatMessage(isChangeData || verifiedMobilePhone ? messages.headingChange : messages.heading)}
                </h3>
                <p>{content || formatMessage(isChangeData ? messages.changeContent : messages.content)}</p>
                <form method="post" onSubmit={handleSubmit}>
                    <IntlTelInput
                        disabled={isReadable || status?.showLoader}
                        inputClassName={isReadable || status?.showLoader ? "bg-lighter-grey" : ""}
                        className="mt-12 mb-5"
                        fieldName="Number"
                        fieldId="Number"
                        label={inputLabel}
                        showPlaceholderOnTop={false}
                        placeholder={`${formatMessage(messages.mobileTANFormInputPhoneNumber)}`}
                        separateDialCode
                        defaultCountry={nonVerifiedMobilePhone?.CountryCode || "de"}
                        onPhoneNumberChange={(...value) => {
                            setFieldValue("Number", formatPhoneNumber(value[1]));
                            setFieldTouched("Number", true);
                            setFieldValue("DialCode", value[2] && value[2].dialCode);
                            setFieldValue("CountryCode", value[2] && value[2].iso2);
                        }}
                        onSelectFlag={(...value) => {
                            setFieldValue("DialCode", value[1] && value[1].dialCode);
                            setFieldValue("CountryCode", value[1] && value[1].iso2);
                        }}
                        onPhoneNumberBlur={() => setFieldTouched("Number", true)}
                        defaultValue={formatPhoneNumber(nonVerifiedMobilePhone?.Number || "")}
                        value={formatPhoneNumber(values?.Number || "")}
                        error={touched.Number && errors.Number}
                        showErrorMessage
                        errorMessage={touched.Number && errors.Number && formatMessage(messages[errors.Number])}
                    />
                    <button
                        disabled={(status && status.showLoader) || counter !== 0}
                        className={cn({
                            "mb-0 mw-100": true,
                            "disable-grey": Object.keys(errors).length || (status && status.showLoader) || counter !== 0,
                        })}
                        type="button"
                        onClick={() => submitForm()}
                    >
                        {status && status.showLoader ? (
                            <>
                                <NewLoader style={{ color: "black" }} />
                                {formatMessage(messages.processing)}
                            </>
                        ) : counter !== 0 ? (
                            `${formatMessage(messages.requestCodeAgainIn)} ${counter} sec `
                        ) : (
                            formatMessage(messages.buttonText)
                        )}
                    </button>
                </form>
            </div>
            {isRequiredBackButton ? (
                <div className="bottom-bar-container">
                    <PrevButton onClick={onBack} />
                </div>
            ) : null}
        </Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    userMobileDetails: selectUserMobileDetails,
    userDetails: selectUserDetails,
    modalData: selectTypeMobilePhonelDetails,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ modalData }) => {
            const mobile = modalData.nonVerifiedMobilePhone || {};
            return {
                Number: mobile.Number || "",
                CountryCode: mobile.CountryCode || "",
                DialCode: mobile.DialCode || "",
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: ({ modalData }) =>
            yup.object().shape({
                Number: yup
                    .string()
                    .test("Number", "shouldNotMatch", function (number) {
                        return modalData.mobilePhone.Number !== number;
                    })
                    .matches(phoneRegex, "mobileTANFormPhoneNumberValidationValid")
                    .required("mobileNumberRequired"),
            }),
        handleSubmit: async (values, { props, setSubmitting, setFieldError, setStatus, setErrors }) => {
            try {
                setSubmitting(true);
                setErrors({ requestError: undefined, mobileError: undefined });
                const requestData = {
                    ...values,
                    TAN: "",
                    TypePhone: PHONE_TYPES.TypePhoneMobile,
                    Number: getOriginNumber(values.Number),
                };
                setStatus({ showLoader: true });
                const response = await setUserMobileDetails(requestData);
                const errors = response?.data?.errors;
                setSubmitting(false);
                if (response.status === 401 || errors?.[0]?.message === "user_blocked") {
                    setFieldError("Number", "userBlocked");
                } else if (errors) {
                    setStatus({ success: false, showLoader: false });

                    errors[0]?.message === "invalid_to_mobile_number"
                        ? setFieldError("Number", "invalidMobileNumberRespError")
                        : errors[0]?.message === "TAN_send_failed"
                        ? setFieldError("Number", "verifyMobileNumberError")
                        : setFieldError("Number", "generalError");
                } else {
                    counter = 90;
                    setStatus({ success: true, showLoader: false });
                    return props.formSubmitSuccess({ ...values, Number: getOriginNumber(values.Number) });
                }
            } catch (error) {
                setStatus({ success: false });
                setSubmitting(false);
                error.message && setErrors({ requestError: error.message });
                return setFieldError("Number", "mobileSomethingWrong");
            }
        },
        displayName: "MobileNumberForm",
    })
)(MobileNumber);
