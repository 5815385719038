module.exports = {
    IOS: "iOS",
    ANDROID: "Android",
    APP_STORE_LINK: "https://apps.apple.com/us/app/finexity/id1464876346",
    PLAY_STORE_LINK: "https://play.google.com/store/apps/details?id=com.finexity.androidapp",
    FINEXITY_SUPPORT_EMAIL: "support@finexity.com",
    SYSTEM_EMAIL: "system.blockchain@finexity.com",
    ALLOWED_EMAILS: [
        "nitesh.gour@encoresky.com",
        "tim.janssen@finexity.com",
        "paul@huelsmann-capital.de",
        "henning.wagner@finexity.com",
        "tim.janssen@simby.de",
        "finexity@mariawagner.eu",
        "janina.04.05@web.de",
        "ah@huelsmann-gruppe.de",
        "revenco.karina@yahoo.com",
        "vishal.chaturvedi@encoresky.com",
    ],
    MANGOPAY_TERMS_EN: "https://mangopay.com/terms/MANGOPAY_Terms-EN.pdf",
    MANGOPAY_TERMS_DE: "https://mangopay.com/terms/MANGOPAY_Terms-DE.pdf",
    ARTPRICE_LINK: "https://www.artprice.com/",
    contactNumberLink: "+494082217720",
    contactNumberText: "+49 40 822 177 20",
    finexityHeathcareContactNumberLink: "+494087406115",
    finexityHeathcareContactNumberText: "+49 40 87406115",
    winvestaPrivatePlacement: "https://www.winvesta.de/private-placement/",
};
